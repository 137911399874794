import SSCalcWorker from "~/assets/js/SSCalc.worker.js"; // worker files has to end in ".worker.js" - see nuxt.config.js
import ReportWorker from "~/assets/js/Report.worker.js"; // worker files has to end in ".worker.js" - see nuxt.config.js
import AnalyticsWorker from "~/assets/js/Analytics.worker.js"; // worker files has to end in ".worker.js" - see nuxt.config.js
import PlatformWorker from "~/assets/js/Platform.worker.js"; // worker files has to end in ".worker.js" - see nuxt.config.js
export default (context, inject) => {
  inject("worker", {
    createSSWorker() {
      return new SSCalcWorker();
    },
    createReportWorker() {
      return new ReportWorker();
    },
    createAnalyticsWorker() {
      return new AnalyticsWorker();
    },
    createPlatformWorker() {
      return new PlatformWorker();
    }
  });
};

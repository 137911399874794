import Vue from "vue";
export const state = () => ({
  roles: []
});
export const getters = {};
export const mutations = {
  updateRoles(state, arrayList) {
    Vue.set(
      state,
      "roles",
      arrayList.map((object) => ({
        id: object.role_int_name,
        name: object.role_name,
        internal_name: object.role_int_name,
        status: object.role_status
      }))
    );
  }
};
export const actions = {
  getRoles({ commit }) {
    return this.$axios
      .$get(`/v1/roles/`)
      .then((r) => {
        commit("updateRoles", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }
};

export const state = () => ({});

export const getters = {
  shouldUseEurozLayout: (state, getters, rootState, rootGetters) =>
    rootGetters["user/isViewer"] &&
    rootGetters["user/currentEntity"]?.entity_id ==
      "7da90c56-0ffc-4b84-9bea-7908453fa8eb",
  computedFrontPageOptions: () => (firstPageOptions) => {
    const eurozEmailOptions = [
      {
        id: "blank_template",
        value: "Blank template"
      },
      {
        id: "single_security_template",
        value: "Single security template"
      },
      {
        id: "multiple_security_template",
        value: "Multiple security template"
      },
      {
        id: "email_template",
        value: "Stretch template (sharks & minnows)"
      },

      {
        id: "email_morningnote_template",
        value: "Email only template (morningnote)"
      },
      {
        id: "email_weekly_informer_template",
        value: "Email only template (weekly informer)"
      }
    ];
    return eurozEmailOptions.map((obj) => ({
      id: obj.id,
      value: obj.value,
      isSelected: firstPageOptions ? firstPageOptions.includes(obj.id) : false,
      disabled: true,
      canMove: false
    }));
  }
};

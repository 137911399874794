<template>
  <b-button
    :id="id"
    class="pencil-btn-b text-left"
    v-bind:class="[clas]"
    size="sm"
    @click="$emit('pencil-b-click')"
  >
    <span v-if="iconname">
      <b-icon font-scale="0.95" :icon="iconname"></b-icon>
    </span>
    <span v-else-if="iconmoon" :class="iconmoon"> </span>
    {{ label }}
  </b-button>
</template>

<script>
export default {
  props: {
    clas: String,
    label: {
      type: String,
      required: true,
    },
    iconname: {
      type: String,
      required: false,
    },
    iconmoon: {
      type: String,
      required: false,
    },
    id: String,
  },
};
</script>

<style scoped>
.pencil-btn-b {
  background: #15293a !important;
  border-style: dashed;
  width: 12em;
  border-color: #203d52 !important;
  margin-right: 0.2rem;
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  display: flex;
  align-items: center;
}
.pencil-btn-b:hover {
  border-color: rgb(100, 125, 150) !important;
}
.pencil-btn-b:hover .icon {
  color: rgb(100, 125, 150);
}
.pencil-btn-b span {
  background-color: #2e4963;
  padding: 0.25em 0.25em 0.25em 0.25em;
  border-radius: 0.25em;
  margin-right: 0.5em;
  display: inherit;
}
</style>

<template>
  <div class="ranos-logo-container">
    <b-img
      fluid
      :src="logoStyle.backgroundImage"
      @click="$emit('click')"
    ></b-img>
    <!-- <nuxt-link to="/">
      
    </nuxt-link> -->
  </div>
</template>
<script>
export default {
  props: {
    background: {
      required: true,
      type: String
    },
    width: {
      required: false,
      type: Number
    },

    height: {
      required: false,
      type: Number
    },

    size: String
  },
  data() {
    return {};
  },
  computed: {
    logoStyle() {
      let tmpImage;
      if (this.size === "small") {
        if (this.background === "fill") {
          tmpImage = require("~/static/logos/logo_Icon_negative.png");
        } else {
          tmpImage = require("~/static/logos/logo_Icon_positive.png");
        }
      } else if (this.size === "big") {
        if (this.background === "fill") {
          tmpImage = require("~/static/logos/logo_Negative.png");
        } else {
          tmpImage = require("~/static/logos/logo_Positive.png");
        }
      } else if (this.size === "square") {
        tmpImage = require("~/static/logos/logo_icon_transparent.png");
      }
      return {
        backgroundImage: tmpImage
      };
    }
  }
};
</script>
<style lang="css" scoped>
.ranos-logo-container {
  /* margin-left: -20px; */
}
.ranos-logo-container:hover {
  cursor: pointer;
}
</style>

<template>
  <div class="character-count-text">
    <span
      >{{ numberWithCommas(length) }}/{{ numberWithCommas(limit) }}
      {{ text }}</span
    >
  </div>
</template>
<script>
import { text } from "@fortawesome/fontawesome-svg-core";
import { mapGetters } from "vuex";
export default {
  props: {
    limit: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: "characters"
    }
  },
  computed: {
    ...mapGetters({
      numberWithCommas: "app_store/numberWithCommas"
    })
  }
};
</script>

<style lang="sass" scoped>
.character-count-text
  display: flex
  align-items: center
  flex-direction: row-reverse
  span
    color: rgba(var(--color-black), 0.5)
    font-size: var(--font-size-extra-small)
    padding-bottom: 5px
</style>

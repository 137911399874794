export const COLORS = {
  RANOS_BLUE: {
    PRIMARY: "#284B6E"
  },
  CHARTS: {
    FIRST: "#284B6E",
    FIRST_50: "rgba(40,75,110, 0.5)",
    SECOND: "#191919",
    SECOND_50: "rgba(25,25,25,0.5)",
    THIRD: "#506478",
    THIRD_50: "rgba(80,100,120,0.5)",
    FOURTH: "#AFB9C8",
    FOURTH_50: "rgba(175,185,200,0.5)",
    FIFTH: "#646464",
    FIFTH_50: "rgba(100,100,100,0.5)",
    SIXTH: "#647d96",
    SIXTH_50: "rgba(100,125,150)",
    BACKGROUND: "#eeeeee"
  }
};

<template>
  <div id="mobile-full-app-container">
    <div class="mobile-search">
      <ranos-logo size="small" background="fill" @click.native="onGoHome" />
      <div class="search-bar flex-grow-1 ml-3 mr-2">
        <autocomplete-item
          ref="autocompleteItem"
          :dictionary="searchData"
          placeholder="Search"
          :mobile="true"
          @search="onSearch"
        />
      </div>
    </div>
    <nuxt />
    <div id="landscape-bg-div">
      <b-img src="~/assets/images/ic_rotate.svg"></b-img>
      <h1>Please rotate your screen</h1>
    </div>
  </div>
</template>

<script>
import RanosLogo from "~/components/RanosLogo.vue";
import AutocompleteItem from "~/components/common/AutocompleteItem";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    "autocomplete-item": AutocompleteItem,
    "ranos-logo": RanosLogo
  },
  middleware: "entityCheck",
  data() {
    return {
      searchInput: "",
      toggleFlag: false,
      logoFile: null,
      searchQuery: "",
      memberList: [],
      navMap: {
        home: "Home",
        feed: "Feed",
        search: "Search"
      }
    };
  },
  computed: {
    getActiveNavTab() {
      return (routeToMatch) => {
        switch (routeToMatch) {
          case "home":
            return this.$route.name === "mobile-home";
          case "feed":
            return this.$route.name === "mobile-feed";
          case "search":
            return this.$route.name === "mobile-search";
          default:
            break;
        }
      };
    },
    searchData() {
      const returnArray = [];
      returnArray.push({ text: "", id: "" });
      for (let i = 0; i < this.filteredSecurities.length; i++) {
        const tmpSecurity = this.filteredSecurities[i];
        returnArray.push({
          text: tmpSecurity.value.name,
          id: tmpSecurity.value.id
        });
        returnArray.push({
          text: tmpSecurity.value.code,
          id: tmpSecurity.value.id
        });
      }
      return returnArray;
    },
    ...mapGetters({
      currentEntity: "user/currentEntity",
      isLimitedAccess: "user/isLimitedAccess",
      isEditor: "user/isEditor",
      isViewer: "user/isViewer"
    }),
    ...mapState({
      currentEntityDetail: (state) => state.user.currentEntityDetail,
      selectedShownEntity: (state) => state.user.selectedShownEntity,
      limitedAccess: (state) => state.app_store.limitedAccess,
      filteredChangeList: (state) => state.change.filteredChangeList,
      filteredSecurities: (state) => state.security.filteredSecurities,
      securityList: (state) => state.security.securityList,
      filterMobileFeedOptions: (state) => state.change.filterMobileFeedOptions
    })
  },
  async created() {
    await this.searchResult();
  },

  methods: {
    onGoHome() {
      this.$router.push({ path: "/" });
    },
    async searchResult() {
      await this.$store.dispatch("security/filterSecurities", {});
      this.$nextTick(async () => {
        await this.$store.dispatch(
          "publication/remoteUpdateSelectedPublicationFilters",
          {
            security_ids: this.securityList.map((it) => it.value.id)
          }
        );
      });
    },
    onSearch(val) {
      this.$nextTick(async () => {
        await this.$store.dispatch("security/remoteLoadSecurityListDetails");
        let idArray = [];
        if (val.id) {
          idArray = [val.id];
        } else {
          idArray = this.securityList.map((it) => it.value.id);
        }
        await this.$store.dispatch(
          "publication/remoteUpdateSelectedPublicationFilters",
          {
            security_ids: idArray
          }
        );
        await this.$store.dispatch(
          "change/remoteUpdateCurrentSearchSecurityId",
          val.id
        );

        this.$nextTick(async () => {
          await this.$store.dispatch("change/remoteLoadMobileFeedDetails", {
            isErase: true,
            securityId: val.id
          });
        });
      });
    }
  }
};
</script>

<style>
/* Mobile Portrait */
@media only screen and (max-width: 600px) and (orientation: portrait) {
  html {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 12px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  #mobile-full-app-container {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    background: -webkit-linear-gradient(
      top,
      rgb(13, 30, 44),
      rgb(13, 30, 44) 150px,
      rgb(230, 230, 230) 150px,
      rgb(230, 230, 230)
    );
    font-size: 10px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    min-height: 100vh;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }
  .mobile-search {
    width: 100%;
    height: 40px;
    background: rgb(13, 30, 44);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1em;
    padding: 1em;
  }
  .ranos-logo-container {
    max-width: 3em;
    max-height: 3em;
  }
  .home-icon {
    color: rgb(0, 0, 0, 0.6);
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  #landscape-bg-div {
    display: none;
  }
}
/* Mobile Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 800px) and (orientation: landscape) {
  #landscape-bg-div {
    display: flex !important;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(13, 30, 44);
    align-items: center;
    z-index: 10;
  }
  #landscape-bg-div h1 {
    color: white;
  }
  #landscape-bg-div img {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
}
</style>

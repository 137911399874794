var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-base-wrapper"},[(_vm.characterLimits)?_c('character-count-text',{attrs:{"limit":_vm.characterLimits,"length":_vm.inputTextLength}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"input-base",class:{
      highlighted: _vm.highlighted,
      disabled: _vm.disabled,
      dark: _vm.dark,
      small: _vm.small,
      error: _vm.error,
      success: _vm.success,
      prepend: _vm.hasPrependSlot,
      append: _vm.hasAppendSlot,
      both: _vm.hasPrependSlot && _vm.hasAppendSlot,
      focused: _vm.isFocused
    }},[_c('transition',{attrs:{"name":"value","mode":"out-in"}},[(_vm.hasPrependSlot)?_c('div',{staticClass:"icon-left"},[_c('transition',{attrs:{"name":"value","mode":"out-in"}},[_vm._t("prepend")],2)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"content",class:{ up: _vm.isLabelUp, focused: _vm.isFocused }},[_c('div',{staticClass:"label"},[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"value"},[_c('transition',{attrs:{"name":"value","mode":"out-in"}},[_vm._t("default")],2)],1)]),_vm._v(" "),_c('transition',{attrs:{"name":"value","mode":"out-in"}},[(_vm.hasAppendSlot)?_c('div',{staticClass:"icon-right"},[_c('transition',{attrs:{"name":"value","mode":"out-in"}},[_vm._t("append")],2)],1):_vm._e()])],1),_vm._v(" "),_c('error-text',{attrs:{"error":_vm.error}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
export const state = () => ({
  notificationTypes: [],
  notificationChannels: [],
  notificationDelayList: [
    {
      id: "1",
      name: "None"
    },
    {
      id: "2",
      name: "1 hour"
    },
    {
      id: "3",
      name: "6 hours"
    },
    {
      id: "4",
      name: "24 hours"
    }
  ],
  memberSystemNotifications: []
});
export const getters = {
  getMemberSystemNotifications: (state) =>
    state.memberSystemNotifications.map((tmpObj) => ({
      value: tmpObj,
      text: tmpObj.name
    }))
};
export const mutations = {
  updateNotificationTypes(state, arrayList) {
    // notification_status
    arrayList
      .sort(function (a, b) {
        const prev_item = a.notification_name.toLowerCase();
        const next_item = b.notification_name.toLowerCase();
        if (prev_item < next_item) return -1;
        else if (prev_item > next_item) return 1;
        else return 0;
      })
      .sort(function (a, b) {
        if (a.notification_name === "None" && b.notification_name !== "None") {
          return -1;
        } else if (
          a.notification_name !== "None" &&
          b.notification_name === "None"
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    Vue.set(
      state,
      "notificationTypes",
      Object.freeze(
        arrayList.map((object) => ({
          id: object.notification_type_id,
          name: object.notification_name,
          status: object.notification_status
        }))
      )
    );
  },
  updateNotificationChannels(state, arrayList) {
    Vue.set(
      state,
      "notificationChannels",
      Object.freeze(
        arrayList.map((object) => ({
          id: object.notification_ch_id,
          name: object.notification_ch_name,
          status:
            object.notification_ch_status ==
            "NotificationChannelStatus.inactive"
              ? "inactive"
              : "active"
        }))
      )
    );
  },
  updateSystemNotifications(state, arrayList) {
    Vue.set(
      state,
      "memberSystemNotifications",
      Object.freeze(
        arrayList
          .sort(
            (obj1, obj2) => obj1.global_report_order - obj2.global_report_order
          )
          .map((obj) => ({
            id: obj.global_report_id,
            name: obj.global_report_display_name,
            order: obj.global_report_id
          }))
      )
    );
  }
};
export const actions = {
  getNotificationTypes({ commit }) {
    return this.$axios
      .$get(`/v1/global_notification/type`)
      .then((r) => {
        commit("updateNotificationTypes", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getNotificationChannels({ commit }) {
    return this.$axios
      .$get(`/v1/global_notification/channel`)
      .then((r) => {
        commit("updateNotificationChannels", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetSystemNotifications({ commit, rootState }) {
    return this.$axios
      .$get(
        `/v2/member_subscribe/entity/${rootState.user.selectedShownEntity}/system_notifications_list`
      )
      .then((r) => {
        if (r.system_notifications_list) {
          commit("updateSystemNotifications", r.system_notifications_list);
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }
};

<template>
  <div
    ref="autocomplete"
    class="autocomplete"
    :class="mobile ? 'mobile' : ''"
    @keydown.tab="onTabDown"
    @keydown.enter="onEnterDown"
    @keydown.down="onDownEvent"
    @keydown.up="onUpEvent"
  >
    <input
      v-model="input"
      type="search"
      class="autocomplete-input"
      :placeholder="placeholder"
    />
    <span class="autocomplete-suggest">{{ currentSuggestion }}</span>
    <div v-if="isCloseBtnShown" class="d-flex flex-column autocomplete-option">
      <b-button class="btn-chevron close" aria-label="Close" @click="onUpEvent">
        <span class="d-inline-block" aria-hidden="true">&and;</span>
      </b-button>
      <b-button
        class="btn-chevron close"
        aria-label="Close"
        @click="onDownEvent"
      >
        <span class="d-inline-block" aria-hidden="true">&or;</span>
      </b-button>
    </div>
    <b-button
      v-if="isCloseBtnShown"
      class="autocomplete-clear close"
      aria-label="Close"
      @click="onClearSearch"
    >
      <span class="d-inline-block" aria-hidden="true">&times;</span>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    dictionary: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    mobile: Boolean
  },
  data() {
    return {
      input: "",
      selectedSuggestionId: null,
      selectedIndex: 0,
      tabIndex: 0, // number of tab pressed, index of word in current suggestion
      tabResult: "", // string shows in the search bar
      previouseSuggestion: ""
    };
  },
  computed: {
    isCloseBtnShown() {
      return Boolean(this.input);
    },
    suggestions() {
      if (
        this.input !== "" &&
        this.input !== null &&
        this.input !== undefined
      ) {
        return this.closest(this.input, this.dictionary);
      } else {
        return this.dictionary;
      }
    },
    selectedItem: {
      get() {
        return this.suggestions[this.selectedIndex] || {};
      },
      set(newValue) {
        return newValue;
      }
    },
    currentSuggestion: {
      get() {
        if (this.input === "" || !this.selectedItem.text) {
          return "";
        }

        return (
          this.input +
            this.selectedItem.text.slice(
              this.input.length,
              this.selectedItem.text.length
            ) || ""
        );
      },
      set(newValue) {
        return newValue;
      }
    }
  },
  watch: {
    input() {
      const routeNameCheck = this.$route.name !== "mobile-feed";
      if (routeNameCheck || (!routeNameCheck && this.input !== "")) {
        this.$emit("input-event", this.selectedItem);
        if (this.selectedSuggestionId) {
          this.selectedIndex = this.findSelectedItemIndexById(
            this.suggestions,
            this.selectedSuggestionId
          );
          if (this.selectedIndex === -1) {
            this.selectedIndex = 0;
          }
        }
        if (!this.input) {
          this.resetValue();
        }
        this.tabIndex = 0;
        this.tabResult = "";
        const currentSuggestionArray = this.currentSuggestion.split(" ");
        const inputArray = this.input.split(" ");
        inputArray.forEach((e) => {
          const index = currentSuggestionArray.indexOf(e);
          if (index !== -1) {
            if (index === currentSuggestionArray.length - 1) {
              this.tabResult =
                this.tabResult + currentSuggestionArray[this.tabIndex];
            } else {
              this.tabResult =
                this.tabResult + currentSuggestionArray[this.tabIndex] + " ";
            }
            this.tabIndex += 1;
          }
        });
      } else {
        this.$emit("input-event", { text: "", id: "" });
      }
    }
  },
  methods: {
    closest(query, dictionary) {
      var suggestions = [];
      for (const term of dictionary) {
        if (term.text) {
          if (term.text.toUpperCase().startsWith(query.toUpperCase())) {
            suggestions.push(term);
          }
        }
      }
      return suggestions;
    },
    findSelectedItemIndexById(arr, id) {
      return arr.map((x) => x.id).indexOf(id);
    },
    onEnterDown(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.$emit("search", this.selectedItem);
        this.resetValue();
      }
    },
    onTabDown(event) {
      if (event.key === "Tab" && this.currentSuggestion) {
        event.preventDefault();
        // generate current suggestion word array and input array
        const currentSuggestionArray = this.currentSuggestion.split(" ");
        const inputArray = this.input.split(" ");
        // if previous seggestion not equal to the current suggestion, reset the tabIndex and tabResult
        if (this.previouseSuggestion !== this.currentSuggestion) {
          this.tabIndex = 0;
          this.tabResult = "";
          // check the elements in current input that match the element in the current suggestion array
          // if element exist, update the tabResult and tabIndex
          inputArray.forEach((e) => {
            if (currentSuggestionArray.indexOf(e) !== -1) {
              this.tabResult =
                this.tabResult + currentSuggestionArray[this.tabIndex] + " ";
              this.tabIndex += 1;
            }
          });
        }
        // record the previouse suggestion
        this.previouseSuggestion = this.currentSuggestion;
        // add more string to the tabResult
        if (this.tabIndex < currentSuggestionArray.length - 1) {
          this.tabResult =
            this.tabResult + currentSuggestionArray[this.tabIndex] + " ";
        }
        if (this.tabIndex === currentSuggestionArray.length - 1) {
          this.tabResult =
            this.tabResult + currentSuggestionArray[this.tabIndex];
        }
        // update tabIndex
        this.tabIndex++;
        this.input = this.tabResult;
      }
    },
    onDownEvent(event) {
      event.preventDefault();
      if (this.selectedIndex === 0) {
        this.selectedIndex = this.suggestions.length - 1;
      } else {
        this.selectedIndex = this.selectedIndex =
          this.suggestions.length === 0
            ? 0
            : (this.selectedIndex - 1) % this.suggestions.length;
      }
      this.selectedSuggestionId = this.selectedItem.id;
    },
    onUpEvent(event) {
      event.preventDefault();
      this.selectedIndex =
        this.suggestions.length === 0
          ? 0
          : (this.selectedIndex + 1) % this.suggestions.length;
      this.selectedSuggestionId = this.selectedItem.id;
    },
    handleBlur() {
      this.resetValue();
    },
    onClearSearch() {
      this.resetValue();
    },
    resetOnSwitch() {
      this.selectedSuggestionId = null;
      this.selectedIndex = 0;
    },
    resetValue() {
      this.selectedSuggestionId = null;
      this.selectedItem = {};
      this.currentSuggestion = "";
      this.selectedIndex = 0;
      this.input = "";
      this.tabIndex = 0;
      this.tabResult = "";
      this.previouseSuggestion = "";
    }
  }
};
</script>
<style scoped>
.autocomplete {
  width: 100%;
  position: relative;
  display: inline-block;
}

.autocomplete.mobile .autocomplete-input {
  border: 0;
  margin: 0;
  outline: 0;
  padding-left: 5px;
  font-size: 12px;
  position: relative;
  width: 100%;
  z-index: 10;
  background-color: rgb(91, 117, 142) !important;
  color: white !important;
  opacity: 0.7 !important;
  border-color: transparent !important;
  border-radius: 0.4rem !important;
  height: 30px;
}
.autocomplete-input {
  border: 0;
  margin: 0;
  outline: 0;
  padding-left: 5px;
  font-size: 12px;
  position: relative;
  width: 100%;
  z-index: 10;
  background-color: rgb(91, 117, 142) !important;
  color: white !important;
  opacity: 0.7 !important;
  border-color: transparent !important;
  border-radius: 0.4rem !important;
  height: 2rem;
  line-height: 2rem;
  word-spacing: 1px;
  padding-right: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.autocomplete-suggest {
  color: white;
  font-size: 12px;
  position: absolute;
  left: 5px;
  /* top: 50%;
  transform: translate(0%, -50%); */
  z-index: 5;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 3rem; /* This is to stop the text from overlapping the drop down chevron */
}

.autocomplete .autocomplete-suggest {
  height: 2rem;
  line-height: 2rem;
}

.autocomplete.mobile .autocomplete-suggest {
  height: 30px;
  line-height: 30px;
}

.autocomplete.mobile .autocomplete-input::placeholder {
  color: rgb(0, 0, 0, 0.5);
  opacity: 1; /* Firefox */
}
.autocomplete.mobile .autocomplete-input::-ms-input-placeholder {
  color: rgb(0, 0, 0, 0.5);
  opacity: 1; /* Firefox */
}
.autocomplete.mobile .autocomplete-input::placeholder {
  color: rgb(0, 0, 0, 0.5);
  opacity: 1; /* Firefox */
}
.autocomplete-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.autocomplete-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.autocomplete-input:focus {
  border-color: transparent;
  box-shadow: none !important;
  outline: none !important;
}
.autocomplete-option {
  position: absolute;
  right: 25px;
  top: 4px;
  z-index: 15;
  color: rgba(255, 255, 255, 0.7);
}

.autocomplete.mobile .autocomplete-option {
  top: 7px;
}

.chevron-down {
  /* margin-top: -3px; */
}
.autocomplete-clear {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  position: absolute;
  right: 5px;
  top: 60%;
  transform: translate(0%, -70%);
  z-index: 15;
}
.autocomplete-option .btn-chevron {
  cursor: pointer;
  font-size: 8px !important;
  color: rgba(255, 255, 255, 0.7);
  height: 8px !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.autocomplete-option .btn-secondary:focus,
.autocomplete-option .btn-secondary:hover {
  background-color: transparent;
  border-color: transparent;
}

.close {
  text-shadow: none !important;
}

.close:not(:disabled):not(.disabled):focus {
  opacity: 0.7;
}

.close:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>

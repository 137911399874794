import Vue from "vue";

export const state = () => ({
  SINGLE_CELL_W: 65,
  SINGLE_CELL_H: 22,
  ORANGE_GRID_W: 54,
  ORANGE_GRID_H: 27,
  SHEET_TAB_W: 200,
  ROW_PADDING: 2,
  COL_PADDING: 3,
  MAX_ROWS: 1000,
  MAX_COLS: 26,
  DEFAULT_FREEZE_COL: "A",
  DEFAULT_FREEZE_ROW: "1",
  SCROLL_UP_THRESHOLD: 20,
  SCROLL_LEFT_THRESHOLD: 20,
  HIGHLIGHT_COLOR: "rgba(100, 125, 150, 0.1)",
  HIGHTLIGHT_CORNER_SIZE: 8,
  HIGHTLIGHT_BORDER_COLOR: "rgb(100, 125, 150)",
  SCROLLBAR_W: 16,
  SCROLLBAR_HANDLE_W: 8,
  TOP_PAGE_MENU_H: 50,
  BOTTOM_SHEET_MENU_H: 50,
  MAX_CELL_WIDTH: process.client ? window.innerWidth * 0.7 : 1200,
  MAX_CELL_HEIGHT: process.client ? window.innerHeight * 0.7 : 600,
  DEPENDENTS_COLOR: "rgba(234, 145, 30, 0.2)",
  DEPENDENTS_BORDER_COLOR: "#ea911e",
  DEPENDENTS_PANEL_COLOR: "#fbe9d2",
  PRECEDENTS_COLOR: "rgba(24, 128, 56, 0.2)",
  PRECEDENTS_BORDER_COLOR: "#188038",
  PRECEDENTS_PANEL_COLOR: "#d0e6d6",

  windowWidth: process.client ? window.innerWidth : 0,
  windowHeight: process.client ? window.innerHeight : 0,
  viewWidth: 0,
  viewHeight: 0,
  visibleCols: {},
  visibleRows: {},
  freezedCols: {},
  freezedRows: {},
  freezeColIndex: {},
  freezeRowIndex: {},
  crossheadObj: {
    col: false,
    row: false,
  },
  copyCells: false,
  showDependencySquare: false,
  showPrecedencySquare: false,
  stageRendering: false,
  inputCellDetails: {},
});

export const getters = {
  topLeftGrid: (state, getters, rootState) => {
    let tmpX = state.ORANGE_GRID_W;
    let returnColArray = [];
    const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
    if (state.freezedCols[tmpSheetName]) {
      state.freezedCols[tmpSheetName].forEach((colObj) => {
        returnColArray.push({
          startX: tmpX,
          endX: tmpX + colObj.colWidth,
          ...colObj,
        });
        tmpX += colObj.colWidth;
      });
    }
    let tmpY = state.ORANGE_GRID_H;
    let returnRowArray = [];
    if (state.freezedRows[tmpSheetName]) {
      state.freezedRows[tmpSheetName].forEach((rowObj) => {
        returnRowArray.push({
          startY: tmpY,
          endY: tmpY + rowObj.rowHeight,
          ...rowObj,
        });
        tmpY += rowObj.rowHeight;
      });
    }
    return {
      cols: returnColArray,
      rows: returnRowArray,
      endX: tmpX,
      endY: tmpY,
      textCells: getters.getTextCellsFromRange(
        returnColArray,
        returnRowArray,
        "topleft"
      ),
    };
  },
  topRightGrid: (state, getters, rootState) => {
    const topLeftPanel = getters.topLeftGrid;
    let tmpX = topLeftPanel.endX;
    let returnColArray = [];
    const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
    let ws = rootState.spreadsheet.worksheets.Sheets[tmpSheetName];
    if (state.visibleCols[tmpSheetName]) {
      state.visibleCols[tmpSheetName].forEach((colObj) => {
        let prevHidden = false;
        let nextHidden = false;
        if (
          ws["!cols"] &&
          ws["!cols"][colObj.colIndex - 1] &&
          ws["!cols"][colObj.colIndex - 1].hidden
        ) {
          prevHidden = ws["!cols"][colObj.colIndex - 1].hidden;
        }
        if (
          ws["!cols"] &&
          ws["!cols"][colObj.colIndex + 1] &&
          ws["!cols"][colObj.colIndex + 1].hidden
        ) {
          nextHidden = ws["!cols"][colObj.colIndex + 1].hidden;
        }
        returnColArray.push({
          startX: tmpX,
          endX: tmpX + colObj.colWidth,
          prevHidden: prevHidden,
          nextHidden: nextHidden,
          ...colObj,
        });
        tmpX += colObj.colWidth;
      });
    }
    let tmpY = state.ORANGE_GRID_H;
    let returnRowArray = [];
    if (state.freezedRows[tmpSheetName]) {
      state.freezedRows[tmpSheetName].forEach((rowObj) => {
        returnRowArray.push({
          startY: tmpY,
          endY: tmpY + rowObj.rowHeight,
          ...rowObj,
        });
        tmpY += rowObj.rowHeight;
      });
    }
    return {
      cols: returnColArray,
      rows: returnRowArray,
      textCells: getters.getTextCellsFromRange(
        returnColArray,
        returnRowArray,
        "topright"
      ),
    };
  },
  bottomLeftGrid: (state, getters, rootState) => {
    const topLeftPanel = getters.topLeftGrid;
    let tmpX = state.ORANGE_GRID_W;
    let returnColArray = [];
    const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
    let ws = rootState.spreadsheet.worksheets.Sheets[tmpSheetName];
    if (state.freezedCols[tmpSheetName]) {
      state.freezedCols[tmpSheetName].forEach((colObj) => {
        returnColArray.push({
          startX: tmpX,
          endX: tmpX + colObj.colWidth,
          ...colObj,
        });
        tmpX += colObj.colWidth;
      });
    }
    let tmpY = topLeftPanel.endY;
    let returnRowArray = [];
    if (state.visibleRows[tmpSheetName]) {
      state.visibleRows[tmpSheetName].forEach((rowObj) => {
        let prevHidden = false;
        let nextHidden = false;
        if (
          rowObj.rowIndex > 0 &&
          ws["!rows"] &&
          ws["!rows"][rowObj.rowIndex - 1] &&
          ws["!rows"][rowObj.rowIndex - 1].hidden
        ) {
          prevHidden = ws["!rows"][rowObj.rowIndex - 1].hidden;
        }
        if (
          ws["!rows"] &&
          ws["!rows"][rowObj.rowIndex + 1] &&
          ws["!rows"][rowObj.rowIndex + 1].hidden
        ) {
          nextHidden = ws["!rows"][rowObj.rowIndex + 1].hidden;
        }
        returnRowArray.push({
          startY: tmpY,
          endY: tmpY + rowObj.rowHeight,
          prevHidden: prevHidden,
          nextHidden: nextHidden,
          ...rowObj,
        });
        tmpY += rowObj.rowHeight;
      });
    }
    return {
      cols: returnColArray,
      rows: returnRowArray,
      textCells: getters.getTextCellsFromRange(
        returnColArray,
        returnRowArray,
        "bottomleft"
      ),
    };
  },
  bottomRightGrid: (state, getters, rootState) => {
    const topLeftPanel = getters.topLeftGrid;
    let tmpX = topLeftPanel.endX;
    let returnColArray = [];
    const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
    if (tmpSheetName && state.visibleCols[tmpSheetName]) {
      state.visibleCols[tmpSheetName].forEach((colObj) => {
        returnColArray.push({
          startX: tmpX,
          endX: tmpX + colObj.colWidth,
          ...colObj,
        });
        tmpX += colObj.colWidth;
      });
    }
    let tmpY = topLeftPanel.endY;
    let returnRowArray = [];
    if (tmpSheetName && state.visibleRows[tmpSheetName]) {
      state.visibleRows[tmpSheetName].forEach((rowObj) => {
        returnRowArray.push({
          startY: tmpY,
          endY: tmpY + rowObj.rowHeight,
          ...rowObj,
        });
        tmpY += rowObj.rowHeight;
      });
    }
    return {
      cols: returnColArray,
      rows: returnRowArray,
      textCells: getters.getTextCellsFromRange(
        returnColArray,
        returnRowArray,
        "bottomright"
      ),
    };
  },
  getAllColsWithX: (state, getters) =>
    getters.topLeftGrid.cols.concat(getters.topRightGrid.cols),
  getAllRowsWithY: (state, getters) =>
    getters.topRightGrid.rows.concat(getters.bottomRightGrid.rows),
  getNewRows: (state, getters, rootState, rootGetters) => {
    return (dy) => {
      const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
      if (
        !rootState.spreadsheet.rowCount[tmpSheetName] ||
        rootState.spreadsheet.rowCount[tmpSheetName] < 0
      )
        return false;
      const fullRowCount =
        rootState.spreadsheet.rowCount[tmpSheetName] + state.ROW_PADDING;
      const firstItemIndex = state.visibleRows[tmpSheetName][0].rowIndex;
      const arrayLength = state.visibleRows[tmpSheetName].length;
      let direction = "";
      let tmpFirstNumber;
      let returnArray = [];
      if (dy > 0) {
        // scroll down
        // check if last item already in array
        const lastItemIndex =
          state.visibleRows[tmpSheetName][arrayLength - 1].rowIndex;
        if (lastItemIndex > fullRowCount) return false;
        tmpFirstNumber =
          lastItemIndex + dy > fullRowCount
            ? firstItemIndex
            : firstItemIndex + dy;
        direction = "down";
      } else {
        // scroll up
        // check if first item already in array
        if (firstItemIndex === state.freezeRowIndex[tmpSheetName]) return false;
        tmpFirstNumber =
          firstItemIndex + dy < state.freezeRowIndex[tmpSheetName]
            ? state.freezeRowIndex[tmpSheetName]
            : firstItemIndex + dy;
        direction = "up";
        let tmpRow = rootGetters["spreadsheet/getRowIndex"](
          tmpFirstNumber,
          tmpSheetName
        );
        while (tmpRow.hidden) {
          if (tmpFirstNumber == 0) break;
          tmpFirstNumber--;
          tmpRow = rootGetters["spreadsheet/getRowIndex"](
            tmpFirstNumber,
            tmpSheetName
          );
        }
      }
      let tmpY = 0;
      while (tmpY < state.viewHeight) {
        const tmpObj = rootGetters["spreadsheet/getRowIndex"](
          tmpFirstNumber,
          tmpSheetName
        );
        if (!tmpObj.hidden) {
          returnArray.push(tmpObj);
          tmpY += tmpObj.rowHeight;
        }
        tmpFirstNumber++;
      }
      return {
        sheetName: tmpSheetName,
        direction: direction,
        addArray: returnArray,
      };
    };
  },
  getNewCols: (state, getters, rootState, rootGetters) => {
    return (dx) => {
      const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
      if (
        !rootState.spreadsheet.colCount[tmpSheetName] ||
        rootState.spreadsheet.colCount[tmpSheetName] < 0
      )
        return false;
      const fullColCount =
        rootState.spreadsheet.colCount[tmpSheetName] + state.COL_PADDING;
      const arrayLength = state.visibleCols[tmpSheetName].length;
      const firstItemIndex = state.visibleCols[tmpSheetName][0].colIndex;
      let direction = "";
      let tmpFirstNumber;
      let returnArray = [];
      if (dx > 0) {
        // scroll right
        // check if last item already in array
        const lastItemIndex =
          state.visibleCols[tmpSheetName][arrayLength - 1].colIndex;
        if (lastItemIndex > fullColCount) return false;
        tmpFirstNumber =
          lastItemIndex + dx > fullColCount
            ? firstItemIndex
            : firstItemIndex + dx;
        direction = "right";
      } else {
        // scroll left
        // check if first item already in array
        if (firstItemIndex === state.freezeColIndex[tmpSheetName]) return false;
        tmpFirstNumber =
          firstItemIndex + dx < state.freezeColIndex[tmpSheetName]
            ? state.freezeColIndex[tmpSheetName]
            : firstItemIndex + dx;
        direction = "left";
        let tmpCol = rootGetters["spreadsheet/getColIndex"](
          tmpFirstNumber,
          tmpSheetName
        );
        while (tmpCol.hidden) {
          if (tmpFirstNumber == 0) break;
          tmpFirstNumber--;
          tmpCol = rootGetters["spreadsheet/getColIndex"](
            tmpFirstNumber,
            tmpSheetName
          );
        }
      }
      let tmpX = 0;
      while (tmpX < state.viewWidth) {
        const tmpObj = rootGetters["spreadsheet/getColIndex"](
          tmpFirstNumber,
          tmpSheetName
        );
        if (!tmpObj.hidden) {
          returnArray.push(tmpObj);
          tmpX += tmpObj.colWidth;
        }
        tmpFirstNumber++;
      }
      return {
        sheetName: tmpSheetName,
        direction: direction,
        addArray: returnArray,
      };
    };
  },
  getTextCellsFromRange(state, getters, rootState, rootGetters) {
    return (cols, rows, pos) => {
      let returnArray = [];
      rows.forEach((rowObj, rowIndex) => {
        cols.forEach((colObj, colIndex) => {
          const cellId = `${colObj.showCol}${rowObj.showRow}`;
          const cellObj = rootGetters["spreadsheet/getCellObj"](
            false,
            false,
            rowObj.rowIndex,
            colObj.colIndex
          );
          let cellText = "";
          let showCell = false;
          if (cellObj) {
            if (cellObj.t == "e" && cellObj.w) {
              cellText = cellObj.w;
              showCell = true;
            } else if (cellObj.w) {
              cellText = cellObj.w;
              showCell = true;
            } else if (cellObj.v) {
              cellText = cellObj.v;
              showCell = true;
            } else if (cellObj.s) {
              showCell = true;
            }
          }
          if (showCell) {
            // need to display cells with style as
            const tmpLastIndex = returnArray.length - 1;
            let currentCell = {
              cellId: cellId,
              cellStartX: colObj.startX,
              cellStartY: rowObj.startY,
              cellWidth: colObj.colWidth,
              cellHeight: rowObj.rowHeight,
              cellText: cellText,
              cellType: cellObj.t,
              cellStyle: cellObj.s ? cellObj.s : false,
              prevCell:
                returnArray[tmpLastIndex] &&
                returnArray[tmpLastIndex].cellStartY == rowObj.startY
                  ? returnArray[tmpLastIndex]
                  : "",
            };
            if (
              returnArray[tmpLastIndex] &&
              returnArray[tmpLastIndex].cellStartY == rowObj.startY
            ) {
              returnArray[tmpLastIndex]["nextCell"] = currentCell;
            }
            returnArray.push(currentCell);
          }
        });
      });
      return returnArray;
    };
  },
  workoutCursorCell(state, getters, rootState) {
    return ({ x, y }) => {
      const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
      if (
        !state.freezedCols[tmpSheetName] ||
        !state.freezedRows[tmpSheetName] ||
        !state.visibleCols[tmpSheetName] ||
        !state.visibleRows[tmpSheetName]
      )
        return false;
      let tmpColObj = getters.getAllColsWithX.find((colObj) => {
        return colObj.startX <= x && colObj.endX > x;
      });

      if (
        tmpColObj &&
        tmpColObj.colIndex > rootState.spreadsheet.colCount[tmpSheetName] + 1
      ) {
        tmpColObj =
          getters.topRightGrid.cols[
            getters.topRightGrid.cols.length - state.COL_PADDING
          ];
      }
      let tmpRowObj = getters.getAllRowsWithY.find((rowObj) => {
        return rowObj.startY <= y && rowObj.endY > y;
      });
      if (
        tmpRowObj &&
        tmpRowObj.rowIndex >= rootState.spreadsheet.rowCount[tmpSheetName]
      ) {
        tmpRowObj =
          getters.topRightGrid.rows[
            getters.topRightGrid.rows.length - state.ROW_PADDING
          ];
      }
      return {
        row: tmpRowObj,
        col: tmpColObj,
      };
    };
  },
  getCrossheads(state, getters, rootState) {
    return (point) => {
      const { row: tmpRowObj, col: tmpColObj } =
        getters.workoutCursorCell(point);
      if (!tmpColObj || !tmpRowObj) return false;
      if (
        state.crossheadObj.col &&
        tmpColObj.startX &&
        state.crossheadObj.col.startX === tmpColObj.startX &&
        state.crossheadObj.row &&
        tmpRowObj.startY &&
        state.crossheadObj.row.startY === tmpRowObj.startY
      )
        return "same";

      return {
        sheetName: rootState.spreadsheet.selectedWorksheetName,
        col: tmpColObj,
        row: tmpRowObj,
      };
    };
  },
  highlightSquare: (state, getters, rootState, rootGetters) => {
    if (
      rootState.spreadsheet.highlightCells.row.length < 1 ||
      rootState.spreadsheet.highlightCells.col.length < 1
    )
      return false;
    //NOTE: cant to Math as its got observer in the array
    const startColIndex =
      rootState.spreadsheet.highlightCells.col[0] <
      rootState.spreadsheet.highlightCells.col[
        rootState.spreadsheet.highlightCells.col.length - 1
      ]
        ? rootState.spreadsheet.highlightCells.col[0]
        : rootState.spreadsheet.highlightCells.col[
            rootState.spreadsheet.highlightCells.col.length - 1
          ];
    const startRowIndex =
      rootState.spreadsheet.highlightCells.row[0] <
      rootState.spreadsheet.highlightCells.row[
        rootState.spreadsheet.highlightCells.row.length - 1
      ]
        ? rootState.spreadsheet.highlightCells.row[0]
        : rootState.spreadsheet.highlightCells.row[
            rootState.spreadsheet.highlightCells.row.length - 1
          ];
    const endColIndex =
      rootState.spreadsheet.highlightCells.col[0] >
      rootState.spreadsheet.highlightCells.col[
        rootState.spreadsheet.highlightCells.col.length - 1
      ]
        ? rootState.spreadsheet.highlightCells.col[0]
        : rootState.spreadsheet.highlightCells.col[
            rootState.spreadsheet.highlightCells.col.length - 1
          ];
    const endRowIndex =
      rootState.spreadsheet.highlightCells.row[0] >
      rootState.spreadsheet.highlightCells.row[
        rootState.spreadsheet.highlightCells.row.length - 1
      ]
        ? rootState.spreadsheet.highlightCells.row[0]
        : rootState.spreadsheet.highlightCells.row[
            rootState.spreadsheet.highlightCells.row.length - 1
          ];

    const tmpAllCols = getters.getAllColsWithX;
    const tmpAllRows = getters.getAllRowsWithY;

    // check if outside range
    const totalColC = tmpAllCols.length;
    if (totalColC === 0) return false;
    const totalRowC = tmpAllRows.length;
    if (totalRowC === 0) return false;
    if (
      endColIndex < tmpAllCols[0].colIndex ||
      startColIndex > tmpAllCols[totalColC - 1].colIndex ||
      endRowIndex < tmpAllRows[0].rowIndex ||
      startRowIndex > tmpAllRows[totalRowC - 1].rowIndex
    )
      return false;

    const startColObj = rootGetters["app_store/arrayBinarySearchWithKey"](
      tmpAllCols,
      "colIndex",
      startColIndex,
      tmpAllCols[0]
    ).result;

    const startRowObj = rootGetters["app_store/arrayBinarySearchWithKey"](
      tmpAllRows,
      "rowIndex",
      startRowIndex,
      tmpAllRows[0]
    ).result;

    const endColObj = rootGetters["app_store/arrayBinarySearchWithKey"](
      tmpAllCols,
      "colIndex",
      endColIndex,
      tmpAllCols[totalColC - 1]
    ).result;

    const endRowObj = rootGetters["app_store/arrayBinarySearchWithKey"](
      tmpAllRows,
      "rowIndex",
      endRowIndex,
      tmpAllRows[totalRowC - 1]
    ).result;

    //workout name range
    let highlightRange =
      rootState.spreadsheet.selectedWorksheetName.indexOf(" ") > -1
        ? `'${rootState.spreadsheet.selectedWorksheetName}'`
        : `${rootState.spreadsheet.selectedWorksheetName}`;
    highlightRange += `!$${startColObj.actualCol}$${startRowObj.actualRow}:$${endColObj.actualCol}$${endRowObj.actualRow}`;

    const { index: nameRangeIndex, result: nameRange } = rootGetters[
      "app_store/arrayBinarySearchWithKey"
    ](
      rootState.spreadsheet.worksheets.Workbook.Names,
      "Ref",
      highlightRange,
      null
    );
    return {
      // these are the visible highlights
      highlightCells: {
        x: startColObj.startX,
        y: startRowObj.startY,
        width: endColObj.endX - startColObj.startX,
        height: endRowObj.endY - startRowObj.startY,
        color: state.HIGHLIGHT_COLOR,
        border: state.HIGHTLIGHT_BORDER_COLOR,
        nameRange,
        highlightRange,
        nameRangeIndex,
        startColObj,
        endColObj,
        startRowObj,
        endRowObj,
      },
      highlightCorner: {
        x: endColObj.endX - state.HIGHTLIGHT_CORNER_SIZE / 2,
        y: endRowObj.endY - state.HIGHTLIGHT_CORNER_SIZE / 2,
        width: state.HIGHTLIGHT_CORNER_SIZE,
        height: state.HIGHTLIGHT_CORNER_SIZE,
        color: state.HIGHTLIGHT_BORDER_COLOR,
      },
      //below is not not related to visibility
      startColIndex,
      endColIndex,
      startRowIndex,
      endRowIndex,
    };
  },
  highlightSquareGroup: (state, getters, rootState, rootGetters) => {
    if (rootState.spreadsheet.highlightCellGroup.length < 1) return false;
    let returnArray = [];
    rootState.spreadsheet.highlightCellGroup.forEach((element) => {
      if (element.row.length < 1 || element.col.length < 1) return false;
      //NOTE: cant to Math as its got observer in the array
      const startColIndex =
        element.col[0] < element.col[element.col.length - 1]
          ? element.col[0]
          : element.col[element.col.length - 1];
      const startRowIndex =
        element.row[0] < element.row[element.row.length - 1]
          ? element.row[0]
          : element.row[element.row.length - 1];
      const endColIndex =
        element.col[0] > element.col[element.col.length - 1]
          ? element.col[0]
          : element.col[element.col.length - 1];
      const endRowIndex =
        element.row[0] > element.row[element.row.length - 1]
          ? element.row[0]
          : element.row[element.row.length - 1];

      const tmpAllCols = getters.getAllColsWithX;
      const tmpAllRows = getters.getAllRowsWithY;

      // check if outside range
      const totalColC = tmpAllCols.length;
      if (totalColC === 0) return false;
      const totalRowC = tmpAllRows.length;
      if (totalRowC === 0) return false;
      if (
        endColIndex < tmpAllCols[0].colIndex ||
        startColIndex > tmpAllCols[totalColC - 1].colIndex ||
        endRowIndex < tmpAllRows[0].rowIndex ||
        startRowIndex > tmpAllRows[totalRowC - 1].rowIndex
      )
        return false;

      const startColObj = rootGetters["app_store/arrayBinarySearchWithKey"](
        tmpAllCols,
        "colIndex",
        startColIndex,
        tmpAllCols[0]
      ).result;

      const startRowObj = rootGetters["app_store/arrayBinarySearchWithKey"](
        tmpAllRows,
        "rowIndex",
        startRowIndex,
        tmpAllRows[0]
      ).result;

      const endColObj = rootGetters["app_store/arrayBinarySearchWithKey"](
        tmpAllCols,
        "colIndex",
        endColIndex,
        tmpAllCols[totalColC - 1]
      ).result;

      const endRowObj = rootGetters["app_store/arrayBinarySearchWithKey"](
        tmpAllRows,
        "rowIndex",
        endRowIndex,
        tmpAllRows[totalRowC - 1]
      ).result;

      //workout name range
      let highlightRange =
        rootState.spreadsheet.selectedWorksheetName.indexOf(" ") > -1
          ? `'${rootState.spreadsheet.selectedWorksheetName}'`
          : `${rootState.spreadsheet.selectedWorksheetName}`;
      highlightRange += `!$${startColObj.actualCol}$${startRowObj.actualRow}:$${endColObj.actualCol}$${endRowObj.actualRow}`;

      const { index: nameRangeIndex, result: nameRange } = rootGetters[
        "app_store/arrayBinarySearchWithKey"
      ](
        rootState.spreadsheet.worksheets.Workbook.Names,
        "Ref",
        highlightRange,
        null
      );

      returnArray.push({
        // these are the visible highlights
        highlightCells: {
          x: startColObj.startX,
          y: startRowObj.startY,
          width: endColObj.endX - startColObj.startX,
          height: endRowObj.endY - startRowObj.startY,
          color: state.HIGHLIGHT_COLOR,
          border: state.HIGHTLIGHT_BORDER_COLOR,
          opacity: 0.5,
          nameRange,
          highlightRange,
          nameRangeIndex,
          startColObj,
          endColObj,
          startRowObj,
          endRowObj,
        },
        //below is not not related to visibility
        startColIndex,
        endColIndex,
        startRowIndex,
        endRowIndex,
      });
    });
    return returnArray;
  },
  copyDashSquare: (state, getters, rootState) => {
    if (!state.copyCells) return false;
    let returnConfg = {};
    if (getters.highlightSquare) {
      returnConfg = {
        ...getters.highlightSquare.highlightCells,
      };
    } else {
      returnConfg = {
        ...rootState.spreadsheet.savedHighlightCells,
      };
    }
    if (state.copyCells.col.startX < returnConfg.x) {
      if (!getters.highlightSquare) {
        returnConfg["width"] +=
          window.innerWidth - state.ORANGE_GRID_W - state.copyCells.col.startX;
      } else {
        returnConfg["width"] += returnConfg.x - state.copyCells.col.startX;
      }
      returnConfg["x"] = state.copyCells.col.startX;
      returnConfg["startColObj"] = state.copyCells.col;
    }
    if (state.copyCells.col.endX > returnConfg.x + returnConfg.width) {
      if (!getters.highlightSquare) {
        returnConfg["x"] = state.ORANGE_GRID_W;
      }
      returnConfg["width"] = state.copyCells.col.endX - returnConfg.x;
      returnConfg["endColObj"] = state.copyCells.col;
    }
    if (state.copyCells.row.startY < returnConfg.y) {
      if (!getters.highlightSquare) {
        returnConfg["height"] +=
          window.innerHeight -
          state.ORANGE_GRID_H -
          state.TOP_PAGE_MENU_H -
          state.BOTTOM_SHEET_MENU_H -
          state.copyCells.row.startY;
      } else {
        returnConfg["height"] += returnConfg.y - state.copyCells.row.startY;
      }
      returnConfg["y"] = state.copyCells.row.startY;
      returnConfg["startRowObj"] = state.copyCells.row;
    }
    if (state.copyCells.row.endY > returnConfg.y + returnConfg.height) {
      if (!getters.highlightSquare) {
        returnConfg["y"] = state.ORANGE_GRID_H;
      }
      returnConfg["height"] = state.copyCells.row.endY - returnConfg.y;
      returnConfg["endRowObj"] = state.copyCells.row;
    }
    return returnConfg;
  },
  scrollbarHandleConfigRight: (state, getters, rootState) => {
    const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
    let totalSize = 0;
    let totalCount = 0;
    let visibleColRow = {};
    let freezeColRow = {};
    let lastIndex;
    let hiddenCount = 0;
    totalSize = state.viewHeight;
    totalCount =
      rootState.spreadsheet.rowCount[tmpSheetName] + state.ROW_PADDING + 2;
    visibleColRow = state.visibleRows[tmpSheetName];
    freezeColRow = state.freezedRows[tmpSheetName];
    lastIndex = visibleColRow[visibleColRow.length - 1].rowIndex;
    for (var i = 1; i < visibleColRow.length; i++) {
      if (visibleColRow[i].rowIndex != visibleColRow[i - 1].rowIndex + 1) {
        hiddenCount +=
          visibleColRow[i].rowIndex - visibleColRow[i - 1].rowIndex - 1;
      }
    }
    const singleSize = totalSize / (totalCount - hiddenCount);
    const tmpSize = (visibleColRow.length + freezeColRow.length) * singleSize;
    const remainColRow = totalCount - lastIndex - 1;
    const remainWidth = singleSize * remainColRow;
    const offset = totalSize - remainWidth - tmpSize;
    let returnArray = {
      fill: "rgba(0, 0, 0, 0.5)",
      draggable: true,
      cornerRadius: state.SCROLLBAR_HANDLE_W,
      dragDistance: singleSize,
    };
    returnArray["x"] =
      state.viewWidth + state.SCROLLBAR_W / 2 - state.SCROLLBAR_HANDLE_W / 2;
    returnArray["y"] = offset + state.TOP_PAGE_MENU_H / 2;
    returnArray["width"] = state.SCROLLBAR_HANDLE_W;
    returnArray["height"] = tmpSize;
    returnArray["dragBoundFunc"] = (pos) => {
      return {
        y:
          pos.y < state.TOP_PAGE_MENU_H / 2
            ? state.TOP_PAGE_MENU_H / 2
            : pos.y < state.viewHeight - tmpSize
            ? pos.y
            : state.viewHeight - tmpSize,
        x:
          state.viewWidth +
          state.SCROLLBAR_W / 2 -
          state.SCROLLBAR_HANDLE_W / 2,
      };
    };
    return returnArray;
  },
  scrollbarHandleConfigBottom: (state, getters, rootState) => {
    const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
    let totalSize = 0;
    let totalCount = 0;
    let visibleColRow = {};
    let freezeColRow = {};
    let lastIndex;
    let hiddenCount = 0;
    totalSize = state.viewWidth - state.ORANGE_GRID_W;
    totalCount =
      rootState.spreadsheet.colCount[tmpSheetName] + state.COL_PADDING + 1;
    visibleColRow = state.visibleCols[tmpSheetName];
    freezeColRow = state.freezedCols[tmpSheetName];
    lastIndex = visibleColRow[visibleColRow.length - 1].colIndex;
    for (var i = 1; i < visibleColRow.length; i++) {
      if (visibleColRow[i].colIndex != visibleColRow[i - 1].colIndex + 1) {
        hiddenCount +=
          visibleColRow[i].colIndex - visibleColRow[i - 1].colIndex - 1;
      }
    }
    const singleSize = totalSize / (totalCount - hiddenCount);
    const tmpSize = (visibleColRow.length + freezeColRow.length) * singleSize;
    const remainColRow = totalCount - lastIndex - 1;
    const remainWidth = singleSize * remainColRow;
    const offset = totalSize - remainWidth - tmpSize;
    let returnArray = {
      fill: "rgba(0, 0, 0, 0.5)",
      draggable: true,
      cornerRadius: state.SCROLLBAR_HANDLE_W,
      dragDistance: singleSize,
    };
    returnArray["y"] =
      state.viewHeight + state.SCROLLBAR_W / 2 - state.SCROLLBAR_HANDLE_W / 2;
    returnArray["x"] = offset + state.ORANGE_GRID_W;
    returnArray["height"] = state.SCROLLBAR_HANDLE_W;
    returnArray["width"] = tmpSize;
    returnArray["dragBoundFunc"] = (pos) => {
      return {
        x:
          pos.x < state.ORANGE_GRID_W
            ? state.ORANGE_GRID_W
            : pos.x <= state.viewWidth - tmpSize
            ? pos.x
            : state.viewWidth - tmpSize,
        y:
          state.viewHeight +
          state.SCROLLBAR_W / 2 -
          state.SCROLLBAR_HANDLE_W / 2,
      };
    };
    return returnArray;
  },
  /*
    scrollbarHandleConfig: (state, getters, rootState) => {
        return (side) => {
            const tmpSheetName = rootState.spreadsheet.selectedWorksheetName;
            let totalSize = 0;
            let totalCount = 0;
            let visibleColRow = {};
            let freezeColRow = {};
            let lastIndex;
            let hiddenCount = 0;
            switch(side) {
                case 'right':
                    totalSize = state.viewHeight;
                    totalCount = rootState.spreadsheet.rowCount[tmpSheetName] + state.ROW_PADDING + 2;
                    visibleColRow = state.visibleRows[tmpSheetName];
                    freezeColRow = state.freezedRows[tmpSheetName];
                    lastIndex = visibleColRow[visibleColRow.length - 1].rowIndex;
                    for(var i = 1; i < visibleColRow.length; i++) {
                        if(visibleColRow[i].rowIndex != visibleColRow[i - 1].rowIndex + 1) {
                            hiddenCount += visibleColRow[i].rowIndex - visibleColRow[i - 1].rowIndex - 1;
                        }
                    }
                    break;
                case 'bottom':
                    totalSize = state.viewWidth - state.ORANGE_GRID_W;
                    totalCount = rootState.spreadsheet.colCount[tmpSheetName] + state.COL_PADDING + 1;
                    visibleColRow = state.visibleCols[tmpSheetName];
                    freezeColRow = state.freezedCols[tmpSheetName];
                    lastIndex = visibleColRow[visibleColRow.length - 1].colIndex;
                    for(var i = 1; i < visibleColRow.length; i++) {
                        if(visibleColRow[i].colIndex != visibleColRow[i - 1].colIndex + 1) {
                            hiddenCount += visibleColRow[i].colIndex - visibleColRow[i - 1].colIndex - 1;
                        }
                    }
                    break;
            }
            const singleSize = totalSize / (totalCount - hiddenCount);
            const tmpSize = (visibleColRow.length + freezeColRow.length) * singleSize;
            const remainColRow = totalCount - lastIndex - 1;
            const remainWidth = singleSize * remainColRow;
            const offset = totalSize - remainWidth - tmpSize;
            let returnArray = {
                fill: 'rgba(0, 0, 0, 0.5)',
                draggable: true,
                cornerRadius: state.SCROLLBAR_HANDLE_W,
                dragDistance: singleSize
            };
            switch (side) {
                case 'right':
                    returnArray['x'] = state.viewWidth + state.SCROLLBAR_W / 2 - state.SCROLLBAR_HANDLE_W / 2;
                    returnArray['y'] = offset;
                    returnArray['width'] = state.SCROLLBAR_HANDLE_W;
                    returnArray['height'] = tmpSize;
                    returnArray['dragBoundFunc'] = (pos) => {
                        return {
                            y: pos.y < 0 ? 0 : pos.y < state.viewHeight - tmpSize ? pos.y : state.viewHeight - tmpSize,
                            x: state.viewWidth + state.SCROLLBAR_W / 2 - state.SCROLLBAR_HANDLE_W / 2
                        };
                    };
                    break;
                case 'bottom':
                    returnArray['y'] = state.viewHeight + state.SCROLLBAR_W / 2 - state.SCROLLBAR_HANDLE_W / 2;
                    returnArray['x'] = offset + state.ORANGE_GRID_W;
                    returnArray['height'] = state.SCROLLBAR_HANDLE_W;
                    returnArray['width'] = tmpSize;
                    returnArray['dragBoundFunc'] = (pos) => {
                        return {
                            x: pos.x < state.ORANGE_GRID_W ? state.ORANGE_GRID_W : pos.x <= state.viewWidth - tmpSize ? pos.x : state.viewWidth - tmpSize,
                            y: state.viewHeight + state.SCROLLBAR_W / 2 - state.SCROLLBAR_HANDLE_W / 2
                        };
                    };
                    break
            }
            return returnArray;
        };
    },
    */
  intDependencySquare: (state, getters, rootState, rootGetters) => {
    // lose the parameters in favour of caching in getters, as we only need 1 lvl dependency this should work for now
    // return (tmpSheetName, tmpCellId) => {
    //use ref cells instead for range
    // const selectedSheetName = rootState.spreadsheet.selectedWorksheetName;
    // if (tmpSheetName !== selectedSheetName) return false;
    if (!rootGetters["spreadsheet/highlightSingleCell"]) return false;
    const selectedSheetName = rootState.spreadsheet.selectedWorksheetName;
    const tmpCellId = rootGetters["spreadsheet/encodeCell"]({
      r: rootState.spreadsheet.highlightCells.row[0],
      c: rootState.spreadsheet.highlightCells.col[0],
    });
    const tmpDependencies =
      rootGetters["spreadsheet/getCellObj"](tmpCellId) &&
      rootGetters["spreadsheet/getCellObj"](tmpCellId).refCells
        ? rootGetters["spreadsheet/getCellObj"](tmpCellId).refCells
        : null;
    if (!tmpDependencies) return false;
    const tmpAllCols = getters.getAllColsWithX;
    const tmpAllRows = getters.getAllRowsWithY;
    const totalCols = tmpAllCols.length;
    const totalRows = tmpAllRows.length;
    let returnArray = [];
    let unshownCells = {};
    const allDependencySheet = Object.keys(tmpDependencies);
    for (
      let i = 0, numberOfSheets = allDependencySheet.length;
      i < numberOfSheets;
      i++
    ) {
      const tmpSheetName = allDependencySheet[i];
      for (
        let m = 0, loop4len = tmpDependencies[tmpSheetName].length;
        m < loop4len;
        m++
      ) {
        const { s, e } = tmpDependencies[tmpSheetName][m];
        if (
          tmpSheetName === selectedSheetName &&
          s.c >= tmpAllCols[0].colIndex &&
          e.c <= tmpAllCols[totalCols - 1].colIndex &&
          s.r >= tmpAllRows[0].rowIndex &&
          e.r <= tmpAllRows[totalRows - 1].rowIndex
        ) {
          // if the square is visible in the current sheet
          // let startColObj = tmpAllCols.find((colObj) => {
          //     return colObj.colIndex === s.c;
          // });
          // if (!startColObj) startColObj = getters.topRightGrid.cols[0];
          const startColObj = rootGetters["app_store/arrayBinarySearchWithKey"](
            tmpAllCols,
            "colIndex",
            s.c,
            getters.topRightGrid.cols[0]
          ).result;

          // let startRowObj = tmpAllRows.find((rowObj) => {
          //     return rowObj.rowIndex === s.r;
          // });
          // if (!startRowObj) startRowObj = getters.bottomRightGrid.rows[0];
          const startRowObj = rootGetters["app_store/arrayBinarySearchWithKey"](
            tmpAllRows,
            "rowIndex",
            s.r,
            getters.bottomRightGrid.rows[0]
          ).result;

          // let endColObj = tmpAllCols.find((colObj) => {
          //     return colObj.colIndex === e.c;
          // });
          // if (!endColObj) endColObj = tmpAllCols[totalCols - 1];
          const endColObj = rootGetters["app_store/arrayBinarySearchWithKey"](
            tmpAllCols,
            "colIndex",
            e.c,
            tmpAllCols[totalCols - 1]
          ).result;

          // let endRowObj = tmpAllRows.find((rowObj) => {
          //     return rowObj.rowIndex === e.r;
          // });
          // if (!endRowObj) endRowObj = tmpAllRows[totalRows - 1];
          const endRowObj = rootGetters["app_store/arrayBinarySearchWithKey"](
            tmpAllRows,
            "rowIndex",
            e.r,
            tmpAllRows[totalRows - 1]
          ).result;

          returnArray.push({
            x: startColObj.startX,
            y: startRowObj.startY,
            width: endColObj.endX - startColObj.startX,
            height: endRowObj.endY - startRowObj.startY,
            color: state.DEPENDENTS_COLOR,
            border: state.DEPENDENTS_BORDER_COLOR,
          });
        } else {
          // if the cell is hidden
          if (!unshownCells[tmpSheetName]) unshownCells[tmpSheetName] = [];
          let tmpRange = rootGetters["spreadsheet/encodeRange"](
            tmpDependencies[tmpSheetName][m]
          );
          // let rangeNameIndex = -1;
          if (rootState.spreadsheet.worksheets.Workbook.Names.length > 0) {
            let highlightRange =
              tmpSheetName.indexOf(" ") > -1
                ? `'${tmpSheetName}'`
                : `${tmpSheetName}`;
            highlightRange +=
              `!$` +
              rootGetters["spreadsheet/encodeCol"](s.c) +
              "$" +
              rootGetters["spreadsheet/encodeRow"](s.r) +
              ":$" +
              rootGetters["spreadsheet/encodeCol"](e.c) +
              "$" +
              rootGetters["spreadsheet/encodeRow"](e.r);
            // rangeNameIndex = rootState.spreadsheet.worksheets.Workbook.Names.findIndex((nameRangeObj) => nameRangeObj.Ref === highlightRange);
            const { index: rangeNameIndex, result: rangeName } = rootGetters[
              "app_store/arrayBinarySearchWithKey"
            ](
              rootState.spreadsheet.worksheets.Workbook.Names,
              "Ref",
              highlightRange,
              null
            );
          }
          // if (rangeName) tmpRange = rangeName.Name + ' (' + tmpRange + ')';
          unshownCells[tmpSheetName].push({
            range: tmpRange,
            rangeNameIndex,
            rangeName: rangeNameIndex > 0 ? rangeName : "",
          });
        }
      }
    }
    return {
      visibleSquares: returnArray,
      unshownSquares: unshownCells,
    };
    // };
  },
  intPrecendencySquare: (state, getters, rootState, rootGetters) => {
    // lose the parameters in favour of caching in getters, as we only need 1 lvl precedency this should work for now
    // return (tmpSheetName, tmpCellId) => {
    if (!rootGetters["spreadsheet/highlightSingleCell"]) return false;
    const selectedSheetName = rootState.spreadsheet.selectedWorksheetName;
    const tmpCellId = rootGetters["spreadsheet/encodeCell"]({
      r: rootState.spreadsheet.highlightCells.row[0],
      c: rootState.spreadsheet.highlightCells.col[0],
    });
    const tmpPrecedencies = rootGetters["spreadsheet/getIntPrecedency"](
      selectedSheetName,
      tmpCellId
    );
    if (!tmpPrecedencies) return false;
    // const selectedSheetName = rootState.spreadsheet.selectedWorksheetName;
    const tmpAllCols = getters.getAllColsWithX;
    const tmpAllRows = getters.getAllRowsWithY;
    let returnArray = [];
    let unshownCells = {};
    for (let m = 0, loop4len = tmpPrecedencies.length; m < loop4len; m++) {
      const [precedencySheet, precedencyCellId] = tmpPrecedencies[m].split("!");
      // if (precedencySheet !== selectedSheetName) continue;
      const { r, c } = rootGetters["spreadsheet/decodeCell"](precedencyCellId);
      if (
        precedencySheet === selectedSheetName &&
        c >= tmpAllCols[0].colIndex &&
        r >= tmpAllRows[0].rowIndex
      ) {
        // if the square is visible in the current sheet
        let startColObj = tmpAllCols.find((colObj) => {
          return colObj.colIndex === c;
        });
        if (!startColObj) continue;

        let startRowObj = tmpAllRows.find((rowObj) => {
          return rowObj.rowIndex === r;
        });
        if (!startRowObj) continue;

        returnArray.push({
          x: startColObj.startX,
          y: startRowObj.startY,
          width: startColObj.colWidth,
          height: startRowObj.rowHeight,
          color: state.PRECEDENTS_COLOR,
          border: state.PRECEDENTS_BORDER_COLOR,
        });
      } else {
        // if the cell is hidden
        if (!unshownCells[precedencySheet]) unshownCells[precedencySheet] = [];
        unshownCells[precedencySheet].push(precedencyCellId);
      }
    }
    return {
      visibleSquares: returnArray,
      unshownSquares: unshownCells,
    };
    // };
  },
};

export const mutations = {
  updateViewSize(state, { width, height }) {
    state.viewHeight = height;
    state.viewWidth = width;
  },
  // updateMaxGridSize(state, { width, height }) {
  //     state.gridHeight = height;
  //     state.gridWidth = width;
  // },
  setRowsAndCols(state, { visibleCol, visibleRow }) {
    Vue.set(state, "visibleCols", visibleCol);
    Vue.set(state, "visibleRows", visibleRow);
  },
  updateVisibleRow(state, { sheetName, visibleRow }) {
    Vue.set(state.visibleRows, sheetName, visibleRow);
  },
  updateVisibleCol(state, { sheetName, visibleCol }) {
    Vue.set(state.visibleCols, sheetName, visibleCol);
  },
  updateFreezeColAndRow(state, { freezeCol, freezeRow }) {
    Vue.set(state, "freezedCols", freezeCol);
    Vue.set(state, "freezedRows", freezeRow);
  },
  setFreezeIndices(state, { freezeColIndex, freezeRowIndex }) {
    Vue.set(state, "freezeColIndex", freezeColIndex);
    Vue.set(state, "freezeRowIndex", freezeRowIndex);
  },
  updateCrosshead(state, { col, row }) {
    Vue.set(state, "crossheadObj", {
      col,
      row,
    });
  },
  setCopyCells(state, cell) {
    Vue.set(state, "copyCells", cell);
  },
  setDependencySquare(state, trueOrFalse) {
    Vue.set(state, "showDependencySquare", trueOrFalse);
  },
  setPrecedencySquare(state, trueOrFalse) {
    Vue.set(state, "showPrecedencySquare", trueOrFalse);
  },
  updateMenuHeight(state, { height }) {
    state.TOP_PAGE_MENU_H = height;
  },
  updateStageRendering(state, trueOrFalse) {
    state.stageRendering = trueOrFalse;
  },
  updateInputCellDetails(state, cellObj) {
    Vue.set(state, "inputCellDetails", cellObj);
  },
};

export const actions = {
  remoteUpdateViewSize({ commit, state }, payload) {
    commit("updateViewSize", {
      width: payload.width - state.SCROLLBAR_W,
      height: payload.height - state.SCROLLBAR_W,
    });
  },
  remoteUpdateFreezeColAndRow({ commit }, { freezeCol, freezeRow }) {
    commit("updateFreezeColAndRow", {
      freezeCol,
      freezeRow,
    });
  },
  remoteUpdateVisibleColAndRow({ commit }, { visibleCol, visibleRow }) {
    commit("setRowsAndCols", {
      visibleCol,
      visibleRow,
    });
  },
  remoteUpdateVisibleRow({ commit }, { sheetName, visibleRow }) {
    commit("updateVisibleRow", {
      sheetName,
      visibleRow,
    });
  },
  remoteUpdateVisibleCol({ commit }, { sheetName, visibleCol }) {
    commit("updateVisibleCol", {
      sheetName,
      visibleCol,
    });
  },
  remoteUpdateFreezeCellIndex({ commit }, { freezeColIndex, freezeRowIndex }) {
    commit("setFreezeIndices", {
      freezeColIndex,
      freezeRowIndex,
    });
  },
  async remoteUpdateVisibleRowsDelta({ commit, getters, state }, dy) {
    const newRows = getters.getNewRows(
      Math.floor(dy / state.SCROLL_UP_THRESHOLD)
    );
    if (newRows) {
      commit("updateVisibleRow", {
        visibleRow: newRows.addArray,
        sheetName: newRows.sheetName,
      });
    }
    return true;
  },
  async remoteUpdateVisibleColsDelta({ commit, getters, state }, dx) {
    const newCols = getters.getNewCols(
      Math.floor(dx / state.SCROLL_LEFT_THRESHOLD)
    );
    if (newCols) {
      commit("updateVisibleCol", {
        visibleCol: newCols.addArray,
        sheetName: newCols.sheetName,
      });
    }
    return true;
  },
  remoteUpdateCrossheads({ commit, getters, rootState }, cursorPos) {
    if (!cursorPos) {
      commit("updateCrosshead", {
        col: false,
        row: false,
      });
      return true;
    }
    const newCrosshead = getters.getCrossheads(cursorPos);
    if (!newCrosshead)
      commit("updateCrosshead", {
        col: false,
        row: false,
      });
    else if (newCrosshead !== "same") commit("updateCrosshead", newCrosshead);
  },
  async workoutCopyCells({ commit, state, getters, rootState }, point) {
    if (!point.x || !point.y) return false;
    if (
      point.x < rootState.spreadsheet.savedHighlightCells.endColObj.endX &&
      point.x > rootState.spreadsheet.savedHighlightCells.x &&
      point.y < rootState.spreadsheet.savedHighlightCells.endRowObj.endY &&
      point.y > rootState.spreadsheet.savedHighlightCells.y
    ) {
      commit("setCopyCells", false);
      return false;
    }

    const { row: tmpRowObj, col: tmpColObj } = getters.workoutCursorCell(point);
    // check the index to see the direction it wants to go
    if (!tmpColObj || !tmpRowObj) {
      commit("setCopyCells", false);
      return false;
    }

    commit("setCopyCells", {
      row: tmpRowObj,
      col: tmpColObj,
    });
    return true;
  },
  highlightCopyCells({ commit, getters, rootState }) {
    if (!getters.copyDashSquare) return;
    commit(
      "spreadsheet/changeHighlightCells",
      {
        row: [
          rootState.spreadsheet.highlightCells.row[0] <
          getters.copyDashSquare.startRowObj.rowIndex
            ? rootState.spreadsheet.highlightCells.row[0]
            : getters.copyDashSquare.startRowObj.rowIndex,
          getters.copyDashSquare.endRowObj.rowIndex,
        ],
        col: [
          rootState.spreadsheet.highlightCells.col[0] <
          getters.copyDashSquare.startColObj.colIndex
            ? rootState.spreadsheet.highlightCells.col[0]
            : getters.copyDashSquare.startColObj.colIndex,
          getters.copyDashSquare.endColObj.colIndex,
        ],
      },
      {
        root: true,
      }
    );
    commit("setCopyCells", false);
  },
  workoutSideScrollbarRowCol(
    { commit, state, rootState, getters },
    { pos, whichScollbar }
  ) {
    const scollBarCurrent =
      whichScollbar === "right"
        ? getters.scrollbarHandleConfigRight
        : getters.scrollbarHandleConfigBottom;
    let deltaD = 0;
    let newRowCols = null;
    switch (whichScollbar) {
      case "right":
        deltaD = pos - scollBarCurrent.y;
        newRowCols = getters.getNewRows(
          Math.floor(deltaD / scollBarCurrent.dragDistance)
        );
        if (newRowCols) {
          commit("updateVisibleRow", {
            visibleRow: newRowCols.addArray,
            sheetName: newRowCols.sheetName,
          });
        }
        break;
      case "bottom":
        deltaD = pos - scollBarCurrent.x;
        newRowCols = getters.getNewCols(
          Math.floor(deltaD / scollBarCurrent.dragDistance)
        );
        if (newRowCols) {
          commit("updateVisibleCol", {
            visibleCol: newRowCols.addArray,
            sheetName: newRowCols.sheetName,
          });
        }
        break;
    }
  },
  async remoteMoveToCell(
    { commit, state, rootState, rootGetters },
    { nextCellObj, position }
  ) {
    // console.log(rootState.spreadsheet.selectedWorksheetName);
    if (
      nextCellObj.c >
        state.visibleCols[rootState.spreadsheet.selectedWorksheetName][
          state.visibleCols[rootState.spreadsheet.selectedWorksheetName]
            .length - 1
        ].colIndex ||
      nextCellObj.c <
        state.visibleCols[rootState.spreadsheet.selectedWorksheetName][0]
          .colIndex
    ) {
      // if col is not visible
      let tmpVisibleCol = [];
      let tmpX = 0;
      if (position.indexOf("right") > -1) {
        for (let i = nextCellObj.c; i >= 0; i--) {
          let tmpObj = rootGetters["spreadsheet/getColIndex"](
            i,
            rootState.spreadsheet.selectedWorksheetName
          );
          tmpX += tmpObj.colWidth;
          if (tmpX >= state.viewWidth) {
            break;
          }
          tmpVisibleCol.unshift(tmpObj);
        }
        if (tmpX < state.viewWidth) {
          for (
            let i = nextCellObj.c + 1;
            i <=
            rootState.spreadsheet.colCount[
              rootState.spreadsheet.selectedWorksheetName
            ];
            i++
          ) {
            let tmpObj = rootGetters["spreadsheet/getColIndex"](
              i,
              rootState.spreadsheet.selectedWorksheetName
            );
            tmpX += tmpObj.colWidth;
            if (tmpX >= state.viewWidth) {
              break;
            }
            tmpVisibleCol.push(tmpObj);
          }
        }
      } else if (position.indexOf("left") > -1) {
        for (
          let i = nextCellObj.c;
          i <=
          rootState.spreadsheet.colCount[
            rootState.spreadsheet.selectedWorksheetName
          ];
          i++
        ) {
          let tmpObj = rootGetters["spreadsheet/getColIndex"](
            i,
            rootState.spreadsheet.selectedWorksheetName
          );
          tmpVisibleCol.push(tmpObj);
          tmpX += tmpObj.colWidth;
          if (tmpX >= state.viewWidth) {
            break;
          }
        }
        if (tmpX < state.viewWidth) {
          for (let i = nextCellObj.c - 1; i >= 0; i--) {
            let tmpObj = rootGetters["spreadsheet/getColIndex"](
              i,
              rootState.spreadsheet.selectedWorksheetName
            );
            tmpVisibleCol.unshift(tmpObj);
            tmpX += tmpObj.colWidth;
            if (tmpX >= state.viewWidth) {
              break;
            }
          }
        }
      }
      if (tmpVisibleCol.length > 0) {
        commit("updateVisibleCol", {
          sheetName: rootState.spreadsheet.selectedWorksheetName,
          visibleCol: tmpVisibleCol,
        });
      }
    }

    if (
      nextCellObj.r >
        state.visibleRows[rootState.spreadsheet.selectedWorksheetName][
          state.visibleRows[rootState.spreadsheet.selectedWorksheetName]
            .length - 1
        ].rowIndex ||
      nextCellObj.c <
        state.visibleRows[rootState.spreadsheet.selectedWorksheetName][0]
          .rowIndex
    ) {
      // if row is not visible
      let tmpVisibleRow = [];
      let tmpY = 0;
      if (position.indexOf("bottom") > -1) {
        for (let i = nextCellObj.r; i >= 0; i--) {
          let tmpObj = rootGetters["spreadsheet/getRowIndex"](
            i,
            rootState.spreadsheet.selectedWorksheetName
          );
          tmpY += tmpObj.rowHeight;
          if (tmpY >= state.viewHeight) {
            break;
          }
          tmpVisibleRow.unshift(tmpObj);
        }
        if (tmpY < state.viewHeight) {
          for (
            let i = nextCellObj.r + 1;
            i <=
            rootState.spreadsheet.rowCount[
              rootState.spreadsheet.selectedWorksheetName
            ];
            i++
          ) {
            let tmpObj = rootGetters["spreadsheet/getRowIndex"](
              i,
              rootState.spreadsheet.selectedWorksheetName
            );
            tmpY += tmpObj.rowHeight;
            if (tmpY >= state.viewHeight) {
              break;
            }
            tmpVisibleRow.push(tmpObj);
          }
        }
      } else if (position.indexOf("top") > -1) {
        for (
          let i = nextCellObj.r;
          i <=
          rootState.spreadsheet.rowCount[
            rootState.spreadsheet.selectedWorksheetName
          ];
          i++
        ) {
          let tmpObj = rootGetters["spreadsheet/getRowIndex"](
            i,
            rootState.spreadsheet.selectedWorksheetName
          );
          tmpVisibleRow.push(tmpObj);
          tmpY += tmpObj.rowHeight;
          if (tmpY >= state.viewHeight) {
            break;
          }
        }
        if (tmpY < state.viewHeight) {
          for (let i = nextCellObj.r - 1; i >= 0; i--) {
            let tmpObj = rootGetters["spreadsheet/getRowIndex"](
              i,
              rootState.spreadsheet.selectedWorksheetName
            );
            tmpVisibleRow.unshift(tmpObj);
            tmpY += tmpObj.rowHeight;
            if (tmpY >= state.viewHeight) {
              break;
            }
          }
        }
      }
      if (tmpVisibleRow.length > 0) {
        commit("updateVisibleRow", {
          sheetName: rootState.spreadsheet.selectedWorksheetName,
          visibleRow: tmpVisibleRow,
        });
      }
    }
    return true;
  },
  remoteToggleDependencySquare({ commit, state }, payload) {
    if (typeof payload === "undefined")
      commit("setDependencySquare", !state.showDependencySquare);
    else commit("setDependencySquare", payload);
  },
  remoteTogglePrecedencySquare({ commit, state }, payload) {
    if (typeof payload === "undefined")
      commit("setPrecedencySquare", !state.showPrecedencySquare);
    else commit("setPrecedencySquare", payload);
  },
  remoteUpdateMenuHeight({ commit }, height) {
    const menuHeight = 50 * Math.ceil(height / 50);
    commit("updateMenuHeight", {
      height: menuHeight > 50 ? menuHeight : 50,
    });
  },
  async remoteUpdateStageRendering({ commit }, trueOrFalse) {
    commit("updateStageRendering", trueOrFalse);
  },
  async remoteUpdateInputCellDetails({ commit, state }, cellObj) {
    commit("updateInputCellDetails", {
      ...cellObj,
      rememberVisibleR: state.visibleRows[cellObj.sheetName],
      rememberVisibleC: state.visibleCols[cellObj.sheetName],
    });
    return true;
  },
};

<template>
  <b-container fluid id="home-container">
    <div id="blue-bg-div"></div>
    <b-row class="login-header" no-gutters align-v="center">
      <b-col sm="2" md="4" cols="6" class="logo-col"> </b-col>
    </b-row>
    <client-only>
      <nuxt />
    </client-only>
    <div id="error-container">
      <b-button class="invisible" id="error-button"></b-button>
      <error-popover
        v-if="showIEError"
        containerId="entity-container"
        targetButtonId="error-button"
        title="Change Browser"
        description="Microsoft no longer provides security or technical support for Internet Explorer. Please use a different browser like Google Chrome, Microsoft Edge, Mozilla Firefox or Apple Safari."
        ref="error-popover"
      />
    </div>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import ErrorPopover from "~/components/popovers/ErrorPopover";

export default {
  data() {
    return {
      showIEError: true
    };
  },
  middleware: "entityCheck",

  mounted() {
    this.$nextTick(function () {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer, return version number
        this.showError();
      }

      this.$store.dispatch(
        "app_store/remoteUpdateWindowWidth",
        window.innerWidth
      );
      this.$store.dispatch(
        "app_store/remoteUpdateWindowHeight",
        window.innerHeight
      );
    });
  },

  methods: {
    ...mapMutations({
      setContent: "contentful/setContent"
    }),
    showError() {
      this.$refs["error-popover"].show();
    }
  },
  components: {
    "error-popover": ErrorPopover
  }
};
</script>

<style scoped>
#home-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 0 3.5em;
  min-height: 100vh;
  position: relative;
  /* width: 100vw; */
  /* overflow-y: auto; */
}

#blue-bg-div {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  background-size: cover;
  background-repeat: repeat;
  background-image: url("~/assets/images/bg_home.png");
  z-index: 1;
}

.login-header {
  margin-top: 10px;
  justify-content: space-between;
  height: 30px;
}
.logo-container {
  margin-left: -12px;
}

.title {
  display: block;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
  letter-spacing: 1px;
  margin-top: 17vh;
  margin-bottom: 5vh;
  text-align: center;
}

#error-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.logo-col {
  position: fixed;
  top: 0;
}
.ranos-bg-logo {
  width: 90%;
}

.ranos-logo-container {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 1000;
}
/* Mobile Portrait */
@media only screen and (max-width: 600px) and (orientation: portrait) {
  #home-container {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 0 1rem !important;
    min-height: 100vh;
    position: relative;
    /* width: 100vw; */
  }

  #blue-bg-div {
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 100%;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    background-size: cover;
    background-repeat: repeat;
    z-index: 1;
  }

  .page-title {
    color: white;
    font-size: 12px;
  }

  .title {
    display: block;
    font-weight: 600;
    font-size: 30px;
    color: white;
    margin-top: 10vh;
    margin-bottom: 5vh;
    text-align: center;
  }
  .subtitle {
    text-align: center;
    font-size: 18px;
    color: white;
  }

  .mobile-none {
    display: none;
  }
  #landscape-bg-div {
    display: none;
  }
}

/* Mobile Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) and (orientation: landscape) {
  #landscape-bg-div {
    display: flex !important;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(13, 30, 44);
    align-items: center;
    z-index: 10;
  }
  #landscape-bg-div h1 {
    color: white;
  }
  #landscape-bg-div img {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .sub-title {
    margin-bottom: 15px;
  }
  #landscape-bg-div {
    display: flex !important;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(13, 30, 44);
    align-items: center;
    z-index: 10;
  }
  #landscape-bg-div h1 {
    color: white;
  }
  #landscape-bg-div img {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
}

/*Tablet Landscape*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .logo-col {
    max-width: 35%;
    flex: 35%;
    margin-left: -2%;
  }
}
/*Mobile Landscape*/
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .ranos-bg-logo {
    width: 100%;
    margin-left: -2%;
  }
}
</style>

<template>
  <div class="loading-text-dots">
    <div :style="{ width: (size + 6) * 3 + 3 + 'px' }" class="spinner">
      <div class="bounce1" :style="style"></div>
      <div class="bounce2" :style="style"></div>
      <div class="bounce3" :style="style"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 6
    },
    color: {
      type: String,
      default: "white"
    }
  },

  computed: {
    style() {
      return {
        width: this.size + "px",
        height: this.size + "px",
        backgroundColor: this.color
      };
    }
  }
};
</script>

<style lang="sass">
@import "style.sass"
</style>

<style lang="sass" scoped>
.loading-text-dots
  .spinner
    text-align: center
    overflow: hidden

  .spinner > div
    float: left
    margin: 3px
    background-color: #333
    border-radius: 100%
    animation: sk-bouncedelay 1.4s infinite ease-in-out both

  .spinner .bounce1
    animation-delay: -0.32s

  .spinner .bounce2
    animation-delay: -0.16s

  @keyframes sk-bouncedelay
    0%, 80%, 100%
      transform: scale(0)
    40%
      transform: scale(1.0)
</style>

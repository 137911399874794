import Vue from "vue";
import moment from "moment";

import {
  GetFormattedDisplayForSeconds,
  GetMMHHDisplayForSeconds
} from "assets/js/DisplayFormattingHelper";

import { LimitChartToolTipCharacters } from "../assets/js/ChartHelpers";

export const state = () => ({
  LOG_DATEITME_FORMAT: "YYYYMMDDHHmm",
  DISPLAY_LOG_DATE: "DD MMMM YYYY",
  DISPLAY_LOG_TIME: "hh:mma",
  entityActivityLog: [],
  memberActivityLog: {},
  securityActivityLog: {},
  changeActivityLog: {},
  memberNotificationLog: {},
  memberAccessLog: {},
  notificationLogFields: [
    {
      key: "date",
      label: "Date",
      sortable: false
    },
    {
      key: "title",
      label: "Title",
      sortable: false
    },
    {
      key: "securityTickerDisplay",
      label: "Security",
      sortable: false
    },
    {
      key: "totalEngagementTimeStr",
      label: "Engagement Time",
      sortable: false
    }
  ],
  /*
      memberAccessLogFields: [
        {
          key: "date",
          label: "Date",
          sortable: false,
        },
        {
          key: "time",
          label: "Time",
          sortable: false,
        },
        {
          key: "device",
          label: "Device",
          sortable: false,
        },
        {
          key: "page_views",
          label: "Page views",
          sortable: false,
        },
        {
          key: "session_time",
          label: "Duration",
          sortable: false,
        },
      ],
      */
  tabletMemberAccessLogFields: [
    {
      key: "date",
      label: "Date",
      sortable: false
    },
    {
      key: "time",
      label: "Time (UTC)",
      sortable: false
    },
    {
      key: "page_views",
      label: "Page views",
      sortable: false
    },

    {
      key: "session_time",
      label: "Duration",
      sortable: false
    }
  ],
  tabletNotificationLogFields: [
    {
      key: "date",
      label: "Date",
      sortable: false
    },
    {
      key: "title",
      label: "Title",
      sortable: false
    },
    {
      key: "securityTickerDisplay",
      label: "Security",
      sortable: false
    },
    {
      key: "totalEngagementTimeStr",
      label: "Engagement Time",
      sortable: false
    }
  ]
});
export const getters = {
  getMemberNotificationLogById: (state) => {
    return (memberId) => {
      const notificationLog = [];
      if (state.memberNotificationLog[memberId]) {
        for (const object of state.memberNotificationLog[memberId]) {
          const totalEngagementTime = object.total_engagement_time || 0;
          const totalEngagementTimeStr =
            GetFormattedDisplayForSeconds(totalEngagementTime);
          let securityNameTickerDisplay = LimitChartToolTipCharacters(
            object.security_name
          );
          if (object.ticker != null) {
            securityNameTickerDisplay += " (" + object.ticker + ")";
          }

          // Format the change_published_at date as the standard ranos display date
          const formatString = "YYYY-MM-DD HH:mm:ss";
          const changePublishedAt = object.change_published_at;
          const parsedDate = moment(changePublishedAt, formatString);
          const formattedDate = parsedDate.format(state.DISPLAY_LOG_DATE);
          const latestEngagementTimeDate = moment(
            object.latest_engagement_time,
            formatString
          );
          const latestEngagementStr = latestEngagementTimeDate.format(
            state.DISPLAY_LOG_DATE
          );

          notificationLog.push({
            date: formattedDate,
            title: object.change_title || "",
            security: object.security_name || "",
            ticker: object.ticker || "",
            securityTickerDisplay: securityNameTickerDisplay,
            opens: object.open_cnt || 0,
            download: object.click_cnt || 0,
            totalEngagementTime: totalEngagementTime,
            averageEngagementTime: object.average_engagement_time || 0,
            totalEngagementTimeStr: totalEngagementTimeStr,
            latestEngagementTime: latestEngagementStr
          });
        }
      }
      return notificationLog;
    };
  },
  getMemberAccessLogById: (state, getters, rootState, rootGetters) => {
    return (memberId) => {
      if (!state.memberAccessLog[memberId]) return [];
      return state.memberAccessLog[memberId].map((tmpObj) => {
        const tmpMomentObj = moment.tz(
          tmpObj.entity_datetime,
          state.LOG_DATEITME_FORMAT,
          "Pacific/Auckland"
        );
        return {
          date: tmpMomentObj.local().format(state.DISPLAY_LOG_DATE),
          time: tmpMomentObj.local().format(state.DISPLAY_LOG_TIME),
          page_views: tmpObj.page_views,
          device: tmpObj.device,
          session_time: tmpObj.session_time
        };
      });
    };
  },
  getMemberLogById: (state) => {
    return (memberId) => {
      const activityLog = [];
      if (state.memberActivityLog[memberId]) {
        for (const object of state.memberActivityLog[memberId]) {
          if (object.activity_log_created_at) {
            object.activity_log_created_at_formatted = moment(
              object.activity_log_created_at,
              state.DISPLAY_LOG_DATE
            ).format(state.DISPLAY_LOG_DATE);
            activityLog.push(object);
          }
        }
      }
      return activityLog;
    };
  },
  getEntityLogById: (state) => {
    const activityLog = [];
    if (state.entityActivityLog.length > 0) {
      for (const object of state.entityActivityLog) {
        if (object.activity_log_created_at) {
          object.activity_log_created_at_formatted = moment(
            object.activity_log_created_at,
            state.DISPLAY_LOG_DATE
          ).format(state.DISPLAY_LOG_DATE);
          activityLog.push(object);
        }
      }
    }
    return activityLog;
  },
  getSecurityLogById: (state) => {
    return (securityId) => {
      const returnLog = [];
      if (state.securityActivityLog[securityId]) {
        for (const object of state.securityActivityLog[securityId]) {
          if (object.activity_log_created_at) {
            object.activity_log_created_at_formatted = moment(
              object.activity_log_created_at,
              state.DISPLAY_LOG_DATE
            ).format(state.DISPLAY_LOG_DATE);
            returnLog.push(object);
          }
        }
      }
      return returnLog;
    };
  },

  getChangeLogById: (state) => {
    return (changeId) => {
      const returnLog = [];
      if (state.changeActivityLog[changeId]) {
        for (const object of state.changeActivityLog[changeId]) {
          if (object.activity_log_created_at) {
            object.activity_log_created_at_formatted = moment(
              object.activity_log_created_at,
              state.DISPLAY_LOG_DATE
            ).format(state.DISPLAY_LOG_DATE);
            returnLog.push(object);
          }
        }
      }
      return returnLog;
    };
  },
  memberAccessLogFields: (state, getters, rootState, rootGetters) => [
    {
      key: "date",
      label: "Date",
      sortable: false
    },
    {
      key: "time",
      label:
        "Time (" +
        rootGetters["app_store/getUtcToTzTime"]("12", "z", "MM") +
        ")",
      sortable: false
    },
    {
      key: "device",
      label: "Device",
      sortable: false
    },
    {
      key: "page_views",
      label: "Page views",
      sortable: false
    },
    {
      key: "session_time",
      label: "Duration",
      sortable: false
    }
  ]
};
export const mutations = {
  //Member list update after getting members from backend
  appendMemberActivityLog(state, { memberId, value }) {
    if (state.memberActivityLog[memberId]) {
      Vue.set(state.memberActivityLog, memberId, [
        ...state.memberActivityLog[memberId],
        ...value
      ]);
    } else {
      Vue.set(state.memberActivityLog, memberId, value);
    }
  },
  appendSecurityActivityLog(state, { securityId, value }) {
    if (state.securityActivityLog[securityId]) {
      Vue.set(state.securityActivityLog, securityId, [
        ...state.securityActivityLog[securityId],
        ...value
      ]);
    } else {
      Vue.set(state.securityActivityLog, securityId, value);
    }
  },
  appendChangeActivityLog(state, { changeId, value }) {
    if (state.changeActivityLog[changeId]) {
      Vue.set(state.changeActivityLog, changeId, [
        ...state.changeActivityLog[changeId],
        ...value
      ]);
    } else {
      Vue.set(state.changeActivityLog, changeId, value);
    }
  },
  updateMemberActivityLog(state, { memberId, value }) {
    Vue.set(state.memberActivityLog, memberId, value);
  },
  updateEntityActivityLog(state, value) {
    Vue.set(state, "entityActivityLog", value);
  },
  updateSecurityActivityLog(state, { securityId, value }) {
    Vue.set(state.securityActivityLog, securityId, value);
  },
  updateChangeActivityLog(state, { changeId, value }) {
    Vue.set(state.changeActivityLog, changeId, value);
  },
  updateNotificationLog(state, { memberId, value }) {
    if (state.memberNotificationLog[memberId]) {
      Vue.set(state.memberNotificationLog, memberId, [
        ...state.memberNotificationLog[memberId],
        ...value
      ]);
    } else {
      Vue.set(state.memberNotificationLog, memberId, value);
    }
  },
  updateMemberAccessLog(state, { memberId, value }) {
    if (state.memberAccessLog[memberId]) {
      Vue.set(state.memberAccessLog, memberId, [
        ...state.memberAccessLog[memberId],
        ...value
      ]);
    } else {
      Vue.set(state.memberAccessLog, memberId, value);
    }
  },
  removeMemberNotificationLog(state, memberId) {
    Vue.set(state.memberNotificationLog, memberId, []);
  },
  removeMemberActivityLog(state, memberId) {
    Vue.set(state.memberAccessLog, memberId, []);
  }
};

export const actions = {
  removeEntityActivityLog({ commit }) {
    commit("updateEntityActivityLog", []);
  },
  removeMemberActivityLog({ commit }, memberId) {
    commit("updateMemberActivityLog", {
      memberId: memberId,
      value: []
    });
  },
  removeSecurityActivityLog({ commit }, securityId) {
    commit("updateSecurityActivityLog", {
      securityId: securityId,
      value: []
    });
  },
  removeChangeActivityLog({ commit }, changeId) {
    commit("updateChangeActivityLog", {
      changeId: changeId,
      value: []
    });
  },
  getEntityActivityLog({ commit, rootState, state }, payload) {
    // payload format: {per_page, page_offset}
    return this.$axios
      .$get(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/Entity/${rootState.user.selectedShownEntity}`,
        {
          params: payload
        }
      )
      .then((r) => {
        commit("updateEntityActivityLog", [...state.entityActivityLog, ...r]);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getMemberActivityLog({ commit, rootState }, { memberId, ...payload }) {
    // payload format: {per_page, page_offset}
    return this.$axios
      .$get(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/Member/${memberId}`,
        {
          params: payload
        }
      )
      .then((r) => {
        commit("appendMemberActivityLog", {
          memberId: memberId,
          value: r
        });
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getSecurityActivityLog({ commit, rootState }, { securityId, ...payload }) {
    // payload format: {per_page, page_offset}
    return this.$axios
      .$get(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/Security/${securityId}`,
        {
          params: payload
        }
      )
      .then((r) => {
        commit("appendSecurityActivityLog", {
          securityId: securityId,
          value: r
        });
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getChangeActivityLog({ commit, rootState }, { changeId, ...payload }) {
    return this.$axios
      .$get(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/Change/${changeId}`,
        {
          params: payload
        }
      )
      .then((r) => {
        commit("appendChangeActivityLog", {
          changeId,
          value: r
        });
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  downloadActivityLog(
    { commit, rootState },
    { section, sectionId, ...payload }
  ) {
    return this.$axios
      .$post(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/${section}/${sectionId}/download-list`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadNotificationLog({ commit, rootState }, payload) {
    return this.$axios
      .$post(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/notification-log/download`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadAccessLog({ commit, rootState }, payload) {
    return this.$axios
      .$post(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/entity-access-log/download`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadPublicationLog({ commit, rootState }, payload) {
    return this.$axios
      .$post(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/publication-log/download`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  async saveReportLog({ rootState, rootGetters }, security_report_id) {
    try {
      const response = await this.$axios.$post(
        `/v2/logs/entity/${rootState.user.selectedShownEntity}/member/${rootGetters["user/currentEntity"].member_id}/report/${security_report_id}/save_report_log`
      );
      if (response) return true;
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async downloadEngagementLog({ rootState }, payload) {
    try {
      const response = await this.$axios.$post(
        `/v2/member/entity_id/${rootState.user.selectedShownEntity}/member_engagement_log`,
        payload
      );
      if (response) return true;
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  remoteGetNotificationLog({ commit, state, rootState }, memberId) {
    let page_offset = 0;
    if (state.memberNotificationLog[memberId])
      page_offset = state.memberNotificationLog[memberId].length;
    return this.$axios
      .$get(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/notification-log/list-published`,
        {
          params: {
            member_id: memberId,
            page_offset: page_offset
          }
        }
      )
      .then((r) => {
        commit("updateNotificationLog", {
          memberId: memberId,
          value: r.data
        });
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.message
        };
      });
  },
  remoteGetMemberAccessLog({ commit, state, rootState }, memberId) {
    let page_offset = 0;
    if (state.memberAccessLog[memberId])
      page_offset = state.memberAccessLog[memberId].length;
    return this.$axios
      .$get(
        `/v1/activity/entity/${rootState.user.selectedShownEntity}/entity-access-log`,
        {
          params: {
            member_id: memberId,
            page_offset: page_offset
          }
        }
      )
      .then((r) => {
        commit("updateMemberAccessLog", {
          memberId: memberId,
          value: r.data
        });
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.message
        };
      });
  },
  remotePostPrintIpoReport(ctx, { entityId, reportId }) {
    return this.$axios.$post(
      `/v1/activity/entity/${entityId}/report/${reportId}`,
      { access_type: "save" }
    );
  },
  remotePostAcceptReportTerms(ctx, { entityId, reportId }) {
    return this.$axios.$post(
      `/v1/activity/entity/${entityId}/report/${reportId}`,
      { access_type: "open" }
    );
  },
  remoteRemoveLog({ commit }, { logType, logTypeId }) {
    switch (logType) {
      case "member notification":
        commit("removeMemberNotificationLog", logTypeId);
        break;
      case "member activity":
        commit("removeMemberActivityLog", logTypeId);
        break;
    }
  },
  async remotePostReportLog(
    { commit, rootGetters, rootState },
    { entityId, reportId, memberId, actionType, url, testFlag }
  ) {
    if (!memberId) {
      if (
        rootGetters["user/currentEntity"] &&
        rootGetters["user/currentEntity"].member_id
      ) {
        memberId = rootGetters["user/currentEntity"].member_id;
      } else {
        console.error("No Member Id Found");
        return;
      }
    }
    try {
      const r = await this.$axios.$post(
        `/v1/activity/entity/${entityId}/report_log/${reportId}`,
        {
          member_id: memberId,
          action_type: actionType,
          url: { url: url },
          test_flag: testFlag
        }
      );
      return r;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
};

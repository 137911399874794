import Vue from "vue";
import qs from "qs";
import { listPageSize } from "~/constants";

export const state = () => ({
  analystsList: [],
  descriptorList: [],
  descriptorsFilterList: [],
  securityAnalystsList: [],
  recipientList: [],
  engagementLogs: [],
  engagementBySecurity: [],
  currentMember: {},
  // selectedMemberList: [],
  totalMemberCount: 0,
  filteredMemberList: [],
  loadedMemberList: [],
  selectedMemberMetrics: [],
  memberCompanyList: [],
  statusList: [
    {
      id: "1",
      name: "Active",
      internal_name: "active"
    },
    {
      id: "2",
      name: "Inactive",
      internal_name: "inactive"
    }
  ],
  allStatusList: [
    {
      id: "0",
      name: "All"
    },
    {
      id: "1",
      name: "Active",
      internal_name: "active"
    },
    {
      id: "2",
      name: "Inactive",
      internal_name: "inactive"
    }
  ],
  autoIncludeOption: [
    {
      id: "1",
      name: "Yes"
    },
    {
      id: "2",
      name: "No"
    }
  ],
  memberStats: {},
  memberLoading: false,
  memberSorting: false,
  memberSortOptions: {
    sort_by: "member_fullname",
    sort_type: "asc"
  },
  memberSelectionOptions: {
    type: "all",
    exclude: []
  },
  memberList: [],
  memberAuthorisedSecurities: [],
  securitySelectorsList: [],
  reasonSelectorsList: []
});
export const getters = {
  getMetricNames: (state) =>
    state.selectedMemberMetrics.map(
      (tmpMemberMetric) => tmpMemberMetric.internal_name
    ),
  getDefaultMemberMetricSelectionList: (state, getters, rootState) => {
    let selectedMetrics = new Array(state.selectedMemberMetrics.length);
    const unselectedMetric = [];
    const getAllId = rootState.filter.memberGroups.find(
      (tmpGroup) => tmpGroup.name === "All"
    )?.id;
    for (const tmpMetric of rootState.filter.memberMetrics) {
      const newBoostrapMultiSelectObj = {
        value: tmpMetric,
        text: `${tmpMetric.name}`
      };
      // if its not all group its always unselected
      if (tmpMetric.group !== getAllId) {
        unselectedMetric.push(newBoostrapMultiSelectObj);
        continue;
      }
      const selectedIndex = state.selectedMemberMetrics.findIndex(
        (defaultSelectedMetric) => defaultSelectedMetric === tmpMetric.id
      );
      if (
        selectedIndex >= 0 &&
        unselectedMetric.findIndex(
          (checkMetric) => checkMetric.value.key === tmpMetric.internal_name
        ) < 0
      ) {
        // if its in linked list
        // selectedMetrics.push(newBoostrapMultiSelectObj);
        if (newBoostrapMultiSelectObj) {
          selectedMetrics.splice(selectedIndex, 1, newBoostrapMultiSelectObj);
        }
      } else {
        unselectedMetric.push(newBoostrapMultiSelectObj);
      }
    }
    selectedMetrics = selectedMetrics.filter((it) => {
      return it != null;
    });
    return {
      selectedMetrics,
      unselectedMetric
    };
  },
  getBootstrapSelectOptions: () => {
    return (tmpArray) =>
      tmpArray.map((tmpMember) => ({
        value: {
          id: tmpMember.member_id,
          name: tmpMember.member_fullname,
          title: tmpMember.member_company_title,
          company: tmpMember.member_company_name,
          email: tmpMember.member_email,
          mobile: tmpMember.member_mobile_no,
          status: tmpMember.member_status,
          notification_type: tmpMember.notification_type,
          notification_channel_name: tmpMember.notification_channel_name
        },
        text:
          tmpMember.member_fullname +
          (tmpMember.member_status === "Inactive" ? "*" : "")
      }));
  },
  getDefaultMemberSelectionList: (state) => {
    const selectedMember = [];
    const unselectedMember = [];
    switch (state.memberSelectionOptions.type) {
      case "none":
        for (const tmpMember of state.memberList) {
          const selectedIndex = state.memberSelectionOptions.exclude.findIndex(
            (checkMember) => checkMember === tmpMember.value.id
          );
          if (selectedIndex >= 0) {
            selectedMember.push(tmpMember);
          } else {
            unselectedMember.push(tmpMember);
          }
        }
        break;
      default:
        for (const tmpMember of state.memberList) {
          const selectedIndex = state.memberSelectionOptions.exclude.findIndex(
            (checkMember) => checkMember === tmpMember.value.id
          );
          if (selectedIndex >= 0) {
            unselectedMember.push(tmpMember);
          } else {
            selectedMember.push(tmpMember);
          }
        }
        break;
    }
    return {
      selectedMember,
      unselectedMember
    };
  },
  getMemberAvailableSecurities: (state, getters, rootState, rootGetters) =>
    state.memberAuthorisedSecurities &&
    state.memberAuthorisedSecurities.length > 0
      ? rootGetters["security/getBootstrapSelectOptions"](
          state.memberAuthorisedSecurities
        ).sort((a, b) => {
          // sort alphabetically
          if (a.text < b.text) return -1;
          if (a.text > b.text) return 1;
          return 0;
        })
      : [],
  getMemberPlatformAccessObject: (state, getters, rootState) => {
    if (
      state.currentMember &&
      state.currentMember.platform_access &&
      rootState.role.roles
    ) {
      return (
        rootState.role.roles.find(
          (obj) => obj.name === state.currentMember.platform_access
        ) || {}
      );
    }
    return {};
  }
};
export const mutations = {
  updateFilteredMemberList(state, arrayList) {
    Vue.set(state, "filteredMemberList", arrayList);
  },
  updateTotalMemberCount(state, count) {
    Vue.set(state, "totalMemberCount", count);
  },
  updateMemberLoadedList(state, loadedMembers) {
    Vue.set(state, "loadedMemberList", loadedMembers);
  },
  updateAnalystsList(state, newList) {
    Vue.set(state, "analystsList", newList);
  },
  updateSecurityAnalystsList(state, newList) {
    Vue.set(state, "securityAnalystsList", newList);
  },
  updateEngagementLogs(state, engagements) {
    Vue.set(state, "engagementLogs", engagements);
  },
  updateEngagementBySecurity(state, securityEngagements) {
    Vue.set(state, "engagementBySecurity", securityEngagements);
  },
  updateRecipientList(state, newList) {
    Vue.set(state, "recipientList", newList);
  },
  //Update selected metric list in memberlist page
  updateSelectedMetrics(state, selectedMetrics) {
    Vue.set(state, "selectedMemberMetrics", selectedMetrics);
  },
  //Member update when editing member in member page
  updateCurrentMember(state, member) {
    Vue.set(state, "currentMember", member);
  },
  updateMemberDetails(state, member) {
    for (const key in member) {
      Vue.set(state.currentMember, key, member[key]);
    }
  },
  updateMemberCompany(state, value) {
    state.currentMember.member_company_id = value;
  },
  updateAuthorisation(state, value) {
    state.currentMember.member_authorizations = value;
  },
  updatePlatformAccess(state, value) {
    state.currentMember.platform_access = value.name;
  },
  updateCompanyList(state, value) {
    state.memberCompanyList = value;
  },
  //Change order of companies in member page
  changeOrderCompanyList(state, id) {
    const index = state.memberCompany.find(
      (tmpCompany) => tmpCompany.id === id
    );
    if (index >= 0) {
      const newCompany1 = state.memberCompanyList[index];
      const newCompany2 = state.memberCompanyList[index - 1];
      const neArray = [...state.memberCompanyList].splice(
        index - 1,
        2,
        newCompany1,
        newCompany2
      );
      Vue.set(state, "memberCompanyList", neArray);
    }
  },
  //Get member company list
  updateMemberCompanyList(state, arrayList) {
    Vue.set(
      state,
      "memberCompanyList",
      arrayList.map((object) => ({
        id: object.member_company_id,
        value: object.member_company_name,
        order: object.member_company_order
      }))
    );
  },
  updateMemberStats(state, object) {
    Vue.set(state, "memberStats", object);
  },
  updateSecuritySelectors(state, arrayList) {
    Vue.set(state, "securitySelectorsList", arrayList);
  },
  updateReasonSelectors(state, arrayList) {
    Vue.set(state, "reasonSelectorsList", arrayList);
  },
  toggleMemberLoading(state, trueOrFalse) {
    state.memberLoading = trueOrFalse;
  },
  toggleMemberSorting(state, trueOrFalse) {
    state.memberSorting = trueOrFalse;
  },
  updateSavedSorted(state, { sort_by, sort_type }) {
    if (sort_by) Vue.set(state.memberSortOptions, "sort_by", sort_by);
    if (sort_type) Vue.set(state.memberSortOptions, "sort_type", sort_type);
  },
  updateSavedChanges(state, { type, exclude }) {
    Vue.set(state, "memberSelectionOptions", {
      type,
      exclude
    });
  },
  updateMemberList(state, newList) {
    Vue.set(state, "memberList", newList);
  },
  updateMemberAuthorisedSecurities(state, newList) {
    Vue.set(state, "memberAuthorisedSecurities", newList);
  },
  updateCurrentMemberSystemNotifications(state, new_ids) {
    Vue.set(state.currentMember, "member_subscribed_notifications", new_ids);
  },
  addDescriptor(state, descriptor) {
    state.descriptorList.push(descriptor);
  },
  removeDescriptor(state, descriptorId) {
    const index = state.descriptorList.findIndex(
      (descriptor) => descriptor.descriptor_id === descriptorId
    );
    if (index !== -1) {
      state.descriptorList.splice(index, 1);
    }
  },
  updateDescriptorList(state, arrayList) {
    let newArrayList = [];
    arrayList.map((descriptor) => {
      newArrayList.push({
        descriptor_type_id: descriptor.descriptor_type_id,
        descriptor_id: descriptor.descriptor_id,
        descriptor_value: descriptor.descriptor_value,
        descriptor_order: descriptor.descriptor_order
      });
    });
    if (!newArrayList.find((obj) => obj.descriptor_value === "All")) {
      newArrayList = [
        {
          descriptor_id: 0,
          descriptor_value: "All",
          descriptor_order: 0,
          descriptor_type_id: 0
        },
        ...newArrayList
      ];
    }
    Vue.set(state, "descriptorList", newArrayList);
  },
  updateDescriptorFilterList(state, arrayList) {
    const newArrayList = [];
    arrayList.map((descriptor) => {
      newArrayList.push({
        id: descriptor.descriptor_id,
        name: descriptor.descriptor_value
      });
    });
    Vue.set(state, "descriptorsFilterList", newArrayList);
  }
};
export const actions = {
  getAnalysts({ commit, rootState, getters }) {
    return this.$axios
      .$get(`/v1/member/${rootState.user.selectedShownEntity}`)
      .then((r) => {
        commit("updateAnalystsList", getters.getBootstrapSelectOptions(r));
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getSecurityAnalysts({ commit, rootState, getters }) {
    return this.$axios
      .$get(
        `/v2/member/entity_id/${rootState.user.selectedShownEntity}/get_analysts`
      )
      .then((r) => {
        commit(
          "updateSecurityAnalystsList",
          getters.getBootstrapSelectOptions(r)
        );
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getFilterMembersList({ commit, rootState, getters }, filterOption) {
    filterOption.member_sort_type = "asc";
    filterOption.member_sort_by = "member_fullname";
    filterOption.per_page = 0;
    // filterOption.page_offset = 0;
    filterOption.limited_fields = true;
    return this.$axios
      .$get(`/v1/member/filter/${rootState.user.selectedShownEntity}`, {
        params: filterOption
      })
      .then((r) => {
        commit(
          "updateFilteredMemberList",
          getters.getBootstrapSelectOptions(r.members)
        );
        return {
          action: "accept",
          message: r.members
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error"
        };
      });
  },

  getMembersSelectionList({ commit, rootState, getters }) {
    return this.$axios
      .$get(`/v1/member/filter/${rootState.user.selectedShownEntity}`, {
        params: {
          per_page: 0,
          limited_fields: true
        }
      })
      .then((r) => {
        const newList = getters.getBootstrapSelectOptions(r.members);
        commit("updateTotalMemberCount", r.member_count);
        commit("updateFilteredMemberList", newList);
        commit("updateMemberList", newList);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteLoadMemberListDetails(
    { commit, state, rootState, getters },
    notification_log_included
  ) {
    const perPage = 30;
    if (getters.getDefaultMemberSelectionList.selectedMember.length === 0)
      return false;
    const params = {
      per_page: perPage,
      limited_fields: false,
      page_offset: state.loadedMemberList.length,
      notification_log_included: notification_log_included ? true : false
    };
    return this.$axios
      .$get(`/v1/member/filter/${rootState.user.selectedShownEntity}`, {
        params,
        paramsSerializer: (params) => qs.stringify(params)
      })
      .then((r) => {
        if (!r.members) {
          console.error(r);
          return false;
        }
        if (!state.memberSorting || !state.memberLoading)
          commit("updateMemberLoadedList", [
            ...state.loadedMemberList,
            ...r.members
          ]);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteRemoveLoadedMemberListDetails({ commit }) {
    commit("updateMemberLoadedList", []);
  },
  getMemberCompanies({ commit, rootState }) {
    return this.$axios
      .$get(`/v1/member_company/${rootState.user.selectedShownEntity}`)
      .then((r) => {
        commit("updateMemberCompanyList", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },

  getMemberById({ commit, rootState }, memberId) {
    return this.$axios
      .$get(`/v1/member/${rootState.user.selectedShownEntity}/${memberId}`)
      .then((r) => {
        commit("updateCurrentMember", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getMemberByIdResult({ commit, rootState }, memberId) {
    return this.$axios
      .$get(`/v1/member/${rootState.user.selectedShownEntity}/${memberId}`)
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e
        };
      });
  },
  createMember({ rootState }, member) {
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$post(`/v1/member/${rootState.user.selectedShownEntity}`, member)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  updateMember(
    { commit, rootState, dispatch },
    { member_id, ...updateDetails }
  ) {
    if (!member_id)
      return {
        action: "error",
        message: "no member id"
      };
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/${member_id}`,
        updateDetails
      )
      .then((r) => {
        dispatch(
          "log/getMemberActivityLog",
          {
            memberId: member_id
          },
          {
            root: true
          }
        );
        // $GET return obj but $PUT only requires string, so once $PUT need to update the object before commit so that its similar to $GET
        if (updateDetails.member_company_id) {
          if (
            r.member_company_id &&
            r.member_company_id[0] &&
            r.member_company_id[0].member_company_id
          ) {
            updateDetails.member_company_id = r.member_company_id;
          }
        }
        // NOTE seperated GET api for authorised companies but not put, to reduce the response size when amount of securities is big. for PUT still use the sanme api
        if (updateDetails.member_security_ids) {
          //commit("updateMemberAuthorisedSecurities", updateDetails.member_security_ids);
          dispatch("remoteGetMemberAuthorisedSecurities", member_id);
          delete updateDetails.member_security_ids;
        }
        commit("updateMemberDetails", updateDetails);
        return r;
      })
      .catch((e) => {
        console.log(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  deleteMember({ commit, rootState }, memberId) {
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$delete(`/v1/member/${rootState.user.selectedShownEntity}/${memberId}`)
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },

  createMemberCompany({ rootState }, memberCompany) {
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$post(
        `/v1/member_company/${rootState.user.selectedShownEntity}`,
        memberCompany
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteSendInvite({ rootState, state }) {
    if (!state.currentMember.member_id)
      return {
        action: "error",
        message: "did not select member"
      };
    return this.$axios
      .$post(
        `/v1/member/invite_member/${rootState.user.selectedShownEntity}/${state.currentMember.member_id}`
      )
      .then((r) => {
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadMemberList({ rootState, getters, state }, payload) {
    payload.metrics = getters.getMetricNames;
    // payload.members = state.loadedMemberList.map(
    //   (tmpMember) => tmpMember.member_id
    // );
    return this.$axios
      .$post(
        `/v1/member/${rootState.user.selectedShownEntity}/download-list`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadMemberProfile({ commit, rootState }, payload) {
    return this.$axios
      .$post(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/member/${payload.member_id}/member-profile`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteGetMemberStats({ commit, rootState }, memberId) {
    return this.$axios
      .$get(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/member/${memberId}/member-stats`
      )
      .then((r) => {
        commit("updateMemberStats", r);
        return true;
      })
      .catch((e) => {
        commit("updateMemberStats", {});
        console.error(e);
        return false;
      });
  },
  remoteGetMemberSecuritySelectors({ commit, rootState }, memberId) {
    return this.$axios
      .$get(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/member/${memberId}/security_selectors`
      )
      .then((r) => {
        commit("updateSecuritySelectors", r.selectors);
        return true;
      })
      .catch((e) => {
        commit("updateSecuritySelectors", []);
        console.error(e);
        return false;
      });
  },
  remoteUpdateMemberSecuritySelectors({ rootState }, payload) {
    return this.$axios
      .$put(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/member/${payload.memberId}/security_selectors`,
        {
          selectors: payload.selectors
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetMemberReasonSelectors({ commit, rootState }, memberId) {
    return this.$axios
      .$get(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/member/${memberId}/change_reasons`
      )
      .then((r) => {
        commit("updateReasonSelectors", r.reasons);
        return true;
      })
      .catch((e) => {
        commit("updateReasonSelectors", []);
        console.error(e);
        return false;
      });
  },
  remoteUpdateMemberReasonSelectors({ rootState }, payload) {
    return this.$axios
      .$put(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/member/${payload.memberId}/change_reasons`,
        {
          reasons: payload.selectors
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUpdateSelectedMetrics({ commit, rootState }, newMetrics) {
    // commit("updateSelectedMetrics", newArray);
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/member_list`,
        {
          member_filter_list_options_metrics: newMetrics.map(
            (met) => met.internal_name
          )
        }
      )
      .then((r) => {
        if (r.member_filter_list_options_metrics) {
          commit("updateSelectedMetrics", r.member_filter_list_options_metrics);
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUpdateSelectedMemberList({ commit, state, rootState }, newArray) {
    const thresholdC = state.memberList.length / 2;
    let allOrNone = "all";
    let ArrayList = newArray.map((memberObj) => memberObj.value.id);
    if (newArray.length > thresholdC) {
      const newArrayList = [];
      for (let i = 0; i < state.memberList.length; i++) {
        if (!ArrayList.includes(state.memberList[i].value.id)) {
          newArrayList.push(state.memberList[i].value.id);
        }
      }
      ArrayList = newArrayList;
    } else {
      allOrNone = "none";
    }
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/member_list`,
        {
          member_filter_list_options_target_type: allOrNone,
          member_filter_list_options_target_ids: ArrayList
        }
      )
      .then((r) => {
        commit("updateSavedChanges", {
          type: r.member_filter_list_options_target_type || "all",
          exclude: r.member_filter_list_options_target_ids || []
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteToggleMemberLoading({ commit }, trueOrFalse) {
    commit("toggleMemberLoading", trueOrFalse);
  },
  remoteToggleMemberSorting({ commit }, trueOrFalse) {
    commit("toggleMemberSorting", trueOrFalse);
  },
  remoteGetSavedFilters({ rootState, commit, rootGetters }) {
    // NOTE api in member namespace but is for all 3 pages
    return this.$axios
      .$get(
        `v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/member_list`
      )
      .then((r) => {
        if (r.member_filter_list_options_metrics) {
          commit("updateSelectedMetrics", r.member_filter_list_options_metrics);
        }
        const commitObj = {};
        const sort_by = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "member_sort_by"],
          null
        );
        if (sort_by) {
          commitObj["sort_by"] = sort_by;
        }
        const sort_type = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "member_sort_type"],
          null
        );
        if (sort_type) {
          commitObj["sort_type"] = sort_type;
        }
        commit("updateSavedSorted", commitObj);
        commit("updateSavedChanges", {
          type: r.member_filter_list_options_target_type || "all",
          exclude: r.member_filter_list_options_target_ids || []
        });
      })
      .catch((e) => {
        console.error(e);
        // throw e;
      });
  },
  remotUpdateSavedSorting(
    { rootState, rootGetters, commit },
    { sort_by, sort_type }
  ) {
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/member_list`,
        {
          member_filter_list_options_params: {
            member_sort_by: sort_by,
            member_sort_type: sort_type
          }
        }
      )
      .then((r) => {
        const commitObj = {};
        const new_sort_by = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "member_sort_by"],
          null
        );
        if (new_sort_by) {
          commitObj["sort_by"] = new_sort_by;
        }
        const new_sort_type = rootGetters[
          "app_store/objectNestedPropertyCheck"
        ](r, ["member_filter_list_options_params", "member_sort_type"], null);
        if (new_sort_type) {
          commitObj["sort_type"] = new_sort_type;
        }
        commit("updateSavedSorted", commitObj);
        return true;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  remoteGetMemberAuthorisedSecurities({ rootState, commit }, member_id) {
    return this.$axios
      .$get(
        `/v1/member/${rootState.user.selectedShownEntity}/member/${member_id}/member_securities`
      )
      .then((r) => {
        commit("updateMemberAuthorisedSecurities", r);
        return Promise.resolve(true);
      })
      .catch((e) => {
        console.error(e);
        return Promise.reject(false);
      });
  },
  /*
    remoteGetMemberWithReportOption({ rootState }) {
      return this.$axios
        .get(`v1/member/entity/${rootState.user.selectedShownEntity}/recipients`)
        .then((r) => {
          return r.data || [];
        })
        .catch((e) => {
          console.error(e);
          return [];
        });
    },
    */
  async remoteResetFilteredMembers({ commit, dispatch }, newList) {
    if (newList) {
      commit("updateFilteredMemberList", newList);
    } else {
      await dispatch("getFilterMembersList", {});
    }
  },
  async remoteUpdateMemberSystemNotifications(
    { commit, rootState },
    { member_id, notifications }
  ) {
    return this.$axios
      .$put(
        `/v2/member_subscribe/entity/${rootState.user.selectedShownEntity}/member/${member_id}/update_member_subscribe`,
        {
          global_report_ids: notifications
        }
      )
      .then((r) => {
        commit("updateCurrentMemberSystemNotifications", notifications);
        return {
          action: true,
          msg: "updated"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: false,
          msg: e
        };
      });
  },
  remoteGetTotalMemberList({ commit, rootState, getters }) {
    return this.$axios
      .$get(`/v2/fetch/${rootState.user.selectedShownEntity}/members`)
      .then((r) => {
        commit("updateMemberList", getters.getBootstrapSelectOptions(r));
        commit("updateTotalMemberCount", r.length);
      });
  },
  async getMemberEngagementStats({ commit, rootState }, { memberId }) {
    try {
      const r = await this.$axios.$get(
        `/v1/member/${rootState.user.selectedShownEntity}/member/${memberId}/member-engagement-stats`,
        {
          params: {
            days_back: 180
          }
        }
      );

      // Store this here.....
      commit("updateEngagementLogs", r);
    } catch (e) {
      console.error(e);
    }
  },
  // engagementBySecurity
  async getMemberEngagementBySecurity({ commit, rootState }, { memberId }) {
    try {
      const r = await this.$axios.$get(
        `/v1/member/${rootState.user.selectedShownEntity}/member/${memberId}/member-security-stats`,
        {
          params: {
            days_back: 180,
            per_page: 10
          }
        }
      );
      // Store this here.....
      const engagementSecurityResults = r["engagement_by_security"];
      if (engagementSecurityResults != null) {
        const sortedEngagements = engagementSecurityResults.sort(
          (a, b) =>
            b.percentage_of_reports_downloaded -
            a.percentage_of_reports_downloaded
        );
        commit("updateEngagementBySecurity", sortedEngagements);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getMemberDescriptors({ commit, rootState }) {
    try {
      const response = await this.$axios.$get(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/descriptor`
      );
      if (response) {
        commit("updateDescriptorList", response);
        commit("updateDescriptorFilterList", response);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async createMemberDescriptor({ commit, rootState }, descriptor) {
    try {
      const response = await this.$axios.$put(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/descriptor`,
        descriptor
      );
      if (response) {
        commit("addDescriptor", descriptor);
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateMemberDescriptor({ commit, rootState }, updateDetails) {
    try {
      const response = await this.$axios.$patch(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/descriptor/${updateDetails.descriptor_id}`,
        updateDetails
      );
      if (response) {
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async deleteMemberDescriptor({ rootState }, descriptor_id) {
    try {
      const response = await this.$axios.$delete(
        `/v1/member/entity/${rootState.user.selectedShownEntity}/descriptor/${descriptor_id}`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async uploadResourceToMemberTempStorage({ rootState, rootGetters }, payload) {
    const member_id = rootGetters["user/currentEntity"].member_id;
    const url = `/v2/member/entity_id/${rootState.user.selectedShownEntity}/member_id/${member_id}/temp_storage_internal`;
    try {
      return await this.$axios.$put(url, payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async remoteGetPdfByTempStorage({ rootState, rootGetters }, payload) {
    const member_id = rootGetters["user/currentEntity"].member_id;
    const url = `/v2/generate_pdf/${rootState.user.selectedShownEntity}/${member_id}/generate_pdf_by_member_temp_storage_internal`;
    try {
      return await this.$axios.$post(url, payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};

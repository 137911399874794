<template>
  <div id="menu">
    <div class="logo-wrapper">
      <Logo id="logo" @click.native="$emit('goHome')" />
    </div>

    <div class="buttons-wrapper">
      <div class="buttons">
        <component
          :is="button.type == 'link' ? 'LinkWrapper' : 'div'"
          v-for="(button, index) in activeButtons"
          :key="index"
          :to="button.value"
        >
          <ButtonLogin
            small
            :tonal="!button.important"
            @click="triggerButton(button)"
          >
            {{ button.name }}
          </ButtonLogin>
        </component>
      </div>
    </div>

    <div class="mobile-menu-button">
      <ButtonLogin small tonal icon @click="menuOpened = true">
        <i class="mi-menu" style="font-size: 22px"></i>
      </ButtonLogin>
    </div>

    <div
      class="mobile-menu-overlay"
      :class="{ active: menuOpened }"
      @click="menuOpened = false"
    ></div>

    <div class="mobile-menu-content" :class="{ active: menuOpened }">
      <component
        :is="button.type == 'link' ? 'LinkWrapper' : 'div'"
        v-for="(button, index) in reverseActiveButtons"
        :key="index"
        :to="button.value"
      >
        <ButtonLogin
          small
          style="width: 100%"
          :tonal="!button.important"
          @click="triggerButton(button)"
        >
          {{ button.name }}
        </ButtonLogin>
      </component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonLogin from "~/components/new/ButtonLogin";
import LinkWrapper from "~/components/new/Options/LinkWrapper";
import Logo from "~/components/new/Logo";

export default {
  components: {
    ButtonLogin,
    LinkWrapper,
    Logo
  },

  data: () => ({
    lightVisible: false,
    menuOpened: false,
    buttons: {
      public: [
        { name: "Sign Up", value: "/register/", type: "link" },
        { name: "Sign In", value: "logIn", type: "action", important: true }
      ],
      private: [
        { name: "Entities", value: "/entities/", type: "link" },
        { name: "Account", value: "/account/", type: "link" },
        { name: "Sign Out", value: "logOut", type: "action" }
      ]
    }
  }),

  computed: {
    ...mapGetters({
      userLoggedIn: "user/userLoggedIn",
      challangeId: "website/challangeId"
    }),

    activeButtons() {
      return this.userLoggedIn ? this.buttons.private : this.buttons.public;
    },

    reverseActiveButtons() {
      return this.activeButtons?.map((i) => i).reverse();
    }
  },

  watch: {
    $route() {
      this.menuOpened = false;
    }
  },

  methods: {
    async logIn() {
      if (this.$route.path.includes("/login")) return;
      if (!this.challangeId) {
        this.$auth.$storage.removeUniversal("tmp_login_challenge");
        this.$auth.$storage.removeUniversal("tmp_user_token");
        this.$auth.loginWith("ranos");
      } else {
        this.$router.push("/login/");
      }
    },

    logOut() {
      this.$store.dispatch("user/remoteLogout").then((result) => {
        switch (result.action) {
          case "redirect":
            window.location.href = result.message;
            break;
          default:
            this.$auth.logout("ranos");
            this.errorMessage = result.message;
            break;
        }
      });
    },

    triggerButton(button) {
      switch (button.type) {
        case "link":
          break;
        case "action":
          this[button.value]();
          break;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
#menu
  height: var(--nav-height)
  background-color: rgba(0,0,0,0.1)
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 2vw
  position: fixed
  top: 0
  left: 0
  width: 100vw
  backdrop-filter: blur(10px)
  z-index: 50
  border-bottom: 1px solid rgba(255,255,255, 0.05)

  #logo
    width: 120px
    cursor: pointer

  .custom-layout-logo
    display: flex
    justify-content: center
    align-items: center
    gap: 14px
    img
      max-width: 120px
      max-height: 35px
    .entity-name
      font-size: 16px
      font-weight: 600
      color: rgba(255,255,255, 1)

  .buttons-wrapper
    @media (max-width: 768px)
      display: none
    .buttons
      display: inline-flex
      flex-wrap: wrap
      gap: 12px

  .mobile-menu-button
    display: none
    @media (max-width: 768px)
      display: block

  .mobile-menu-overlay
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0,0,0,0)
    pointer-events: none
    transition: all 0.35s ease-in-out
    z-index: 99
    &.active
      background-color: rgba(0,0,0,0.5)
      pointer-events: all

  .mobile-menu-content
    position: fixed
    top: 0
    right: 0
    width: 300px
    height: 100vh
    background-color: rgba(var(--color-background), 1)
    transform: translateX(350px)
    transition: all 0.35s ease-in-out
    z-index: 100
    box-shadow: 0 0 80px -30px rgba(0,0,0, 0.05)
    opacity: 0
    padding: 16px
    display: flex
    flex-flow: column
    gap: 16px
    &.active
      transform: translateX(0)
      opacity: 1
</style>

<template>
  <div
    v-intersection="show"
    class="show-up"
    :class="classes"
    :style="{ 'transition-delay': `${delay}s` }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    delay: {
      type: Number,
      default: 0
    },
    right: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    once: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    shown: false,
    visible: false
  }),

  computed: {
    ...mapGetters({
      isTablet: "app_store/isTablet",
      isMobile: "app_store/isMobile"
    }),
    classes() {
      if (this.isMobile || this.isTablet) return {};
      return {
        "show-up-right-init": this.right,
        "show-up-left-init": this.left,
        "show-up-top-init": !this.right && !this.left,
        "show-up-right": this.right && this.visible,
        "show-up-left": this.left && this.visible,
        "show-up-top": !this.right && !this.left && this.visible
      };
    }
  },

  methods: {
    show(entry) {
      if (this.once && !this.shown && entry) {
        this.visible = true;
        this.shown = true;
      } else if (this.once && this.shown) {
        return;
      } else {
        this.visible = entry;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.show-up-top-init
  opacity: 0
  transform: translateY(20px)
  transition: transform .2s ease-in-out, opacity .2s ease-in-out
  transition-delay: 0 !important
  @media (max-width: 1024)
    transform: translateY(0px)
    opacity: 1

.show-up-top
    opacity: 1
    transform: translateY(0)

.show-up-left-init
  opacity: 0
  transform: translateX(-60px)
  transition: transform .2s ease-in-out, opacity .2s ease-in-out
  @media (max-width: 1024px)
    transform: translateY(0px)
    opacity: 1

.show-up-left
    opacity: 1
    transform: translateX(0)
    @media (max-width: 1024px)
      transform: translateY(0px)

.show-up-right-init
    opacity: 0
    transform: translateX(60px)
    transition: transform .2s ease-in-out, opacity .2s ease-in-out
    @media (max-width: 1024px)
      transform: translateY(0px)
      opacity: 1

.show-up-right
    opacity: 1
    transform: translateX(0)
    @media (max-width: 1024px)
      transform: translateY(0px)
</style>

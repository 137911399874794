export const parseQuery = (queryString) => {
  const query = {};
  const pairs = queryString.split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

export const encodeQuery = (queryObject) => {
  return Object.entries(queryObject)
    .filter(([key, value]) => typeof value !== "undefined")
    .map(
      ([key, value]) =>
        encodeURIComponent(key) +
        (value != null ? "=" + encodeURIComponent(value) : "")
    )
    .join("&");
};

export const listPageSize = 50;
export const LOGINCOOKIETRIGGERVALUE = "auth.user-relogged-in";
export const MEMBER_AUTH_PROTECTION = "auth.member_protection";
export const MEMBER_LAST_SENT_AUTH_CODE = "auth.member_last_sent_auth_code_";
export const RESEND_CODE_DELAY_LENGTH = 90;

export const REPORT_DEFAULT_DATE_FORMAT = "DD MMMM yyyy (HH:mm z)";

export const CHANGE_STATUS_SCHEDULED = "scheduled";
export const CHANGE_STATUS_PUBLISHED = "Published";
export const makeRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

/*
  Member Authorization
 */
export const MEMBER_AUTHORIZATION_EDIT_PUBLISHED_CHANGE =
  "edit_published_change";

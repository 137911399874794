import { render, staticRenderFns } from "./home.vue?vue&type=template&id=20033022&scoped=true"
import script from "./home.vue?vue&type=script&lang=js"
export * from "./home.vue?vue&type=script&lang=js"
import style0 from "./home.vue?vue&type=style&index=0&id=20033022&prod&lang=sass"
import style1 from "./home.vue?vue&type=style&index=1&id=20033022&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20033022",
  null
  
)

export default component.exports
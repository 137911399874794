/* eslint-disable */
export default ({ $gtm, app, env }) => {
  app.router.afterEach((to, from) => {
    /*
     ** We tell Google Analytics to add a `p ageview`
     */
    let pushObj = {};
    if (to.path === "/download_file/") {
      if (to.query.m_id) {
        //pushObj['reportMemberId'] = to.query.m_id;
      }
    } else if (to.path.includes("/files/")) {
      //pushObj.event = "pageView"; //NOTE move this to the file.vue page
    } else {
      if (app.$auth.loggedIn) {
        // download_file gets these from report id
        /*
                var user_id = app.$auth.user.user_data.user_id
                pushObj['userId'] = user_id;
                */
        var entity_id = app.$cookies.get("auth.selected_entity");
        if (entity_id) {
          pushObj["entityId"] = entity_id;
          const member_id = app.$auth.user.entity_data.find(
            (tmpEntity) => tmpEntity.entity_id === entity_id
          )?.member_id;
          if (member_id) {
            pushObj["memberId"] = member_id;
          }
        }
      }
    }
    if (Object.keys(pushObj).length > 0) {
      if (!pushObj.event) pushObj.event = "pageView";
      $gtm.push(pushObj);
    }
  });
};

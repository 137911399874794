import Vue from "vue";
const clone = require("rfdc")({
  proto: true,
});

export const state = () => ({
  TWO_DP_METRICS: Object.freeze([126, 127, 229, 230, 231, 236, 237]),
  ONE_DP_METRICS: Object.freeze([
    130, 131, 132, 139, 140, 147, 148, 168, 169, 170, 171, 172, 173, 175, 190,
    195, 196, 212, 217, 219, 232, 233, 234, 235, 238, 239, 240, 241, 242, 243,
  ]),
  cdbSheet: [],
  cdbMetrics: [],
  priceMatrixInternalNames: [
    "price",
    "target_price",
    //"market_capitalisation",
    //"enterprise_value",
    "dividend_return",
    "capital_return",
    "total_investment_return",
    //"price_to_revenue",
    //"enterprise_value_to_ebitda",
    //"price_to_earnings",
    //"price_to_earnings_growth",
    //"price_to_book",
    //"price_to_free_cashflow",
    //"pre-tax_dividend_yield",
    //"post-tax_dividend_yield",
  ],
});
export const getters = {
  cdbMetricsNameDictionary: (state) => {
    let returnDict = {};
    for (let i = 0; i < state.cdbMetrics.length; i++) {
      returnDict[state.cdbMetrics[i].metrics_int_name] = state.cdbMetrics[i];
    }
    return returnDict;
  },
  computeFinancialMatrix: (state, getters, rootState, rootGetters) => {
    return (secObj) => {
      if (!secObj) {
        console.error("no security found");
        return {};
      }
      let newCDBData = rootGetters["app_store/objectNestedPropertyCheck"](
        secObj,
        ["change_security_new_cdb", "model_sheet_metadata"],
        null
      );
      if (!newCDBData) return secObj;
      const priceToUse =
        secObj.change_security_publication_price ||
        secObj.change_security_current_price;
      if (!priceToUse && priceToUse !== "-" && priceToUse > -1) return secObj;
      let returnObj = clone(secObj);
      newCDBData = rootGetters["app_store/objectNestedPropertyCheck"](
        returnObj,
        ["change_security_new_cdb", "model_sheet_metadata"],
        null
      );
      //NOTE order does matter
      let metricIds = new Map();
      for (const internalName of state.priceMatrixInternalNames) {
        const metricObj = getters.cdbMetricsNameDictionary[internalName];
        if (!metricObj) {
          console.error(internalName);
          continue;
        }
        metricIds.set(metricObj.metrics_id, internalName);
      }
      //NOTE value needed for calculations that is not in the priceMatricsNames
      let valueRequired = {
        translational_exchange_rate: {
          value: {
            t: 1,
          },
        },
        /*'shares_on_issue': {
                    value: {
                        t: 0,
                        'unit': ''
                    }
                },
                'cash_and_equivalents': {
                    value: {
                        't+1': 0
                    }
                },
                'debt_and_equivalents': {
                    value: {
                        "t+1": 0
                    }
                },*/
        "declared_post-tax_dividend_per_share": {
          value: {
            "t+1": 0,
          },
        },
        /*
                'operating_revenues_generated': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'operating_ebitda_generated': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'adjusted_net_profit_generated_after_tax': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'eps_normalised': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'eps_growth': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'shareholders_equity': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'free_cashflow_received': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'free_cashflow_received': {
                    value: {
                        't+1': 0,
                        't+2': 0,
                        't+3': 0,
                        't-1': 0
                    }
                },
                'corporate_tax_rate': {
                    value: {
                        't': 0
                    }
                },
                */
      };
      let valueMetricIds = new Map();
      for (const internalName in valueRequired) {
        const metricObj = getters.cdbMetricsNameDictionary[internalName];
        if (!metricObj) {
          console.error(internalName);
          continue;
        }
        valueMetricIds.set(metricObj.metrics_id, internalName);
      }
      //const valueMetricIds = getters.getFinancialMatrixIdMap;
      // loop 1 time to get all metrics that needs to be change and all the value that is required
      const loopLen = newCDBData.length;
      const breakLen = metricIds.size;
      const valueBreakLen = valueMetricIds.size;
      let gotValueC = 0;
      let indexToChange = [];
      //all calc stuff that is required by other functions
      let mrktCapObj = {};
      let capitalRObj = {};
      let dividendRObj = {};
      let enterpriseValObj = {};
      for (let i = 0; i < loopLen; i++) {
        const metricsData = newCDBData[i];
        const metricIdToCheck = metricsData.metrics_id;
        const changeInternalName = metricIds.get(metricIdToCheck);
        if (changeInternalName) {
          indexToChange.push({
            changeIndex: i,
            changeInternalName,
            calculate: !(
              metricsData.manual_update !== undefined &&
              metricsData.manual_update
            ),
          });
          if (changeInternalName === "market_capitalisation")
            mrktCapObj = {
              changeIndex: i,
              changeInternalName,
            };
          if (changeInternalName === "capital_return")
            capitalRObj = {
              changeIndex: i,
              changeInternalName,
            };
          if (changeInternalName === "dividend_return")
            dividendRObj = {
              changeIndex: i,
              changeInternalName,
            };
          if (changeInternalName === "enterprise_value")
            enterpriseValObj = {
              changeIndex: i,
              changeInternalName,
            };
        }
        const valueInternalName = valueMetricIds.get(metricIdToCheck);
        if (valueInternalName) {
          if (metricsData.op_sheet_row_values) {
            valueRequired[valueInternalName] = metricsData.op_sheet_row_values;
          }
          gotValueC++;
        }
        if (gotValueC >= valueBreakLen && indexToChange.length >= breakLen)
          break; // both value got and index got
      }
      const timeToCalc = ["t-1", "t+1", "t+2", "t+3"];
      // actually changing the value here
      for (const {
        changeIndex,
        changeInternalName,
        calculate,
      } of indexToChange) {
        // user input value dont need to run calc
        if (!calculate) continue;
        let newResults = {};
        switch (changeInternalName) {
          case "market_capitalisation":
            newResults = getters.computeMarketCap({
              sharesOnIssueUnit: valueRequired["shares_on_issue"]["unit"],
              sharesOnIssue: valueRequired["shares_on_issue"]["t"],
              exchangeRate: valueRequired["translational_exchange_rate"]["t"],
              price: priceToUse,
            });
            break;
          case "enterprise_value":
            newResults = getters.computeEnterpriseVal({
              marketCap:
                newCDBData[mrktCapObj.changeIndex]["op_sheet_row_values"]["t"],
              cashAndEquivalents: valueRequired["cash_and_equivalents"]["t+1"],
              debtAndEquivalents: valueRequired["debt_and_equivalents"]["t+1"],
            });
            break;
          case "capital_return":
            newResults = getters.computeCapitalReturn({
              price: priceToUse,
              targetPrice: secObj.change_security_targetprice || 0,
            });
            break;
          case "dividend_return":
            newResults = getters.computeDividendReturn({
              exchangeRate: valueRequired["translational_exchange_rate"]["t"],
              declaredPostTaxDividendPerShare:
                valueRequired["declared_post-tax_dividend_per_share"]["t+1"],
              price: priceToUse,
            });
            break;
          case "total_investment_return":
            newResults = getters.computeTotalInvestmentReturn({
              capitalReturn:
                newCDBData[capitalRObj.changeIndex]["op_sheet_row_values"]["t"],
              dividendReturn:
                newCDBData[dividendRObj.changeIndex]["op_sheet_row_values"][
                  "t"
                ],
            });
            break;
          case "price_to_revenue":
            newResults = getters.computePriceRevenue({
              marketCap:
                newCDBData[mrktCapObj.changeIndex]["op_sheet_row_values"]["t"],
              operatingRevenueGenerated:
                valueRequired["operating_revenues_generated"],
              timeToCalc,
            });
            break;
          case "enterprise_value_to_ebitda":
            newResults = getters.computeEnterpriseValToEbitda({
              timeToCalc,
              enterpriseValue:
                newCDBData[enterpriseValObj.changeIndex]["op_sheet_row_values"][
                  "t"
                ],
              operatingEbitdaGenerated:
                valueRequired["operating_ebitda_generated"],
            });
            break;
          case "price_to_earnings":
            newResults = getters.computePriceToEarnings({
              timeToCalc,
              marketCap:
                newCDBData[mrktCapObj.changeIndex]["op_sheet_row_values"]["t"],
              adjustedNetProfitAfterTax:
                valueRequired["adjusted_net_profit_generated_after_tax"],
              epsNormalised: valueRequired["eps_normalised"],
              price: priceToUse,
            });
            break;
          case "price_to_earnings_growth":
            newResults = getters.computePriceToEarningsGrowth({
              timeToCalc,
              marketCap:
                newCDBData[mrktCapObj.changeIndex]["op_sheet_row_values"]["t"],
              adjustedNetProfitAfterTax:
                valueRequired["adjusted_net_profit_generated_after_tax"],
              epsGrowth: valueRequired["eps_growth"],
            });
            break;
          case "price_to_book":
            newResults = getters.computePriceToBook({
              timeToCalc,
              marketCap:
                newCDBData[mrktCapObj.changeIndex]["op_sheet_row_values"]["t"],
              shareholdersEquity: valueRequired["shareholders_equity"],
            });
            break;
          case "price_to_free_cashflow":
            newResults = getters.computePriceToFreeCashflow({
              timeToCalc,
              marketCap:
                newCDBData[mrktCapObj.changeIndex]["op_sheet_row_values"]["t"],
              freeCashflowRec: valueRequired["free_cashflow_received"],
            });
            break;
          case "pre-tax_dividend_yield":
            newResults = getters.computePreTaxDividendYield({
              timeToCalc,
              declaredPostTaxDividendPerShare:
                valueRequired["declared_post-tax_dividend_per_share"],
              corporateTaxRate: valueRequired["corporate_tax_rate"]["t"],
              exchangeRate: valueRequired["translational_exchange_rate"]["t"],
              price: priceToUse,
            });
            break;
          case "post-tax_dividend_yield":
            newResults = getters.computePostTaxDividendYield({
              timeToCalc,
              exchangeRate: valueRequired["translational_exchange_rate"]["t"],
              declaredPostTaxDividendPerShare:
                valueRequired["declared_post-tax_dividend_per_share"],
              price: priceToUse,
            });
            break;
          case "price":
            newResults = {
              t: priceToUse.toFixed(2),
            };
            break;
          case "target_price":
            newResults = {
              t: secObj.change_security_targetprice
                ? secObj.change_security_targetprice.toFixed(2)
                : "0.00",
            };
            break;
        }
        for (const tmpT in newResults) {
          if (
            rootGetters["app_store/objectNestedPropertyCheck"](
              newCDBData[changeIndex],
              ["op_sheet_row_values", tmpT],
              false
            )
          ) {
            newCDBData[changeIndex].op_sheet_row_values[tmpT] =
              newResults[tmpT];
          }
        }
      }
      return returnObj;
    };
  },
  computeMarketCap(state, getters, rootState, rootGetters) {
    return ({ sharesOnIssueUnit, sharesOnIssue, exchangeRate, price }) => {
      try {
        const sharesOnIssueUnitToCheck = sharesOnIssueUnit.toLowerCase();
        const sharesOnIssueToCheck =
          typeof sharesOnIssue === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                sharesOnIssue
              )
            : sharesOnIssue;
        const exchangeRateToCheck =
          typeof exchangeRate === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                exchangeRate
              )
            : exchangeRate;
        let returnValue = {
          t: 0.0,
        };
        if (sharesOnIssueUnitToCheck.indexOf("m") > -1) {
          returnValue.t = (sharesOnIssueToCheck * price).toLocaleString(
            undefined,
            { minimumFractionDigits: 1, maximumFractionDigits: 1 }
          );
        } else if (sharesOnIssueUnitToCheck.indexOf("b") > -1) {
          returnValue.t = (sharesOnIssueToCheck * price * 1000).toLocaleString(
            undefined,
            { minimumFractionDigits: 1, maximumFractionDigits: 1 }
          );
        } else {
          returnValue.t = (
            (sharesOnIssueToCheck * price) /
            1000000
          ).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          });
        }
        return returnValue;
      } catch (e) {
        console.error(e);
        return {
          t: "-",
        };
      }
    };
  },
  computeEnterpriseVal(state, getters, rootState, rootGetters) {
    return ({ marketCap, cashAndEquivalents, debtAndEquivalents }) => {
      try {
        const cashAndEquivalentsToCheck =
          typeof cashAndEquivalents === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                cashAndEquivalents
              )
            : cashAndEquivalents;
        const debtAndEquivalentsCheck =
          typeof debtAndEquivalents === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                debtAndEquivalents
              )
            : debtAndEquivalents;
        const marketCapToCheck =
          typeof marketCap === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                marketCap
              )
            : marketCap;
        return {
          t: (
            marketCapToCheck -
            cashAndEquivalentsToCheck +
            debtAndEquivalentsCheck
          ).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }),
        };
      } catch (e) {
        console.error(e);
        return "-";
      }
    };
  },
  computeCapitalReturn(state, getters, rootState, rootGetters) {
    return ({ price, targetPrice }) => {
      if (targetPrice === "-") {
        return {
          t: "-",
        };
      }
      try {
        const priceToCheck =
          typeof price === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](price)
            : price;
        const targetPriceToCheck =
          typeof targetPrice === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                targetPrice
              )
            : targetPrice;
        return {
          t: ((targetPriceToCheck / priceToCheck - 1) * 100).toFixed(1) + "%",
        };
      } catch (e) {
        console.error(e);
        return {
          t: "0.0%",
        };
      }
    };
  },
  computeDividendReturn(state, getters, rootState, rootGetters) {
    return ({ exchangeRate, declaredPostTaxDividendPerShare, price }) => {
      try {
        const priceToCheck =
          typeof price === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](price)
            : price;
        let exchangeRateToCheck =
          typeof exchangeRate === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                exchangeRate
              )
            : exchangeRate;
        if (exchangeRateToCheck <= 0 || exchangeRateToCheck === undefined)
          exchangeRateToCheck = 1;
        let ddptdpsToCheck =
          typeof declaredPostTaxDividendPerShare === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                declaredPostTaxDividendPerShare
              )
            : declaredPostTaxDividendPerShare;
        if (ddptdpsToCheck === undefined) ddptdpsToCheck = 0;
        return {
          t:
            (
              (ddptdpsToCheck / 100 / priceToCheck) *
              exchangeRateToCheck *
              100
            ).toFixed(1) + "%",
        };
      } catch (e) {
        console.error(e);
        return {
          t: "0.0%",
        };
      }
    };
  },
  computeTotalInvestmentReturn(state, getters, rootState, rootGetters) {
    return ({ capitalReturn, dividendReturn }) => {
      try {
        const capitalToCheck =
          typeof capitalReturn === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                capitalReturn
              )
            : capitalReturn;
        const dividendReturnCheck =
          typeof dividendReturn === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                dividendReturn
              )
            : dividendReturn;
        return {
          t: (capitalToCheck + dividendReturnCheck).toFixed(1) + "%",
        };
      } catch (e) {
        console.error(e);
        return {
          t: "0.0%",
        };
      }
    };
  },
  computePriceRevenue(state, getters, rootState, rootGetters) {
    return ({ marketCap, operatingRevenueGenerated, timeToCalc }) => {
      try {
        const marketCapToCheck =
          typeof marketCap === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                marketCap
              )
            : marketCap;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          if (!operatingRevenueGenerated[timeToUse]) continue;
          const orgToCheck =
            typeof operatingRevenueGenerated[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  operatingRevenueGenerated[timeToUse]
                )
              : operatingRevenueGenerated[timeToUse];
          const returnVal = marketCapToCheck / orgToCheck;
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal > 99.9) {
            returnObj[timeToUse] = ">99.9";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1);
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  computeEnterpriseValToEbitda(state, getters, rootState, rootGetters) {
    return ({ enterpriseValue, operatingEbitdaGenerated, timeToCalc }) => {
      try {
        const enterpriseValToCheck =
          typeof enterpriseValue === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                enterpriseValue
              )
            : enterpriseValue;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          if (!operatingEbitdaGenerated[timeToUse]) continue;
          const oegToCheck =
            typeof operatingEbitdaGenerated[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  operatingEbitdaGenerated[timeToUse]
                )
              : operatingEbitdaGenerated[timeToUse];
          const returnVal = enterpriseValToCheck / oegToCheck;
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal > 99.9) {
            returnObj[timeToUse] = ">99.9";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1);
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  computePriceToEarnings(state, getters, rootState, rootGetters) {
    return ({
      marketCap,
      adjustedNetProfitAfterTax,
      epsNormalised,
      price,
      timeToCalc,
    }) => {
      try {
        const marketCapToCheck =
          typeof marketCap === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                marketCap
              )
            : marketCap;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          let returnVal;
          if (!adjustedNetProfitAfterTax[timeToUse]) continue;
          const anpatToCheck =
            typeof adjustedNetProfitAfterTax[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  adjustedNetProfitAfterTax[timeToUse]
                )
              : adjustedNetProfitAfterTax[timeToUse];
          if (marketCapToCheck && marketCapToCheck > 0) {
            returnVal = marketCapToCheck / anpatToCheck;
          } else {
            const peToChec =
              typeof epsNormalised[timeToUse] === "string"
                ? rootGetters["app_store/getDecimalNumberValueFromString"](
                    epsNormalised[timeToUse]
                  )
                : epsNormalised[timeToUse];
            returnVal = (price * 100) / peToChec;
          }
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal > 99.9) {
            returnObj[timeToUse] = ">99.9";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1);
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  computePriceToEarningsGrowth(state, getters, rootState, rootGetters) {
    return ({
      marketCap,
      adjustedNetProfitAfterTax,
      epsGrowth,
      timeToCalc,
    }) => {
      try {
        const marketCapToCheck =
          typeof marketCap === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                marketCap
              )
            : marketCap;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          let returnVal;
          if (!adjustedNetProfitAfterTax[timeToUse]) continue;
          const anpatToCheck =
            typeof adjustedNetProfitAfterTax[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  adjustedNetProfitAfterTax[timeToUse]
                )
              : adjustedNetProfitAfterTax[timeToUse];
          const epsGrowthToCheck =
            typeof epsGrowth[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  epsGrowth[timeToUse]
                )
              : epsGrowth[timeToUse];
          returnVal = marketCapToCheck / anpatToCheck / epsGrowthToCheck;
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal >= 10) {
            returnObj[timeToUse] = ">10.0";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1);
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  computePriceToBook(state, getters, rootState, rootGetters) {
    return ({ marketCap, shareholdersEquity, timeToCalc }) => {
      try {
        const marketCapToCheck =
          typeof marketCap === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                marketCap
              )
            : marketCap;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          if (!shareholdersEquity[timeToUse]) continue;
          const sheToCheck =
            typeof shareholdersEquity[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  shareholdersEquity[timeToUse]
                )
              : shareholdersEquity[timeToUse];
          const returnVal = marketCapToCheck / sheToCheck;
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal >= 10) {
            returnObj[timeToUse] = ">10.0";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1);
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  computePriceToFreeCashflow(state, getters, rootState, rootGetters) {
    return ({ timeToCalc, marketCap, freeCashflowRec }) => {
      try {
        const marketCapToCheck =
          typeof marketCap === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                marketCap
              )
            : marketCap;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          if (!freeCashflowRec[timeToUse]) continue;
          const fcrToCheck =
            typeof freeCashflowRec[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  freeCashflowRec[timeToUse]
                )
              : freeCashflowRec[timeToUse];
          const returnVal = marketCapToCheck / fcrToCheck;
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal > 99.9) {
            returnObj[timeToUse] = ">99.9";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1);
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  computePreTaxDividendYield(state, getters, rootState, rootGetters) {
    return ({
      timeToCalc,
      declaredPostTaxDividendPerShare,
      corporateTaxRate,
      exchangeRate,
      price,
    }) => {
      try {
        const ctrToCheck =
          typeof corporateTaxRate === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                corporateTaxRate
              )
            : corporateTaxRate;
        const exchangeRateToCheck =
          typeof exchangeRate === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                exchangeRate
              )
            : exchangeRate;
        const priceToCheck =
          typeof price === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](price)
            : price;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          if (!declaredPostTaxDividendPerShare[timeToUse]) continue;
          const dptdpsToCheck =
            typeof declaredPostTaxDividendPerShare[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  declaredPostTaxDividendPerShare[timeToUse]
                )
              : declaredPostTaxDividendPerShare[timeToUse];
          //const returnVal = (dptdpsToCheck / (1 - ctrToCheck) / 100) / priceToCheck * exchangeRate;
          const returnVal =
            (dptdpsToCheck / (1 - ctrToCheck / 100) / priceToCheck) *
            exchangeRateToCheck;
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal >= 50) {
            returnObj[timeToUse] = ">50.0%";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1) + "%";
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  computePostTaxDividendYield(state, getters, rootState, rootGetters) {
    return ({
      timeToCalc,
      price,
      declaredPostTaxDividendPerShare,
      exchangeRate,
    }) => {
      try {
        const exchangeRateToCheck =
          typeof exchangeRate === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](
                exchangeRate
              )
            : exchangeRate;
        const priceToCheck =
          typeof price === "string"
            ? rootGetters["app_store/getDecimalNumberValueFromString"](price)
            : price;
        let returnObj = {};
        for (const timeToUse of timeToCalc) {
          if (!declaredPostTaxDividendPerShare[timeToUse]) continue;
          const dptdpsToCheck =
            typeof declaredPostTaxDividendPerShare[timeToUse] === "string"
              ? rootGetters["app_store/getDecimalNumberValueFromString"](
                  declaredPostTaxDividendPerShare[timeToUse]
                )
              : declaredPostTaxDividendPerShare[timeToUse];
          const returnVal =
            (dptdpsToCheck / priceToCheck) * exchangeRateToCheck;
          if (returnVal <= 0) {
            returnObj[timeToUse] = "-";
          } else if (returnVal >= 50) {
            returnObj[timeToUse] = ">50.0%";
          } else {
            returnObj[timeToUse] = returnVal.toFixed(1) + "%";
          }
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {
          "t-1": "-",
          "t+1": "-",
          "t+2": "-",
          "t+3": "-",
        };
      }
    };
  },
  returnComputedFinancialMatrix(state, getters, rootState, rootGetters) {
    return (newCDBData, matchName) => {
      /*
            let valueMetricIds = new Map();
            for(const internalName of state.priceMatrixInternalNames) {
                const metricObj = getters.cdbMetricsNameDictionary[internalName];
                if (!metricObj) {
                    console.error(internalName);
                    continue;
                }
                valueMetricIds.set(metricObj.metrics_id, internalName);
            }
            */
      const valueMetricIds = getters.getFinancialMatrixIdMap;
      const loopLen = newCDBData.length;
      const breakLen = valueMetricIds.size;
      let returnList = [];
      for (let i = 0; i < loopLen; i++) {
        const metricsData = newCDBData[i];
        const metricIdToCheck = metricsData.metrics_id;
        const valueInternalName = valueMetricIds.get(metricIdToCheck);
        if (valueInternalName) {
          if (matchName) {
            returnList.push({
              ...metricsData,
              internal_name: valueInternalName,
            });
          } else {
            returnList.push(metricsData);
          }
        }
        if (returnList.length >= breakLen) break;
      }
      return returnList;
    };
  },
  getFinancialMatrixIdMap(state, getters) {
    let valueMetricIds = new Map();
    for (const internalName of state.priceMatrixInternalNames) {
      const metricObj = getters.cdbMetricsNameDictionary[internalName];
      if (!metricObj) {
        console.error(internalName);
        continue;
      }
      valueMetricIds.set(metricObj.metrics_id, internalName);
    }
    return valueMetricIds;
  },
};
export const mutations = {
  //Member list update after getting members from backend
  updateCDBSheet(state, arrayList) {
    Vue.set(state, "cdbSheet", arrayList);
  },
  updateCDBMetrics(state, arrayList) {
    Vue.set(state, "cdbMetrics", arrayList);
  },
};

export const actions = {
  getCDBSheet({ commit, rootState }, { security_id, ...params }) {
    return this.$axios
      .$get(
        `/v1/cdb_op_sheet/entity/${rootState.user.selectedShownEntity}/security/${security_id}`,
        {
          params: params,
        }
      )
      .then((r) => {
        commit("updateCDBSheet", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetCDBMetrics({ commit }) {
    return this.$axios
      .$get(`/v1/cdb/metrics/`)
      .then((r) => {
        commit("updateCDBMetrics", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUpdateDraftCdbMetrics(
    { commit, rootState, rootGetters },
    { metrics_list, model_sheet_id, change_security_id, update_value = false }
  ) {
    return this.$axios
      .$put(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/modelsheet/${model_sheet_id}/update_by_metrics`,
        { metrics_list }
      )
      .then((r) => {
        if (update_value && change_security_id) {
          // user input values commit to change store
          const tabIndex = rootState.change.linkedSecurities.findIndex(
            (tmpSec) => tmpSec.change_security_id === change_security_id
          );
          if (tabIndex < 0) return true;
          let tmpSecObj = clone(rootState.change.linkedSecurities[tabIndex]);
          let newCDBData = rootGetters["app_store/objectNestedPropertyCheck"](
            tmpSecObj,
            ["change_security_new_cdb", "model_sheet_metadata"],
            null
          );
          const loopLen = newCDBData.length;
          const breakLen = metrics_list.length;
          let updateC = 0;
          for (let i = 0; i < loopLen; i++) {
            let metricsData = newCDBData[i];
            const metricIdToCheck = metricsData.metrics_id;
            const replaceObj = metrics_list.find(
              (tmpObj) => tmpObj.metrics_id === metricIdToCheck
            );
            if (replaceObj) {
              // although its by reference but use long way for clarity
              tmpSecObj.change_security_new_cdb.model_sheet_metadata[i] =
                replaceObj;
              updateC++;
            }
            if (updateC >= breakLen) break;
          }
          if (updateC > 0) {
            commit(
              "change/updateLinkedSecurityDetail",
              {
                index: tabIndex,
                newDetails: tmpSecObj,
              },
              {
                root: true,
              }
            );
          }
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  async remoteUpdateComputedFinancialMtatrixForReport({
    dispatch,
    getters,
    rootGetters,
  }) {
    let promiseArray = [];
    for (const tmpSecObj of rootGetters["change/orderedLinkedSecurities"]) {
      const modelSheetObjs = rootGetters["app_store/objectNestedPropertyCheck"](
        tmpSecObj,
        ["model", "model_sheets"],
        false
      );
      if (!modelSheetObjs) continue;
      const cdbSheetObj = modelSheetObjs.find(
        (tmpObj) =>
          tmpObj.model_sheet_cdb && tmpObj.model_sheet_cdb !== undefined
      );
      if (!cdbSheetObj || !cdbSheetObj.model_sheet_id) continue;
      const newCDBData = rootGetters["app_store/objectNestedPropertyCheck"](
        tmpSecObj,
        ["change_security_new_cdb", "model_sheet_metadata"],
        null
      );
      if (!newCDBData) continue;
      const metrics_list = getters.returnComputedFinancialMatrix(newCDBData);
      if (metrics_list.length > 0) {
        promiseArray.push(
          dispatch("remoteUpdateDraftCdbMetrics", {
            metrics_list,
            model_sheet_id: cdbSheetObj.model_sheet_id,
          })
        );
      }
    }
    return promiseArray.length > 0
      ? Promise.all(promiseArray)
      : Promise.resolve(true);
  },
};

<template>
  <b-button
    pill
    :id="btnId"
    class="button-a"
    :class="disabled ? 'disabled' : ''"
    :disabled="disabled"
    @click="okClicked"
    size="sm"
  >
    <p v-if="!isSuccess && !isLoading">{{ label }}</p>
    <transition name="bounce">
      <b-img v-if="isSuccess" src="~/assets/images/ic_ticker.svg"></b-img>
    </transition>
    <b-spinner
      v-if="!isSuccess && isLoading"
      class="spinner"
      small
      label="Loading..."
    ></b-spinner>
  </b-button>
</template>

<script>
export default {
  props: ["label", "btnId", "disabled"],
  data() {
    return {
      isSuccess: false,
      isLoading: false,
    };
  },
  methods: {
    showSuccess() {
      this.isLoading = false;
      this.isSuccess = true;
      setTimeout(() => (this.isSuccess = false), 1000);
    },
    toggleLoading(trueOrFalse) {
      this.isLoading = trueOrFalse;
    },
    okClicked() {
      if (!this.disabled && !this.isLoading) {
        this.$emit("ok-click");
      } else return;
    },
  },
};
</script>

<style scoped>
.button-a {
  background-color: rgb(100, 125, 150);
  border-color: rgb(100, 125, 150);
  position: relative;
  min-width: 60px;
  height: 23px;
}
.button-a p {
  font-size: 1em;
  color: white;
  left: 50%;
  white-space: nowrap;
  /* top: 45%;*/
  transform: translate(-50%, -50%);
  position: absolute;
}
.button-a img {
  width: 1.5em;
  height: 1.2em;
  left: 50%;
  /* top: 45%;*/
  transform: translate(-50%, -50%);
  position: absolute;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>

import Vue from "vue";
import qs from "qs";
export const state = () => ({
  loadedFeedList: [],
  filterPublicationOptions: {},
  securityList: [],
  offset: 0
});
export const getters = {
  getSelectedSecurities: (state, getters, rootState) => {
    if (state.filterPublicationOptions["security_ids"]) {
      if (
        state.filterPublicationOptions["security_ids"].length === 0 &&
        state.filterPublicationOptions["security_ids_type"] === "all"
      ) {
        return state.securityList || [];
      } else {
        return getters.getSecurityFilterOptions(
          state.filterPublicationOptions["security_ids"],
          state.securityList
        );
      }
    } else {
      return state.securityList || [];
    }
  },
  getSelectedSecurityAnalysts: (state, getters, rootState) => {
    if (state.filterPublicationOptions["analyst_ids"]) {
      if (
        state.filterPublicationOptions["analyst_ids"].length === 0 &&
        state.filterPublicationOptions["analyst_ids_type"] === "all"
      ) {
        return rootState.member.securityAnalystsList || [];
      } else {
        return getters.getFilterOptions(
          state.filterPublicationOptions["analyst_ids"],
          rootState.member.securityAnalystsList
        );
      }
    } else {
      return rootState.member.securityAnalystsList || [];
    }
  },
  getSelectedReasons: (state, getters, rootState, rootGetters) => {
    if (state.filterPublicationOptions["reason_ids"]) {
      if (
        state.filterPublicationOptions["reason_ids"].length === 0 &&
        state.filterPublicationOptions["reason_ids_type"] === "all"
      ) {
        return rootGetters["change/getBootstrapReasonOptions"](
          rootState.change.reasonList || []
        );
      } else {
        return getters.getFilterOptions(
          state.filterPublicationOptions["reason_ids"],
          rootGetters["change/getBootstrapReasonOptions"](
            rootState.change.reasonList
          )
        );
      }
    } else {
      return rootGetters["change/getBootstrapReasonOptions"](
        rootState.change.reasonList || []
      );
    }
  },
  getSecurityFilterOptions: () => {
    return (idArray, objArray) => {
      if (!idArray || !objArray) return [];
      let returnArr = [];
      objArray.map((obj) => {
        if (obj.value.permission && idArray.includes(obj.value.id))
          returnArr.push(obj);
      });
      return returnArr;
    };
  },
  getFilterOptions: () => {
    return (idArray, objArray) => {
      if (!idArray || !objArray) return [];
      let returnArr = [];
      objArray.map((obj) => {
        if (idArray.includes(obj.value.id)) returnArr.push(obj);
      });
      return returnArr;
    };
  }
};
export const mutations = {
  updateLoadedFeedList(state, list) {
    Vue.set(state, "loadedFeedList", list);
  },
  updateFilterPublicationOptions(state, option) {
    Vue.set(state, "filterPublicationOptions", option);
  },
  updateSecurityList(state, option) {
    Vue.set(state, "securityList", option);
  },
  updateOffset(state, offset) {
    Vue.set(state, "offset", offset);
  }
};
export const actions = {
  remoteGetSecuritiesList({ commit, rootState, getters, rootGetters }) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/filter`,
        {
          params: {
            per_page: 0,
            limited_fields: true,
            member_flag: true
          }
        }
      )
      .then((r) => {
        // let newList;
        // if (rootGetters["user/isViewer"]) {
        //   newList = getters.getBootstrapSelectOptions(
        //     r.data.filter((it) => it.security_status.toLowerCase() === "active")
        //   );
        // } else {
        //   newList = getters.getBootstrapSelectOptions(r.data);
        // }
        let newList = rootGetters["security/getBootstrapSelectOptions"](r.data);
        // commit("updateSecurityFilteredList", newList);
        //NOTE: use the same variable as the security page
        commit(
          "updateSecurityList",
          newList.filter((obj) => {
            if (obj.value.permission) return true;
          })
        );
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  async remoteLoadPublicationList(
    { commit, state, rootState, rootGetters },
    isEraseLoadedFeedList
  ) {
    const perPage = state.offset === 0 ? 20 : 10;
    const params = {
      search_limit: perPage,
      search_offset: isEraseLoadedFeedList ? 0 : state.offset,
      use_member_publish_security_filter: true,
      publish_type: "all"
    };
    await commit("updateOffset", state.offset + perPage);
    try {
      let r = await this.$axios.$post(
        `/v2/report/filter/${rootState.user.selectedShownEntity}/${rootGetters["user/currentEntity"].member_id}`,
        params
      );
      if (r.result && r.message === "done") {
        r.report_data.forEach((data) => {
          data.report_published_at = rootGetters["app_store/getUtcToTzTime"](
            data.report_published_at,
            "DD MMMM YYYY",
            rootState.app_store.API_DATE_FORMAT
          );
        });
        if (isEraseLoadedFeedList) {
          await commit("updateLoadedFeedList", r.report_data);
        } else {
          if (state.offset !== r.search_offset) {
            r.search_offset = state.offset;
          }
          await commit("updateOffset", r.search_offset);
          await commit("updateLoadedFeedList", [
            ...state.loadedFeedList,
            ...r.report_data
          ]);
        }
        return {
          action: "accept",
          message: r
        };
      }
    } catch (e) {
      console.error(e);
      return {
        action: "error"
      };
    }
  },
  remoteRemoveLoadedFeedListDetails({ commit }) {
    commit("updateLoadedFeedList", []);
    commit("updateOffset", 0);
  },
  remoteGetSavedFilters({ rootState, commit, rootGetters }) {
    // NOTE api in member namespace but is for all 3 pages
    return this.$axios
      .$get(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/published_list`
      )
      .then((r) => {
        if (r.member_filter_list_options_params) {
          let commitObj = {};
          const security_ids = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "security_ids"], null);
          if (security_ids) {
            commitObj["security_ids"] = security_ids;
          }
          const security_ids_type = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](
            r,
            ["member_filter_list_options_params", "security_ids_type"],
            null
          );
          if (security_ids_type) {
            commitObj["security_ids_type"] = security_ids_type;
          }

          const analyst_ids_type = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "analyst_ids_type"], null);
          if (analyst_ids_type) {
            commitObj["analyst_ids_type"] = analyst_ids_type;
          }

          const reason_ids_type = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "reason_ids_type"], null);
          if (reason_ids_type) {
            commitObj["reason_ids_type"] = reason_ids_type;
          }
          const reason_ids = rootGetters["app_store/objectNestedPropertyCheck"](
            r,
            ["member_filter_list_options_params", "reason_ids"],
            null
          );
          if (reason_ids) {
            commitObj["reason_ids"] = reason_ids;
          }
          const analyst_ids = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "analyst_ids"], null);
          if (analyst_ids) {
            commitObj["analyst_ids"] = analyst_ids;
          }
          commit("updateFilterPublicationOptions", commitObj);
        }
      })
      .catch((e) => {
        console.error(e);
        // throw e;
      });
  },
  remoteUpdateSelectedPublicationFilters(
    { commit, state, rootState, rootGetters },
    { security_ids, reason_ids, analyst_ids }
  ) {
    let updateObj = Object.assign({}, state.filterPublicationOptions);
    if (security_ids) {
      updateObj["security_ids"] = security_ids;
      if (security_ids.length === state.securityList.length) {
        updateObj["security_ids_type"] = "all";
        updateObj["security_ids"] = [];
      } else {
        updateObj["security_ids_type"] = "none";
        updateObj["security_ids"] = security_ids;
      }
    }
    // if (reason_ids) {
    //   updateObj["reason_ids"] = reason_ids;
    //   if (
    //     reason_ids.length ===
    //     rootGetters["change/getBootstrapReasonOptions"](
    //       rootState.change.reasonList || []
    //     ).length
    //   ) {
    //     updateObj["reason_ids_type"] = "all";
    //     updateObj["reason_ids"] = [];
    //   } else {
    //     updateObj["reason_ids_type"] = "none";
    //     updateObj["reason_ids"] = reason_ids;
    //   }
    // }
    // if (analyst_ids) {
    //   updateObj["analyst_ids"] = analyst_ids;
    //   if (analyst_ids.length === rootState.member.securityAnalystsList.length) {
    //     updateObj["analyst_ids_type"] = "all";
    //     updateObj["analyst_ids"] = [];
    //   } else {
    //     updateObj["analyst_ids_type"] = "none";
    //     updateObj["analyst_ids"] = analyst_ids;
    //   }
    // }

    // const thresholdC = rootState.security.securityList.length / 2;
    // let allOrNone = "all";
    // let ArrayList = newArray.map((obj) => obj.value.id);
    // if (newArray.length > thresholdC) {
    //   let newArrayList = [];
    //   for (let i = 0; i < state.changeList.length; i++) {
    //     if (!ArrayList.includes(state.changeList[i].value.id)) {
    //       newArrayList.push(state.changeList[i].value.id);
    //     }
    //   }
    //   ArrayList = newArrayList;
    // } else {
    //   allOrNone = "none";
    // }

    updateObj["reason_ids_type"] = "all";
    updateObj["reason_ids"] = [];
    updateObj["analyst_ids_type"] = "all";
    updateObj["analyst_ids"] = [];
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/published_list`,
        {
          member_filter_list_options_params: updateObj
        }
      )
      .then((r) => {
        if (r.member_filter_list_options_params) {
          let commitObj = {};
          const security_ids = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "security_ids"], null);
          if (security_ids) {
            commitObj["security_ids"] = security_ids;
          }
          const security_ids_type = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](
            r,
            ["member_filter_list_options_params", "security_ids_type"],
            null
          );
          if (security_ids_type) {
            commitObj["security_ids_type"] = security_ids_type;
          }
          const reason_ids = rootGetters["app_store/objectNestedPropertyCheck"](
            r,
            ["member_filter_list_options_params", "reason_ids"],
            null
          );
          if (reason_ids) {
            commitObj["reason_ids"] = reason_ids;
          }
          const analyst_ids = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "analyst_ids"], null);
          if (analyst_ids) {
            commitObj["analyst_ids"] = analyst_ids;
          }
          const analyst_ids_type = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "analyst_ids_type"], null);
          if (analyst_ids_type) {
            commitObj["analyst_ids_type"] = analyst_ids_type;
          }

          const reason_ids_type = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](r, ["member_filter_list_options_params", "reason_ids_type"], null);
          if (reason_ids_type) {
            commitObj["reason_ids_type"] = reason_ids_type;
          }

          commit("updateFilterPublicationOptions", commitObj);
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }
};

<template>
  <div class="notification-center">
    <div class="notification-wrapper">
      <div class="notifications">
        <transition-group name="notification" tag="div">
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="notification-box"
          >
            <Notification
              :notification="notification"
              @close="remove(notification.id)"
            />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import Notification from "./Notification.vue";

export default {
  components: {
    Notification
  },
  data: () => ({
    notifications: [],
    id: 1
  }),
  mounted() {
    this.$bus.$on("notification", (notification) => {
      this.notifications.push({ ...notification, id: this.id });
      this.id++;
    });
  },
  destroyed() {
    this.$bus.$off("notification");
  },
  methods: {
    remove(id) {
      this.notifications = this.notifications.filter(
        (notification) => notification.id !== id
      );
    }
  }
};
</script>

<style lang="sass" scoped>
.notification-enter-active
  transition: all .5s

.notification-leave-active
  transition: all .5s
  position: absolute

.notification-enter
  transform: translateX(300px) scale(0.7)
  opacity: 0

.notification-leave-to
  transform: translateX(300px) scale(0.7)
  opacity: 0

.notification-center
  width: 100vw
  height: 100vh
  position: fixed
  top: 0
  left: 0
  pointer-events: none
  z-index: 110
  .notification-wrapper
    width: 100%
    height: auto
    position: absolute
    padding: 20px
    min-height: 140px
    right: 0
    bottom: 0
    max-width: 480px
    @media (max-width: 480px)
      padding: 12px
    .notifications
      position: relative
      pointer-events: all
      .notification-box
        width: 100%
        display: block !important
        transition: all .5s
        padding: 6px
</style>

import Vue from 'vue';
import excelFormula from 'static/formula.json';

export const state = () => ({
    CELL_REFERENCE_REGEX: /((\'{1}([A-Za-z0-9\!\@\\\#\$\%\^\&\*\(\)\_\+\-\=\{\}\[\]\;\,\~\`\.\:\|\\ ]+(\'{2,})*)+)([^\'])('!){1})?(([A-Za-z0-9\_\.]+|\'[A-Za-z0-9\!\@\#\$\%\^\&\(\)\_\+\-\=\{\}\|\;\'\s\""\,\.\<\>\`\~]+\')\!)?(\$)?[A-Z]+(\$)?[0-9]+(\:(\$)?[A-Z]+(\$)?[0-9]+)?(\:(([A-Za-z0-9\_\.]+|\'[A-Za-z0-9\!\@\#\$\%\^\&\(\)\_\+\-\=\{\}\|\;\'\s\""\,\.\<\>\`\~]+\')\!)?(\$)?[A-Z]+(\$)?[0-9]+(\:(\$)?[A-Z]+(\$)?[0-9]+)?)?/g,
    CELL_ONLY_REGEX: /(\$?)\b([A-Z]+)(\$?)(\d+)\b/g,
    FORMULA_BEGIN: /[\=\+\-\*\/\^\&\:\(\,\<\>]/,
    RECORD_MUTATIONS: [
        'spreadsheet/updateCellValue',
        // 'spreadsheet/calculateAllWorksheets',
        'spreadsheet/updateCellAttr'
    ],

    arrowKeys: {
        'ArrowLeft': 'left',
        'ArrowRight': 'right',
        'ArrowUp': 'up',
        'ArrowDown': 'down',
        37: 'left',
        39: 'right',
        38: 'up',
        40: 'down'
    },
    escapeKeys: {
        'Escape': 'escape',
        27: 'escape'
    },
    undoKeyOrZ: {
        90: 'z',
        'z': 'z'
    },
    redoKeyOrY: {
        89: 'y',
        'y': 'y'
    },
    longEquationString: Object.keys(excelFormula).reduce((a, b) => {
        return a.length > b.length ? a : b;
    }).length,
    cmdOrCtrl: false,
    internalCopy: false,
    showInputCell: false,
    inputtingNameRange: false,
    endKey: false,
});

export const getters = {
    EQUATION_REPLACE_REGEX: (state) => {
        const allEquationArray = Object.keys(excelFormula).filter((value, index, funcself) => {
            return funcself.indexOf(value) === index;
        });
        if (allEquationArray.length === 0) {
            return {
                tmpEquationReplaceRegex: false,
            };
        }
        const equationReplaceRegex = new RegExp(allEquationArray.join("(|").concat('(').replace(/\(/g, '\\$&'), 'gi');
        return {
            equationReplaceRegex,
            equationReplaceRegexFunction: (matched, index, value) => {
                return matched.toUpperCase();
            }
        };
    },
    nonFunctionalKeys: (state) => {
        return (event) => {
            const key = event.key || event.keyCode;
            if (state.arrowKeys[key] || state.escapeKeys[key] || key === 'Shift') {
                // arrow key only when key is up
                return false;
            } else {
                return true;
            }
        };
    },
    directionalKeys: (state) => {
        return (event) => {
            const key = event.key || event.keyCode;
            if (state.arrowKeys[key]) {
                return state.arrowKeys[key];
            } else {
                return false;
            }
        }
    },
    escapeKeyPressed: (state) => {
        return (event) => {
            const key = event.key || event.keyCode;
            if (state.escapeKeys[key]) {
                // arrow key only when key is up
                return false;
            } else {
                return true;
            }
        }
    },
    checkFormulaHint: (state) => {
        return (textToCheck) => {
            if (!textToCheck) return [];
            const newTextToCheck = textToCheck.toUpperCase();
            if (!newTextToCheck) return [];
            const includeKeys = Object.keys(excelFormula).filter((value) => {
                return value.startsWith(newTextToCheck) !== false;
            });
            return includeKeys.map((value) => {
                return {
                    formula: value,
                    ...excelFormula[value]
                };
            });
        }
    },
    checkCmdOrCtrl: (state) => {
        return (event) => {
            const key = event.key || event.keyCode;
            if (navigator.platform === 'MacIntel') {
                if (key === 'Meta') return 'mac';
                return false;
            } else {
                if (key === 'Control' || key === '17') return 'win';
                return false;
            }
            // return false;
        };
    },
    checkUndoOrZ: (state) => {
        return (event) => {
            const key = event.key || event.keyCode;
            if (state.undoKeyOrZ[key]) return true;
            return false;
        }
    },
    relatedCellsFromFormula: (state) => {
        return (textToCheck, matchSheetName = true, matchDetails = true) => {
            const regexToUse = matchSheetName ? state.CELL_REFERENCE_REGEX : state.CELL_ONLY_REGEX;
            let matchedArray;
            if (matchDetails) {
                matchedArray = textToCheck.matchAll(regexToUse);
                return matchedArray ? Array.from(matchedArray) : false;
            } else {
                // only returns the unique ones
                matchedArray = textToCheck.match(regexToUse);
                return matchedArray && matchedArray.length > 0 ? matchedArray.filter((value, index, self) => {
                    // select unique elements
                    return self.indexOf(value) === index;
                }) : false;
            }
        }
    },
    updateFormulaCells: (state, getters, rootState, rootGetters) => {
        return (textToCheck, cellMovement) => {
            let returnFormula = textToCheck;
            const relatedCells = getters.relatedCellsFromFormula(textToCheck, false, true);
            if (relatedCells) {
                relatedCells.forEach((tmpCellArray) => {
                    const [tmpCellText, colDollar, tmpColLetter, rowDollar, tmpRowLetter] = tmpCellArray;
                    const spliceIndex = tmpCellArray.index;
                    let replaceCellIdText = tmpCellText;
                    if (!colDollar && cellMovement.col) {
                        const newColLetter = rootGetters['spreadsheet/encodeCol'](rootGetters['spreadsheet/decodeCol'](tmpColLetter) + cellMovement.col);
                        if (newColLetter !== tmpColLetter) {
                            replaceCellIdText = replaceCellIdText.replace(tmpColLetter, newColLetter);
                        }
                    }
                    if (!rowDollar && cellMovement.row) {
                        const newRowLetter = rootGetters['spreadsheet/encodeRow'](rootGetters['spreadsheet/decodeRow'](tmpRowLetter) + cellMovement.row);
                        if (newRowLetter !== tmpRowLetter) {
                            replaceCellIdText = replaceCellIdText.replace(tmpRowLetter, newRowLetter);
                        }
                    }
                    if (replaceCellIdText !== tmpCellText) {
                        returnFormula = returnFormula.replace(tmpCellText, replaceCellIdText);
                        // returnFormula = returnFormula.substring(0, spliceIndex).concat(replaceCellIdText, returnFormula.substring(spliceIndex + tmpCellText.length));
                    }
                });
            }
            return returnFormula;
        };
    },
    getNumberOfDecimals: () => {
        return (stringToCheck) => {
            if (stringToCheck.toString().split(".").length === 1) return 0;
            return stringToCheck.toString().split(".")[1].length || 0;
        };
    },
};

export const mutations = {
    toggleMacCommandKey(state, trueOrFalse) {
        if (navigator.platform === 'MacIntel') {
            // state.cmdOrCtrl = trueOrFalse;
            Vue.set(state, 'cmdOrCtrl', trueOrFalse);
        }
    },
    toggleWinCtrlKey(state, trueOrFalse) {
        if (navigator.platform !== 'MacIntel') {
            // state.cmdOrCtrl = trueOrFalse;
            Vue.set(state, 'cmdOrCtrl', trueOrFalse);
        }
    },
    updateInternalCopy(state, trueOrFalse) {
        // state.internalCopy = trueOrFalse;
        Vue.set(state, 'internalCopy', trueOrFalse);

    },
    toggleInputCell(state, trueOrFalse) {
        Vue.set(state, 'showInputCell', trueOrFalse);
    },
    toggleNameRangeInput(state, trueOrFalse) {
        Vue.set(state, 'inputtingNameRange', trueOrFalse);
    },
    toggleEndKey(state, trueOrFalse) {
        Vue.set(state, 'endKey', trueOrFalse);
    }
};

export const actions = {
    async remoteToggleCommandKey({
        commit
    }, trueOrFalse) {
        commit('toggleMacCommandKey', trueOrFalse);
        return true;
    },
    async remoteToggleCtrlKey({
        commit
    }, trueOrFalse) {
        commit('toggleWinCtrlKey', trueOrFalse);
        return true;
    },
    async remoteUpdateClipBoardData({
        commit
    }, tmpArray) {
        commit('updateClipBoardData', tmpArray);
        return true;
    },
    async remoteToggleInternalCopy({
        commit
    }, trueOrFalse) {
        commit('updateInternalCopy', trueOrFalse);
        return true;
    },
    async remoteToggleInputCell({
        commit
    }, trueOrFalse) {
        commit('toggleInputCell', trueOrFalse);
        return true;
    },
    async remoteToggleNameRangeInput({
        commit
    }, trueOrFalse) {
        commit('toggleNameRangeInput', trueOrFalse);
        return true;
    },
    async remoteToggleEndKey({
        commit
    }, trueOrFalse) {
        commit('toggleEndKey', trueOrFalse);
        return true;
    }
};
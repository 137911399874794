<template>
  <div class="notification" :class="`type-${notification.type}`">
    <div>
      <p v-if="notification.message" class="text">{{ notification.message }}</p>
    </div>
    <div>
      <ButtonLogin tonal small icon @click="$emit('close')">
        <i class="mi-close"></i>
      </ButtonLogin>
    </div>
  </div>
</template>

<script>
import ButtonLogin from "@/components/new/ButtonLogin.vue";
export default {
  components: {
    ButtonLogin
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
.notification
  background-color: rgba(0,0,0,0.4)
  backdrop-filter: §blur(10px)
  padding: 24px
  border-radius: 8px
  position: relative
  overflow: hidden
  display: grid
  grid-gap: 12px
  grid-template-columns: 1fr auto
  max-width: 100%
  >div
    display: flex
    align-items: center
    justify-content: flex-start
  &.type-error
    color: #fff
    &:after
      background: linear-gradient(90deg, rgba(var(--color-error), 1) 0%, rgba(var(--color-error), .85) 100%)
      content: ""
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      transition: all 0.3s ease
      opacity: .6
      z-index: -1
    &:hover
      &:after
        opacity: 1
  &.type-success
    color: #fff
    &:after
      background: linear-gradient(90deg, rgba(var(--color-success), 8) 0%, rgba(var(--color-success), .6) 100%)
      content: ""
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      transition: all 0.3s ease
      opacity: .45
      z-index: -1
    &:hover
      &:after
        opacity: .9
  .text
    font-size: 14px
    line-height: 1.2
    margin: 0
</style>

<template>
  <DialogWrapper v-model="modelValue" persistant>
    <div class="ie-error-card site-dialog-card">
      <MotionShowUp once :delay="0.1">
        <h2 class="section-title">{{ content?.title }}</h2>
      </MotionShowUp>
      <MotionShowUp once :delay="0.2">
        <p
          class="section-description"
          v-html="documentToHtmlString(content?.description)"
        ></p>
      </MotionShowUp>
    </div>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from "~/components/new/DialogWrapper";
import MotionShowUp from "~/components/new/MotionShowUp";
import { mapGetters } from "vuex";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  components: {
    DialogWrapper,
    MotionShowUp
  },

  data: () => ({
    modelValue: false
  }),

  async mounted() {
    await this.$nextTick();
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      this.modelValue = true;
    }
  },

  computed: {
    ...mapGetters({
      contentReady: "contentful/ready",
      contentful: "contentful/content"
    }),
    content() {
      if (!this.contentReady) return null;
      return this.contentful?.sectionAuthorization[0]?.ieError?.fields;
    }
  },

  methods: {
    documentToHtmlString
  }
};
</script>

<style lang="sass" scoped></style>

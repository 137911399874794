export const TMP_FILE_COPY_OPTION = "copy-change-model";
export const TMP_STORAGE_FOR_MODEL_LOCAL_PREFIX = "model_";
export const MODEL_FILE_TYPE_MAP = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-excel": "xls",
  "text/csv": "csv",
  "application/vnd.ms-excel.sheet.macroEnabled.12": "xlsm",
  "application/vnd.ms-excel.sheet.macroenabled.12": "xlsm",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12": "xlsb",
  "application/vnd.ms-excel.sheet.binary.macroenabled.12": "xlsb"
};

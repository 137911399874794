<template>
  <div class="loader" :class="{ small }"></div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
.loader
  width: 68px
  height: 68px
  border: 5px solid rgba(var(--color-blue-light), 0.1)
  border-bottom-color: transparent
  border-radius: 50%
  display: inline-block
  box-sizing: border-box
  animation: rotation 1s linear infinite
  &.small
    width: 16px
    height: 16px
    border-width: 3px

@keyframes rotation
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>

<template>
  <!-- Our popover title and content render container -->
  <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
  <!-- We specify the same container as the trigger button, so that popover is close to button -->
  <b-popover
    ref="popover"
    :target="targetButtonId"
    triggers="click"
    :show.sync="popoverShow"
    placement="auto"
    :container="containerId"
    custom-class="popover-a popover-a-medium"
    @show="onShow"
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #title>
      <b-button class="close" aria-label="Close" @click="onClose">
        <span class="d-inline-block" aria-hidden="true"
          >&times;</span
        > </b-button
      >Upload Entity Logos And Branding
    </template>
    <div>
      <div class="popover-content mx-0">
        <span style="color: rgba(0, 0, 0, 0.5); font-size: 10px">
          Upload platform image (PNG format, less than 200kb, preferably 5:1)
        </span>
        <drop-zone
          id="platform-dropzone"
          ref="platform-dropzone"
          class="upload-btn"
          :options="options"
          :use-custom-slot="false"
          :destroy-dropzone="true"
          @vdropzone-file-added="onPlatformAdded"
          @vdropzone-thumbnail="onPlatformThumbnail"
          @vdropzone-sending="onPlatformSending"
          @vdropzone-error="onPlatformError"
          @vdropzone-drop="onPlatformDropFile"
        >
          <div class="dropzone-custom-content">
            <b-icon font-scale="2" icon="plus"></b-icon>
            <p class="dropzone-subtitle">
              Drag-n-drop or click to upload a new png image.
            </p>
          </div>
        </drop-zone>
      </div>
      <div class="popover-content mx-0 mt-3">
        <span style="color: rgba(0, 0, 0, 0.5); font-size: 10px">
          Upload report image (PNG format, less than 200kb, preferably 5:1)
        </span>
        <drop-zone
          id="report-dz-dropzone"
          ref="report-dropzone"
          class="upload-btn"
          :options="options"
          :use-custom-slot="false"
          :destroy-dropzone="true"
          @vdropzone-file-added="onReportAdded"
          @vdropzone-thumbnail="onReportThumbnail"
          @vdropzone-sending="onReportSending"
          @vdropzone-error="onReportError"
          @vdropzone-drop="onReportDropFile"
        >
          <div class="dropzone-custom-content">
            <b-icon font-scale="2" icon="plus"></b-icon>
            <p class="dropzone-subtitle">
              Drag-n-drop or click to upload a new png image.
            </p>
          </div>
        </drop-zone>
      </div>
      <div class="popover-content mx-0 mt-3">
        <span style="color: rgba(0, 0, 0, 0.5); font-size: 10px">
          Upload notification image (PNG format, less than 200kb, preferably
          5:1)
        </span>
        <drop-zone
          id="notification-dropzone"
          ref="notification-dropzone"
          class="upload-btn"
          :options="options"
          :use-custom-slot="false"
          :destroy-dropzone="true"
          @vdropzone-file-added="onNotificationAdded"
          @vdropzone-thumbnail="onNotificationThumbnail"
          @vdropzone-sending="onNotificationSending"
          @vdropzone-error="onNotificationError"
          @vdropzone-drop="onNotificationDropFile"
        >
          <div class="dropzone-custom-content">
            <b-icon font-scale="2" icon="plus"></b-icon>
            <p class="dropzone-subtitle">
              Drag-n-drop or click to upload a new png image.
            </p>
          </div>
        </drop-zone>
      </div>
      <div class="popover-content mx-0 mt-3">
        <span style="color: rgba(0, 0, 0, 0.5); font-size: 10px">
          Upload left positive and right negative icons (PNG format, less than
          200kb, preferably 1:1)
        </span>
        <div class="d-flex col-12 double-drop-row flex-row">
          <drop-zone
            id="positive-dropzone"
            ref="positive-dropzone"
            class="upload-btn"
            :options="options"
            :use-custom-slot="false"
            :destroy-dropzone="true"
            @vdropzone-file-added="onPositiveAdded"
            @vdropzone-thumbnail="onPositiveThumbnail"
            @vdropzone-sending="onPositiveSending"
            @vdropzone-error="onPositiveError"
            @vdropzone-drop="onPositiveDropFile"
          >
            <div class="dropzone-custom-content">
              <b-icon font-scale="2" icon="plus"></b-icon>
              <p class="dropzone-subtitle">
                Drag-n-drop or click to upload a new png image.
              </p>
            </div>
          </drop-zone>
          <drop-zone
            id="negative-dropzone"
            ref="negative-dropzone"
            class="upload-btn"
            :options="options"
            :use-custom-slot="false"
            :destroy-dropzone="true"
            @vdropzone-file-added="onNegativeAdded"
            @vdropzone-thumbnail="onNegativeThumbnail"
            @vdropzone-sending="onNegativeSending"
            @vdropzone-error="onNegativeError"
            @vdropzone-drop="onNegativeDropFile"
          >
            <div class="dropzone-custom-content">
              <b-icon font-scale="2" icon="plus"></b-icon>
              <p class="dropzone-subtitle">
                Drag-n-drop or click to upload a new png image.
              </p>
            </div>
          </drop-zone>
        </div>
      </div>
      <div class="popover-content mx-0 mt-3">
        <span style="color: rgba(0, 0, 0, 0.5); font-size: 10px">
          Upload report background (PNG format, less than 1MB, preferably 4:3 or
          16:9)
        </span>
        <drop-zone
          id="report-background-dropzone"
          ref="report-background-dropzone"
          class="upload-btn"
          :options="bgOptions"
          :use-custom-slot="false"
          :destroy-dropzone="true"
          @vdropzone-file-added="onReportBackgroundAdded"
          @vdropzone-thumbnail="onReportBackgroundThumbnail"
          @vdropzone-sending="onReportBackgroundSending"
          @vdropzone-error="onReportBackgroundError"
          @vdropzone-drop="onReportBackgroundDropFile"
        >
          <div class="dropzone-custom-content">
            <b-icon font-scale="2" icon="plus"></b-icon>
            <p class="dropzone-subtitle">
              Drag-n-drop or click to upload a new png image.
            </p>
          </div>
        </drop-zone>
      </div>
      <b-row class="footer mx-0 mt-3">
        <b-col class="col-md-8 px-1">
          <p>
            The images will be displayed on all webpages, emails and reports
            associated with your entity.
          </p>
        </b-col>
        <b-col class="al-right-1 col-md-4 px-1">
          <div class="cancel-btn">
            <cancel-button label="Cancel" @cancel-click="onClose" />
          </div>
          <div class="ok-btn">
            <save-button ref="saveBtn" label="Save" @ok-click="onSave" />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-popover>
</template>

<script>
import CancelButton from "~/components/common/CancelButton";
import OkButton from "~/components/common/OkButton";
import Dropzone from "nuxt-dropzone";
import "nuxt-dropzone/dropzone.css";
import { mapGetters, mapState } from "vuex";
import nodeUtil from "v-tooltip";

export default {
  components: {
    "cancel-button": CancelButton,
    "save-button": OkButton,
    "drop-zone": Dropzone
  },
  props: {
    containerId: {
      type: String,
      required: true
    },
    targetButtonId: {
      type: String,
      required: true
    },
    platformUrl: String,
    reportUrl: String,
    notificationUrl: String,
    positiveUrl: String,
    negativeUrl: String,
    backgroundUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      popoverShow: false,
      platformFile: null,
      reportFile: null,
      notificationFile: null,
      reportBackgroundFile: null,
      errorMsg: "Invalid file size or format. Please upload a PNG under 200kb.",
      options: {
        url: "http://httpbin.org/anything",
        thumbnailWidth: 300,
        thumbnailHeight: 75,
        maxFilesize: 0.2,
        thumbnailMethod: "contain",
        autoProcessQueue: false,
        maxFiles: 1,
        acceptedFiles: ".png"
      },
      bgOptions: {
        url: "http://httpbin.org/anything",
        thumbnailWidth: 300,
        thumbnailHeight: 75,
        maxFilesize: 1,
        thumbnailMethod: "contain",
        autoProcessQueue: false,
        maxFiles: 1,
        acceptedFiles: ".png"
      }
    };
  },
  mounted() {
    if (window) {
      window.addEventListener("paste", this.onPaste);
    }
  },
  beforeDestroy() {
    if (window) {
      window.removeEventListener("paste", this.onPaste);
    }
  },

  methods: {
    onKeyDown(evt) {
      if (evt.keyCode == 13) {
        evt.preventDefault();
        evt.stopPropagation();
        this.onSave();
      } else if (evt.keyCode == 27) {
        evt.preventDefault();
        evt.stopPropagation();
        this.onClose();
      }
    },
    onClose() {
      if (this.$refs["saveBtn"].isLoading) return;
      this.resetValues();
      this.popoverShow = false;
    },
    onSave() {
      if (
        this.platformFile != null ||
        this.reportFile != null ||
        this.notificationFile != null ||
        this.reportBackgroundFile != null
      ) {
        this.$refs["saveBtn"].toggleLoading(true);
        this.$emit("file-added", {
          platform:
            !this.platformFile || this.platformFile.manuallyAdded
              ? null
              : this.platformFile,
          report:
            !this.reportFile || this.reportFile.manuallyAdded
              ? null
              : this.reportFile,
          notification:
            !this.notificationFile || this.notificationFile.manuallyAdded
              ? null
              : this.notificationFile,
          negative:
            !this.negativeFile || this.negativeFile.manuallyAdded
              ? null
              : this.negativeFile,
          positive:
            !this.positiveFile || this.positiveFile.manuallyAdded
              ? null
              : this.positiveFile,
          background:
            !this.reportBackgroundFile ||
            this.reportBackgroundFile.manuallyAdded
              ? null
              : this.reportBackgroundFile
        });
      } else {
        this.onClose();
      }
    },
    onSuccess() {
      this.$refs["saveBtn"].showSuccess();
      setTimeout(() => (this.popoverShow = false), 1000);
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables

      const element = document.createElement("div");
      element.setAttribute("class", "popover-semi-b");
      element.addEventListener("mousedown", this.onMouseDown);
      document.querySelector("body").append(element);
    },
    onMouseDown(e) {
      //When popover background clicked
      this.resetValues();
      this.popoverShow = false;
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      // this.focusRef(this.$refs.localFirstName);
      document.addEventListener("keydown", this.onKeyDown);
      var platformFile = new File([""], "platform");
      var reportFile = new File([""], "report");
      var notificationFile = new File([""], "notification");
      var negativeFile = new File([""], "negative");
      var positiveFile = new File([""], "positive");
      var backgroundFile = new File([""], "background");

      if (this.platformUrl) {
        this.$refs["platform-dropzone"].manuallyAddFile(
          platformFile,
          this.platformUrl
        );
        this.$refs["platform-dropzone"].dropzone.emit(
          "thumbnail",
          platformFile,
          this.platformUrl
        );
        this.$refs["platform-dropzone"].dropzone.emit("complete", platformFile);
      }
      if (this.reportUrl) {
        this.$refs["report-dropzone"].manuallyAddFile(
          reportFile,
          this.reportUrl
        );
        this.$refs["report-dropzone"].dropzone.emit(
          "thumbnail",
          reportFile,
          this.reportUrl
        );
        this.$refs["report-dropzone"].dropzone.emit("complete", reportFile);
      }
      if (this.notificationUrl) {
        this.$refs["notification-dropzone"].manuallyAddFile(
          notificationFile,
          this.notificationUrl
        );
        this.$refs["notification-dropzone"].dropzone.emit(
          "thumbnail",
          notificationFile,
          this.notificationUrl
        );
        this.$refs["notification-dropzone"].dropzone.emit(
          "complete",
          notificationFile
        );
      }
      if (this.negativeUrl) {
        this.$refs["negative-dropzone"].manuallyAddFile(
          negativeFile,
          this.negativeUrl
        );
        this.$refs["negative-dropzone"].dropzone.emit(
          "thumbnail",
          negativeFile,
          this.negativeUrl
        );
        this.$refs["negative-dropzone"].dropzone.emit("complete", negativeFile);
      }
      if (this.positiveUrl) {
        this.$refs["positive-dropzone"].manuallyAddFile(
          positiveFile,
          this.positiveUrl
        );
        this.$refs["positive-dropzone"].dropzone.emit(
          "thumbnail",
          positiveFile,
          this.positiveUrl
        );
        this.$refs["positive-dropzone"].dropzone.emit("complete", positiveFile);
      }
      if (this.backgroundUrl) {
        this.$refs["report-background-dropzone"].manuallyAddFile(
          backgroundFile,
          this.backgroundUrl
        );
        this.$refs["report-background-dropzone"].dropzone.emit(
          "thumbnail",
          backgroundFile,
          this.backgroundUrl
        );
        this.$refs["report-background-dropzone"].dropzone.emit(
          "complete",
          backgroundFile
        );
      }
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      const popoverSemi = document.querySelector(".popover-semi-b");
      if (popoverSemi) {
        popoverSemi.removeEventListener("mousedown", this.onMouseDown);
        popoverSemi.remove();
      }
      this.$root.$emit("bv::hide::popover", this.targetButtonId);
      document.removeEventListener("keydown", this.onKeyDown);
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus();
        });
      });
    },
    resetValues() {},

    onPlatformDropFile() {
      this.$refs["platform-dropzone"].removeAllFiles(true);
      this.$refs["platform-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "unset";
    },
    onReportDropFile() {
      this.$refs["report-dropzone"].removeAllFiles(true);
      this.$refs["report-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "unset";
    },
    onNotificationDropFile() {
      this.$refs["notification-dropzone"].removeAllFiles(true);
      this.$refs["notification-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "unset";
    },
    onPositiveDropFile() {
      this.$refs["positive-dropzone"].removeAllFiles(true);
      this.$refs["positive-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "unset";
    },
    onNegativeDropFile() {
      this.$refs["negative-dropzone"].removeAllFiles(true);
      this.$refs["negative-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "unset";
    },
    onReportBackgroundDropFile() {
      this.$refs["report-background-dropzone"].removeAllFiles(true);
      this.$refs["report-background-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "unset";
    },
    onPlatformError(file, response) {
      // this.$refs["platform-dropzone"].$el.querySelector(
      //   ".dz-message"
      // ).style.display = "unset";
      this.platformFile = null;
      this.$refs["platform-dropzone"].$el.querySelector(
        ".dz-error-message span"
      ).innerText = this.errorMsg;
      const imgContainer =
        this.$refs["platform-dropzone"].$el.querySelector(".dz-image");
      imgContainer.removeChild(
        this.$refs["platform-dropzone"].$el.querySelector(".dz-image img")
      );
      imgContainer.append(document.createElement("IMG"));
    },
    onReportError(file, response) {
      // this.$refs["platform-dropzone"].$el.querySelector(
      //   ".dz-message"
      // ).style.display = "unset";
      this.reportFile = null;
      this.$refs["report-dropzone"].$el.querySelector(
        ".dz-error-message span"
      ).innerText = this.errorMsg;
      const imgContainer =
        this.$refs["report-dropzone"].$el.querySelector(".dz-image");
      imgContainer.removeChild(
        this.$refs["report-dropzone"].$el.querySelector(".dz-image img")
      );
      imgContainer.append(document.createElement("IMG"));
    },
    onNotificationError(file, response) {
      // this.$refs["platform-dropzone"].$el.querySelector(
      //   ".dz-message"
      // ).style.display = "unset";
      this.notificationFile = null;
      this.$refs["notification-dropzone"].$el.querySelector(
        ".dz-error-message span"
      ).innerText = this.errorMsg;
      const imgContainer =
        this.$refs["notification-dropzone"].$el.querySelector(".dz-image");
      imgContainer.removeChild(
        this.$refs["notification-dropzone"].$el.querySelector(".dz-image img")
      );
      imgContainer.append(document.createElement("IMG"));
    },
    onPositiveError(file, response) {
      // this.$refs["platform-dropzone"].$el.querySelector(
      //   ".dz-message"
      // ).style.display = "unset";
      this.positiveFile = null;
      this.$refs["positive-dropzone"].$el.querySelector(
        ".dz-error-message span"
      ).innerText = this.errorMsg;
      const imgContainer =
        this.$refs["positive-dropzone"].$el.querySelector(".dz-image");
      imgContainer.removeChild(
        this.$refs["positive-dropzone"].$el.querySelector(".dz-image img")
      );
      imgContainer.append(document.createElement("IMG"));
    },
    onNegativeError(file, response) {
      // this.$refs["platform-dropzone"].$el.querySelector(
      //   ".dz-message"
      // ).style.display = "unset";
      this.negativeFile = null;
      this.$refs["negative-dropzone"].$el.querySelector(
        ".dz-error-message span"
      ).innerText = this.errorMsg;
      const imgContainer =
        this.$refs["negative-dropzone"].$el.querySelector(".dz-image");
      imgContainer.removeChild(
        this.$refs["negative-dropzone"].$el.querySelector(".dz-image img")
      );
      imgContainer.append(document.createElement("IMG"));
    },
    onReportBackgroundError(file, response) {
      this.reportBackgroundFile = null;
      this.$refs["report-background-dropzone"].$el.querySelector(
        ".dz-error-message span"
      ).innerText = this.errorMsg;
      const imgContainer =
        this.$refs["report-background-dropzone"].$el.querySelector(".dz-image");
      imgContainer.removeChild(
        this.$refs["report-background-dropzone"].$el.querySelector(
          ".dz-image img"
        )
      );
      imgContainer.append(document.createElement("IMG"));
    },
    onPlatformAdded(file) {
      this.$refs["platform-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "none";
      // this.$refs["platform-dropzone"].removeFile(this.platformFile);
    },
    onReportAdded(file) {
      this.$refs["report-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "none";
      // this.$refs["report-dropzone"].removeFile(this.reportFile);
    },
    onNotificationAdded(file) {
      this.$refs["notification-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "none";
      // this.$refs["notification-dropzone"].removeFile(this.notificationFile);
    },
    onNegativeAdded(file) {
      this.$refs["negative-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "none";
      // this.$refs["negative-dropzone"].removeFile(this.negativeFile);
    },
    onPositiveAdded(file) {
      this.$refs["positive-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "none";
      // this.$refs["positive-dropzone"].removeFile(this.positiveFile);
    },
    onReportBackgroundAdded(file) {
      this.$refs["report-background-dropzone"].$el.querySelector(
        ".dz-message"
      ).style.display = "none";
    },
    onPlatformThumbnail(file, dataUrl) {
      if (file.status === "error") {
        return;
      }
      file.thumbnail = dataUrl;
      this.platformFile = file;
      //   this.$refs["platform-dropzone"].removeEventListeners();
    },
    onPlatformSending(file, xhr, formData) {},

    onReportThumbnail(file, dataUrl) {
      if (file.status === "error") {
        return;
      }
      file.thumbnail = dataUrl;
      this.reportFile = file;
      //   this.$refs["report-dropzone"].removeEventListeners();
    },
    onReportSending(file, xhr, formData) {},
    onNotificationThumbnail(file, dataUrl) {
      if (file.status === "error") {
        return;
      }
      file.thumbnail = dataUrl;
      this.notificationFile = file;
      //   this.$refs["notification-dropzone"].removeEventListeners();
    },
    onNotificationSending(file, xhr, formData) {},
    onNegativeThumbnail(file, dataUrl) {
      if (file.status === "error") {
        return;
      }
      file.thumbnail = dataUrl;
      this.negativeFile = file;
      //   this.$refs["notification-dropzone"].removeEventListeners();
    },
    onNegativeSending(file, xhr, formData) {},
    onPositiveThumbnail(file, dataUrl) {
      if (file.status === "error") {
        return;
      }
      file.thumbnail = dataUrl;
      this.positiveFile = file;
      //   this.$refs["notification-dropzone"].removeEventListeners();
    },
    onPositiveSending(file, xhr, formData) {},
    onReportBackgroundThumbnail(file, dataUrl) {
      if (file.status === "error") {
        return;
      }
      file.thumbnail = dataUrl;
      this.reportBackgroundFile = file;
    },
    onReportBackgroundSending(file, xhr, formData) {},
    async onPaste(e) {
      // if (e.clipboardData || window.clipboardData) {
      //     let clipdata = e.clipboardData || window.clipboardData;
      //     let copiedItems = clipdata.items;
      //     for (var i = 0; i < copiedItems.length; i++) {
      //         // Skip content if not image
      //         if (copiedItems[i].type.indexOf("image") == -1) continue;
      //         // Retrieve image on clipboard as blob
      //         var blob = copiedItems[i].getAsFile();
      //         this.$refs.dropzone.addFile(blob);
      //         break;
      //     }
      // }
    }
  }
};
</script>
<style scoped>
#negative-dropzone {
  margin-left: 1em;
}
.double-drop-row .upload-btn {
  width: 50%;
}
.upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.2rem;
  font-size: 12px;
  font-weight: 400;
  background: transparent !important;
  color: #6c757d !important;
  flex-grow: 1;
  min-height: 100px !important;
  /* border-style: dashed; */
  border: dashed 1px rgb(100, 125, 150);
  /* border-color: rgb(100, 125, 150) !important; */
  background-color: rgb(230, 235, 240) !important;
}

.upload-btn :deep(svg) {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.upload-btn :deep(p) {
  text-align: center;
  margin: 0;
  padding: 2% 10%;
}
.upload-btn :deep(.dz-preview) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100px;
  position: relative;
}

.upload-btn :deep(.dz-preview) .dz-details {
  text-align: center;
  font-size: 12px;
  display: none;
}

.upload-btn :deep(.dz-preview) .dz-success-mark,
.upload-btn :deep(.dz-preview) .dz-error-mark,
.upload-btn :deep(.dz-error-message) {
  text-align: center;
}

.upload-btn :deep(.dz-preview) svg {
  display: none;
  width: 24px;
}

.upload-btn :deep(.dz-details) {
  width: 100%;
  padding: 0 2%;
}

.upload-btn :deep(.dz-filename),
.upload-btn :deep(.dz-error-message) span {
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
}

.upload-btn :deep(.dz-image) img {
  display: block;
  margin: auto;
  width: 100% !important;
  max-height: 100px;
  border-radius: 10px;
  object-fit: scale-down;
  cursor: pointer;
}

.upload-btn :deep(.dz-progress) {
  position: relative;
  top: 11%;
}

.upload-btn :deep(.dz-progress) .dz-upload {
  display: block;
  background-color: #ee9a4d;
  height: 3px;
  border-radius: 4px;
  width: 0;
  transition-property: width;
}

.upload-btn :deep(.dz-error-message) {
  display: flex;
  justify-content: space-around;
  color: #ee9a4d;
  font-size: 10px;
  padding: 0 2%;
}
</style>

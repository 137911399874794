export default {
  created() {
    this.$nextTick(function () {
      this.$store.dispatch(
        "app_store/remoteUpdateWindowWidth",
        window.innerWidth
      );
      this.$store.dispatch(
        "app_store/remoteUpdateWindowHeight",
        window.innerHeight
      );
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$nextTick(function () {
      this.$store.dispatch(
        "app_store/remoteUpdateWindowWidth",
        window.innerWidth
      );
      this.$store.dispatch(
        "app_store/remoteUpdateWindowHeight",
        window.innerHeight
      );
    });
  }
};

<template>
  <b-container fluid id="home-container">
    <div id="blue-bg-div"></div>
    <client-only>
      <ranos-logo
        v-if="$route.name === 'download_file'"
        :width="200"
        :height="200"
        size="square"
        background="fill"
        @click="reloadPage"
      />
    </client-only>
    <b-row class="login-header" no-gutters align-v="center">
      <b-col sm="2" md="4" cols="6" class="logo-col">
        <!-- <client-only>
          <b-img class="ranos-bg-logo" src="~/static/logos/logo_Icon_gery.svg"></b-img>
        </client-only> -->
      </b-col>
    </b-row>
    <client-only>
      <nuxt />
    </client-only>
    <div id="error-container">
      <b-button class="invisible" id="error-button"></b-button>
      <error-popover
        v-if="showIEError"
        containerId="entity-container"
        targetButtonId="error-button"
        title="Change Browser"
        description="Microsoft no longer provides security or technical support for Internet Explorer. Please use a different browser like Google Chrome, Microsoft Edge, Mozilla Firefox or Apple Safari."
        ref="error-popover"
      />
    </div>
    <div id="copyright">
      <span>Hosted by <a href="https://ranos.io/">ranos.io</a></span>
      <div class="d-flex mt-2 flex-row">
        <span class="footer-link" @click="reloadPage">Home</span>
        <span class="footer-link ml-3" @click="onTermsClicked">Terms</span>
        <span class="footer-link ml-3" @click="onPrivacyClicked">Privacy</span>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import RanosLogo from "~/components/RanosLogo.vue";
import PencilButtonB from "~/components/common/PencilButtonB";
import ErrorPopover from "~/components/popovers/ErrorPopover";
import useContentful from "~/utilities/contentful";

export default {
  data() {
    return {
      searchInput: "",
      toggleFlag: false,
      showIEError: true,
      copyrightText:
        "Copyright RANOS Limited " +
        new Date().getFullYear() +
        ". All rights reserved."
    };
  },

  async fetch() {
    const content = await useContentful();
    this.setContent(content);
  },

  mounted() {
    this.$nextTick(function () {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer, return version number
        this.showError();
      }

      this.$store.dispatch(
        "app_store/remoteUpdateWindowWidth",
        window.innerWidth
      );
      this.$store.dispatch(
        "app_store/remoteUpdateWindowHeight",
        window.innerHeight
      );
    });
  },
  computed: {
    ...mapGetters({
      userLoggedIn: "user/userLoggedIn",
      isTablet: "app_store/isTablet",
      isMobile: "app_store/isMobile"
    }),
    ...mapState({
      showRegister: (state) => state.user.showRegister
    })
  },

  methods: {
    ...mapMutations({
      setContent: "contentful/setContent"
    }),
    showError() {
      this.$refs["error-popover"].show();
    },
    /*
    onAboutClicked() {
      this.$router.push({ path: "/about/" });
    },
    */
    onPrivacyClicked() {
      this.$router.push({ path: "/legal/privacy-policy/" });
    },
    onTermsClicked() {
      this.$router.push({ path: "/legal/terms-of-use/" });
    },
    reloadPage() {
      this.$router.push({ path: "/" });
    }
  },
  components: {
    "ranos-logo": RanosLogo,
    "pencil-button-b": PencilButtonB,
    "error-popover": ErrorPopover
  }
};
</script>

<style scoped>
#home-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 0 3.5em;
  min-height: 100vh;
  position: relative;
  /* width: 100vw; */
  /* overflow-y: auto; */
}

#blue-bg-div {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  background-size: cover;
  background-repeat: repeat;
  background-image: url("~/assets/images/bg_home.png");
  z-index: 1;
}

.login-header {
  margin-top: 10px;
  justify-content: space-between;
  height: 30px;
}
.logo-container {
  margin-left: -12px;
}

.title {
  display: block;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
  letter-spacing: 1px;
  margin-top: 17vh;
  margin-bottom: 5vh;
  text-align: center;
}

#error-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#copyright {
  color: white;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 1rem;
  left: 3vw;
  z-index: 1000;
  flex-direction: column;
}

#copyright a {
  color: #ff7907;
}

.footer-link {
  cursor: pointer;
}
.logo-col {
  position: fixed;
  top: 0;
}
.ranos-bg-logo {
  width: 90%;
}

.ranos-logo-container {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 1000;
}
/* Mobile Portrait */
@media only screen and (max-width: 600px) and (orientation: portrait) {
  #home-container {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 0 1rem !important;
    min-height: 100vh;
    position: relative;
    /* width: 100vw; */
  }

  #blue-bg-div {
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 100%;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    background-size: cover;
    background-repeat: repeat;
    z-index: 1;
  }

  .page-title {
    color: white;
    font-size: 12px;
  }

  .title {
    display: block;
    font-weight: 600;
    font-size: 30px;
    color: white;
    margin-top: 10vh;
    margin-bottom: 5vh;
    text-align: center;
  }
  .subtitle {
    text-align: center;
    font-size: 18px;
    color: white;
  }

  .mobile-none {
    display: none;
  }
  #landscape-bg-div {
    display: none;
  }
}

/* Mobile Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) and (orientation: landscape) {
  #landscape-bg-div {
    display: flex !important;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(13, 30, 44);
    align-items: center;
    z-index: 10;
  }
  #landscape-bg-div h1 {
    color: white;
  }
  #landscape-bg-div img {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .sub-title {
    margin-bottom: 15px;
  }
  #landscape-bg-div {
    display: flex !important;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(13, 30, 44);
    align-items: center;
    z-index: 10;
  }
  #landscape-bg-div h1 {
    color: white;
  }
  #landscape-bg-div img {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
}

/*Tablet Landscape*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .logo-col {
    max-width: 35%;
    flex: 35%;
    margin-left: -2%;
  }
  #copyright {
    position: absolute;
    font-size: 10px;
  }
}
/*Mobile Landscape*/
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .ranos-bg-logo {
    width: 100%;
    margin-left: -2%;
  }
  #copyright {
    position: absolute;
    font-size: 10px;
    z-index: 30;
  }
}
@media only screen and (max-width: 768px) {
  #copyright {
    z-index: 30;
  }
}
</style>

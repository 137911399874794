<template>
  <b-button :id="id" class="logo-btn d-flex justify-content-between" size="sm">
    <div v-if="url == null">
      <div class="label text-left">{{ label }}</div>
    </div>
    <div v-if="url != null" class="image-container">
      <b-img fluid :src="url" class="image-fit"></b-img>
    </div>
    <b-icon font-scale="0.9" icon="pencil" class="icon"></b-icon>
  </b-button>
</template>

<script>
export default {
  props: {
    clas: String,
    label: {
      type: String,
      required: true,
    },
    sublabel: {
      type: String,
      required: false,
    },
    url: null,
    id: String,
  },
};
</script>

<style scoped>
.logo-btn {
  background: #15293a !important;
  border-style: dashed;
  border-color: #203d52 !important;
  width: 40.5em;
  height: 5em;
}
.logo-btn:hover {
  border-color: rgb(100, 125, 150) !important;
}
.logo-btn:hover .icon {
  color: rgb(100, 125, 150);
}
.logo-btn .label {
  font-weight: 600;
  font-size: 1.5rem;
}
.logo-btn .image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.logo-btn .image-container .image-fit {
  max-width: 30em;
  max-height: 3em;
}
.icon {
  color: #274a63;
}

/* Tablet Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  .logo-btn {
    background: #15293a !important;
    border-style: dashed;
    border-color: #203d52 !important;
    width: 30em;
    height: 5em;
  }
}
</style>

import Vue from "vue";
// think on mobile the lowest is 2 webworkers - define number as safari has no hardwareconcurrency
export const state = () => ({
  MAX_WORKERS: process.client ? window.navigator.hardwareConcurrency : 2,
  ssCalcWorker: null,
  reportWorker: null,
  analyticsWorker: null,
  plateformWorker: null
});

export const getters = {
  totalWorkers: (state) =>
    (state.ssCalcWorker ? 1 : 0) +
    (state.reportWorker ? 1 : 0) +
    !!state.plateformWorker +
    !!state.analyticsWorker
};

export const mutations = {
  saveMaxWorkers(state, max_number) {
    Vue.set(state, "MAX_WORKERS", max_number);
  },
  createWorkers(state, type) {
    switch (type) {
      case "sscalc":
        // state.ssCalcWorkers.push(this.$worker.createSSWorker());
        Vue.set(state, "ssCalcWorker", this.$worker.createSSWorker());
        break;
      case "report":
        Vue.set(state, "reportWorker", this.$worker.createReportWorker());
        break;
      case "analytics":
        Vue.set(state, "analyticsWorker", this.$worker.createAnalyticsWorker());
        break;
      case "platform":
        Vue.set(state, "platformWorker", this.$worker.createPlatformWorker());
        break;
    }
  },
  removePlatformWebSocket(state) {
    Vue.set(state, "platformWorker", null);
  }
};

export const actions = {
  remoteSetMaxWorker({ commit }, max_number) {
    return new Promise((resolve) => {
      commit("saveMaxWorkers", max_number);
      resolve(true);
    });
  },
  remoteCreateWorker({ commit, state, getters }, { type }) {
    return new Promise((resolve, reject) => {
      if (process.client && getters.totalWorkers + 1 <= state.MAX_WORKERS) {
        commit("createWorkers", type);
        resolve(true);
      } else {
        reject(false);
      }
    });
  },
  remoteClosePlatformWebSocket({ state, commit }) {
    if (!state.platformWorker) throw new Error("ws not setup");
    state.platformWorker.postMessage({
      action: "close"
    });
    commit("removePlatformWebSocket");
  }
};

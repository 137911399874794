import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState({
      MAX_WORKERS: (state) => state.webworkers.MAX_WORKERS,
      platformWorker: (state) => state.webworkers.platformWorker
    }),
    ...mapGetters({
      currentEntity: "user/currentEntity",
      uuidv4: "app_store/uuidv4"
    })
  },
  created: function () {
    this.$on("hook:mounted", async function () {
      // this is fired at the end of componets mounted but before other mixins mounted
      if (!this.platformWorker) {
        if (!this.MAX_WORKERS) {
          await this.$store.dispatch(
            "webworkers/remoteSetMaxWorker",
            process.client && window.navigator.hardwareConcurrency
              ? window.navigator.hardwareConcurrency
              : 2
          );
        }
        // worker creation
        try {
          await this.$store.dispatch("webworkers/remoteCreateWorker", {
            type: "platform"
          });
        } catch (e) {
          console.error(e);
        }
        if (this.platformWorker) {
          // setup worker
          this.platformWorker.onmessage = async (event) => {
            switch (event.data.action) {
              case "require setup":
                break;
            }
          };
          const entity_id = this.currentEntity.entity_id || "";
          if (!entity_id) {
            console.error("no entity id to setup ws");
            return;
          }
          const member_id = this.currentEntity.member_id || "";
          if (!member_id) {
            console.error("no member id to setup ws");
            return;
          }
          const session_id = this.uuidv4();
          if (!session_id) {
            console.error("no session id");
            return;
          }
          //const ws_url = process.env.RANOS_WEB_WS_URL;
          this.platformWorker.postMessage({
            action: "set credentials",
            entity_id,
            member_id,
            session_id,
            auth_token:
              this.$axios.defaults.headers.common.Authorization.replace(
                "Bearer ",
                ""
              )
          });
          this.platformWorker.postMessage({
            action: "start"
          });
          this.webworkerUpdatePageName(this.$route.name);
          this.webworkerUpdatePageId(this.$route.name, this.$route.query);
        }
      }
    });
  },
  beforeDestroy() {
    // terminate ws connection
    try {
      this.$store.dispatch("webworkers/remoteClosePlatformWebSocket");
    } catch (e) {
      console.error(e);
    }
  },
  watch: {
    $route(to, from) {
      this.webworkerUpdatePageName(to.name);
      this.webworkerUpdatePageId(to.name, to.query);
    }
  },
  methods: {
    webworkerUpdatePageName(pageName) {
      if (this.platformWorker) {
        this.platformWorker.postMessage({
          action: "set page",
          pageName: pageName
        });
      }
    },
    webworkerUpdatePageId(pageName, pageQuery) {
      const ID_MAP = {
        publicationlist: "srid",
        change: "c",
        security: "s",
        member: "m"
      };
      if (this.platformWorker) {
        const pageId =
          ID_MAP[pageName] && pageQuery[ID_MAP[pageName]]
            ? pageQuery[ID_MAP[pageName]]
            : "";
        this.platformWorker.postMessage({
          action: "set page id",
          pageId: pageId
        });
      }
    }
  }
};

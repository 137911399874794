<template>
  <div :class="!darkFontColor ? 'switch' : 'switch dark'">
    <div v-bind:class="{ selected: !checked }">
      <small>{{ leftLabel }}</small>
    </div>
    <div v-bind:class="{ selected: checked }">
      <b-form-checkbox v-model="checked" switch size="sm" @change="onChange">{{
        rightLabel
      }}</b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggleFlag: { type: Boolean, default: false },
    leftLabel: { type: String, default: "" },
    rightLabel: { type: String, default: "" },
    darkFontColor: { type: Boolean, default: false }
  },
  data() {
    return {
      checked: this.toggleFlag
    };
  },
  methods: {
    onChange(val) {
      this.$emit("switched", val);
    },
    resetValue() {
      this.$nextTick(() => {
        this.checked = false;
      });
    }
  }
};
</script>

<style lang="css" scoped>
.switch {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 5px;
}

.switch :deep(.custom-control-label)::before {
  background: rgb(91, 117, 142) !important;
  border: rgb(91, 117, 142) solid 1px !important;
}

.switch :deep(.custom-control-label)::after {
  background: white;
}

div {
  padding: 0;
  display: flex;
}

small {
  padding-right: 5px;
}

/*
.switch :deep(div.custom-control),
div {
    //padding-top: 0.1em;
    //padding-left: 2.25rem;
}
*/

.switch :deep(.custom-control-label),
small {
  font-size: 10px;
  line-height: 1.5;
  font-weight: 400;
}

.switch .selected :deep(.custom-control-label),
.selected small {
  color: white !important;
}

.switch.dark {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switch.dark .selected {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switch.dark .custom-control {
  display: flex;
  align-items: center;
}
.switch.dark :deep(.custom-control-label)::before {
  background: rgb(91, 117, 142) !important;
  border: rgb(91, 117, 142) solid 1px !important;
  top: 0.35rem;
}

.switch.dark :deep(.custom-control-label)::after {
  background: white;
  top: calc(0.35rem + 2px);
}

.switch.dark :deep(.custom-control-label),
.switch.dark small {
  font-size: 12px !important;
  line-height: 1.5;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5) !important;
}

.switch.dark .selected :deep(.custom-control-label),
.switch.dark .selected small {
  color: rgba(0, 0, 0) !important;
}
</style>

import Vue from "vue";

function initialState() {
  return {
    rgptFileId: null,
    rgptFileName: "Query Response",
    rgptFileMd5: null,
    rgptPdfLink: null,
    rgptResponseList: [],
    rgptResponseWordCount: null,
    rgptQuestionCategories: [],
    rgptQuestionQueries: [],
    rgptAllQuestionCategories: [
      {
        name: "None",
        value: "",
        default: true,
        definitions_list: [
          {
            name: "All",
            def_index: "All",
            index: 0
          }
        ]
      }
    ],
    rgptPersona: null,
    rgptPersonaExample: null,
    rgptTextSource: "",
    isTextSource: false
  };
}
export const state = () => ({
  rgptFileId: null,
  rgptFileName: "Query Response",
  rgptFileMd5: null,
  rgptPdfLink: null,
  rgptResponseList: [],
  rgptResponseWordCount: null,
  rgptQuestionCategories: [],
  rgptQuestionQueries: [],
  rgptAllQuestionCategories: [
    {
      name: "None",
      value: "",
      default: true,
      definitions_list: [
        {
          name: "All",
          def_index: "All",
          index: 0
        }
      ]
    }
  ],
  rgptPersona: null,
  rgptPersonaExample: null,
  rgptTextSource: "",
  isTextSource: true
});
export const getters = {};
export const mutations = {
  resetRgptState(state, condition) {
    if (condition) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    }
  },
  setRgptFileMd5(state, rgptFileMd5) {
    Vue.set(state, "rgptFileMd5", rgptFileMd5);
  },
  setRgptFileId(state, rgptFileId) {
    Vue.set(state, "rgptFileId", rgptFileId);
  },
  setRgptFileName(state, rgptFileName) {
    Vue.set(state, "rgptFileName", rgptFileName);
  },
  setRgptPdfLink(state, rgptPdfLink) {
    Vue.set(state, "rgptPdfLink", rgptPdfLink);
  },
  setRgptResponseList(state, rgptResponseList) {
    Vue.set(state, "rgptResponseList", rgptResponseList);
  },
  setRgptResponseWordCount(state, rgptResponseWordCount) {
    Vue.set(state, "rgptResponseWordCount", rgptResponseWordCount);
  },
  setRgptQuestionCategories(state, questionCategories) {
    Vue.set(state, "rgptQuestionCategories", questionCategories);
  },
  setRgptAllQuestionCategories(state, questionCategories) {
    const allCategories = structuredClone(questionCategories);
    if (allCategories.length === 0) {
      allCategories.push({
        name: "None",
        definitions_list: [
          {
            name: "All",
            def_index: "All",
            index: 0
          }
        ],
        default: true,
        value: ""
      });
    } else {
      allCategories.forEach((category) => {
        let index = 0;
        category.name = category.def_category_name;
        category.definitions_list.unshift({
          name: "All",
          def_index: "All",
          index: 0,
          default: true
        });
        category.definitions_list.forEach((definition) => {
          if (definition.def_index) {
            definition.name = definition.def_index;
          }
          definition.index = index;
          index++;
        });
      });
    }
    Vue.set(state, "rgptAllQuestionCategories", allCategories);
  },
  setRgptQuestionQueries(state, questionQueries) {
    Vue.set(state, "rgptQuestionQueries", questionQueries);
  },
  setRgptPersona(state, persona) {
    Vue.set(state, "rgptPersona", persona);
  },
  setRgptPersonaExample(state, personaExample) {
    Vue.set(state, "rgptPersonaExample", personaExample);
  },
  setRgptTextSource(state, textSource) {
    Vue.set(state, "rgptTextSource", textSource);
  },
  setRgptisTextSource(state, isTextSource) {
    Vue.set(state, "isTextSource", isTextSource);
  }
};
export const actions = {
  async remoteUploadRGPTFile(
    { commit, rootState, rootGetters },
    { file, fileName, uploadFileMD5 }
  ) {
    if (fileName.length === 0)
      return {
        action: "error",
        message: "no files"
      };
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("original_filename", fileName);
    formData.append("upload_file_md5", uploadFileMD5);

    try {
      const r = await this.$axios.$post(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/upload_file_to_ai`,
        formData
      );
      commit("setRgptFileMd5", r.file_content_md5);
      commit("setRgptFileId", r.file_id);
      commit("setRgptFileName", fileName);
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async getRgptDisplayUrl({ rootState, commit }, { file_content_md5 }) {
    const entity_id = rootState.auth.selected_entity;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/file_content_md5/${file_content_md5}/display_file_url`
      );
      commit("setRgptPdfLink", r.display_url);
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async getRgptDisplayUrlByFileId({ rootState, commit }, { file_id }) {
    const entity_id = rootState.auth.selected_entity;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/file_id/${file_id}/display_file_url`
      );
      commit("setRgptPdfLink", r.display_url);
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async rgptAskQuestionInFile({ rootState, rootGetters }, { payload }) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$post(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/ask_question_in_file`,
        payload
      );
      return { action: "accept", message: r };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async rgptFetchAnswer({ rootState, rootGetters }, { question_id }) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/question/${question_id}/fetch_answer`
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async rgptAskQuestionInText({ rootState, rootGetters }, { payload }) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$post(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/ask_question_in_text`,
        payload
      );
      const responseBody = r.response_payload; //r.response_payload.errorMessage
      if (responseBody.question_result !== true) {
        responseBody.question_answer = responseBody.message;
      }
      return { action: "accept", message: responseBody };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async rgptCopyResourceToAi({ rootState, rootGetters, commit }, payload) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$post(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/copy_resource_to_ai?resource_type=${payload.resource_type}&resource_id=${payload.resource_id}`
      );
      commit("setRgptFileId", r.file_id);
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  // Category
  async createQuestionCategory({ rootState, rootGetters }, payload) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$post(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/create_question_category`,
        payload
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  async updateQuestionCategory(
    { rootState, rootGetters },
    { payload, def_category_id }
  ) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$put(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/definition_category/${def_category_id}/category`,
        payload
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);

      throw e;
    }
  },
  async fetchQuestionCategory({ rootState, rootGetters }, def_category_id) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/definition_category/${def_category_id}/category`
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async deleteQuestionCategory({ rootState, rootGetters }, def_category_id) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$delete(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/definition_category/${def_category_id}/category`
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  //Query
  async createQuestionQuery({ rootState, rootGetters }, payload) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$post(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/create_question_definition`,
        payload
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async updateQuestionQuery({ rootState, rootGetters }, { payload, def_id }) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$put(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/question_definition/${def_id}/ai_question_definition`,
        payload
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async fetchQuestionQuery({ rootState, rootGetters }, def_id) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/question_definition/${def_id}/ai_question_definition`
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async deleteQuestionQuery({ rootState, rootGetters }, def_id) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$delete(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/question_definition/${def_id}/ai_question_definition`
      );
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.response.data.message
      };
    }
  },
  async fetchQuestionCategories({ rootState, rootGetters, commit }) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/question_categories`
      );
      commit("setRgptQuestionCategories", r);
      commit("setRgptAllQuestionCategories", r);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async fetchQuestionDefinitions({ rootState, rootGetters, commit }) {
    const entity_id = rootState.auth.selected_entity;
    const member_id = rootGetters["user/currentEntity"].member_id;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/member/${member_id}/question_definitions`
      );
      commit("setRgptQuestionQueries", r);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async fetchRGPTPersona({ rootState, rootGetters, commit }) {
    const entity_id = rootState.auth.selected_entity;
    try {
      const r = await this.$axios.$get(
        `/v2/artificial_intelligence/entity/${entity_id}/manage_entity_rgpt_prompt`
      );
      if (r && r.result === true && r.message === "success") {
        if (r.example_rgpt_prompt) {
          const example = rootGetters["app_store/splitString"](
            r.example_rgpt_prompt,
            "\nSystem: ",
            "\n----------------\n\n{context}\n\nHuman: {question}\n"
          );
          commit("setRgptPersonaExample", example);
        }
        const persona = rootGetters["app_store/splitString"](
          r.entity_rgpt_prompt,
          "\nSystem: ",
          "\n----------------\n\n{context}\n\nHuman: {question}\n"
        );
        commit("setRgptPersona", persona);
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async updateRGPTPersona({ rootState, rootGetters, commit }, payload) {
    const entity_id = rootState.auth.selected_entity;
    try {
      const r = await this.$axios.$post(
        `/v2/artificial_intelligence/entity/${entity_id}/manage_entity_rgpt_prompt`,
        payload
      );
      if (r && r.result === true) {
        const persona = rootGetters["app_store/splitString"](
          r.prompt,
          "\nSystem: ",
          "\n----------------\n\n{context}\n\nHuman: {question}\n"
        );
        commit("setRgptPersona", persona);
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
};

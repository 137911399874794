export default function ({ redirect, route }) {
  let isMaintenance = false;
  if (isMaintenance && route.path !== "/maintenance/") {
    redirect("/maintenance/");
    return;
  }
  if (isMaintenance === false && route.path === "/maintenance/") {
    redirect("/");
    return;
  }
}

export const state = () => ({
  customLayout: null,
  customLayoutSet: false,
  entityLayoutId: null,
  challangeId: null
});

export const getters = {
  customLayout(state) {
    return state.customLayout;
  },
  customLayoutSet(state) {
    return state.customLayoutSet;
  },
  entityLayoutId(state) {
    return state.entityLayoutId;
  },
  challangeId(state) {
    return state.challangeId;
  }
};

export const mutations = {
  setLayout(state, payload) {
    if (!payload) return;
    state.customLayout = {
      "entity-logo": payload.entity_logo,
      "sign-in": {
        title: payload.sign_in_title,
        description: payload.sign_in_welcome_message
      },
      "sign-up": {
        title: payload.sign_up_title,
        description: payload.sign_up_welcome_message
      }
    };
    localStorage.removeItem("entityLayoutId");
    state.customLayoutSet = true;
  },

  setEntityLayoutId(state, payload) {
    localStorage.setItem("entityLayoutId", payload);
    state.entityLayoutId = payload;
    console.log("entityLayoutId set", payload);
  },

  setChallangeId(state, payload) {
    state.challangeId = payload;
  }
};

export const actions = {
  async sendLead({}, payload) {
    try {
      const response = await this.$axios.$post("/v2/other/contact_us", {
        email: payload.email,
        recaptcha_token: payload.token || ""
      });
      return response;
    } catch (error) {
      console.log("Error:", error);
      return error;
    }
  },

  async consumeLayout({ commit, state }) {
    if (!localStorage.getItem("entityLayoutId") || state.customLayoutSet) {
      return;
    }
    try {
      const response = await this.$axios.$get(
        `/v2/common/entity_id/${localStorage.getItem(
          "entityLayoutId"
        )}/entity_brand_data`
      );
      commit("setLayout", response);
      return response;
    } catch (error) {
      console.log(error);
      // commit("setLayout", {
      //   entity_logo:
      //     "https://resource.dev.ranos.io/7da90c56-0ffc-4b84-9bea-7908453fa8eb/logo_7da90c56-0ffc-4b84-9bea-7908453fa8eb_20230418223004.png",
      //   sign_in_title: "Euroz Hartleys",
      //   sign_in_welcome_message:
      //     "Euroz Hartleys uses the RANOS platform to produce and distribute equity research and other financial content.",
      //   sign_up_title: "Euroz Hartleys",
      //   sign_up_welcome_message:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla odio sem, hendrerit fermentum molestie eget, pharetra sit amet nibh. Donec neque risus, maximus non venenatis quis, posuere non mauris. Phasellus a aliquet diam."
      // });
      // console.log("Error layout set");
      return error;
    }
  }
};

<template>
  <div v-if="!hidden">
    <nuxt-link
      v-ripple="15"
      :to="disabled ? '#' : href"
      class="main-menu-item"
      :class="{ active: active, disabled: disabled }"
      @click.native="handleClick"
    >
      <i v-if="disabled" class="la la-lock"></i>
      <slot></slot>
    </nuxt-link>

    <dialog-wrapper v-model="dialogError" @close="dialogError = false">
      <card class="dialog-error" no-line>
        <template slot="header">
          <div class="view-panel-title">Contact Administrator</div>
          <button-action
            small
            icon-only
            icon="close"
            @click="$emit('close'), (dialogError = false)"
          ></button-action>
        </template>
        <div class="content">
          <p class="view-panel-description">{{ disabledMessage }}</p>
        </div>
      </card>
    </dialog-wrapper>
  </div>
</template>

<script>
import Card from "~/components/new-platform/cards/PlatformCard";
import DialogWrapper from "~/components/new-platform/containers/DialogWrapper";
import ButtonAction from "~/components/new-platform/buttons/ButtonAction";

export default {
  components: {
    card: Card,
    "dialog-wrapper": DialogWrapper,
    "button-action": ButtonAction
  },

  props: {
    href: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledMessage: {
      type: String,
      default: ""
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dialogError: false
  }),

  methods: {
    handleClick() {
      if (this.disabled) {
        this.dialogError = true;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.main-menu-item
  font-size: var(--font-size-regular)
  color: rgba(255,255,255,0.65)
  padding: 4px 8px
  border-radius: var(--button-action-radius)
  transition: all 0.2s ease-in-out
  text-decoration: none !important
  font-weight: 600
  &:hover
    background-color: rgba(255,255,255,0.07)
    color: rgba(255,255,255,0.8)
  &.active
    background-color: rgba(255,255,255,0.15)
    color: rgba(255,255,255,1)
  &.disabled
    opacity: 0.5

.dialog-error
  max-width: var(--dialog-card-width-small) !important
</style>

<template>
  <svg viewBox="0 0 813 143" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.71582"
      y="0.869629"
      width="35.9832"
      height="35.9832"
      rx="1"
      :fill="white ? '#fff' : '#F66522'"
    />
    <rect
      x="0.71582"
      y="53.8975"
      width="35.9832"
      height="35.9832"
      rx="1"
      :fill="white ? '#fff' : '#F66522'"
    />
    <rect
      x="0.71582"
      y="106.925"
      width="35.9832"
      height="35.9832"
      rx="1"
      :fill="white ? '#fff' : '#F66522'"
    />
    <rect
      x="51.8491"
      y="0.869629"
      width="89.0111"
      height="35.9832"
      rx="1"
      :fill="white ? '#fff' : '#F66522'"
    />
    <rect
      x="51.8491"
      y="53.8975"
      width="89.0111"
      height="35.9832"
      rx="1"
      fill="#fff"
    />
    <rect
      x="51.8491"
      y="106.925"
      width="89.0111"
      height="35.9832"
      rx="1"
      fill="#fff"
    />
    <path
      d="M228.726 36.1874V107.191H244.819V49.9937H291.023C295.592 49.9937 296.388 53.2809 296.215 54.9245V66.2654C296.215 70.2101 292.754 71.1962 291.023 71.1962H279.083V85.0026L297.772 107.191H316.462L296.215 85.0026H297.772C304.417 85.0026 312.308 77.1132 312.308 71.1962V49.9937C312.308 40.921 302.618 37.0092 297.772 36.1874H228.726Z"
      fill="#fff"
    />
    <path
      d="M346.572 107.191L384.47 40.6251C385.162 39.1459 387.066 36.1874 389.142 36.1874H404.198C405.859 36.1874 408.005 39.1459 408.87 40.6251L448.845 107.191H430.674L396.93 49.9937L364.223 107.191H346.572Z"
      fill="#fff"
    />
    <path
      d="M476.879 43.5836V107.191H493.491V49.5006L538.138 103.74C540.215 106.205 542.291 107.191 543.849 107.191H560.981C565.549 107.191 567.037 103.247 567.21 101.274V36.1874H551.636V94.3711L507.508 40.6251C505.432 38.1597 504.393 36.1874 499.202 36.1874H484.666C478.021 36.1874 476.705 41.1182 476.879 43.5836Z"
      fill="#fff"
    />
    <path
      d="M625.879 93.7225H647.164V107.036H623.803C605.944 107.036 601.825 93.2294 601.999 86.3263V54.769C601.999 42.5405 614.972 36.5249 621.207 36.5249H647.164V50.8243H625.879C620.065 50.8243 618.611 55.4264 618.611 57.7275V86.8194C618.611 91.9474 623.457 93.5582 625.879 93.7225Z"
      fill="#fff"
    />
    <path
      d="M668.449 93.7225H647.164V107.036H670.526C688.385 107.036 692.503 93.2294 692.33 86.3263V54.769C692.33 42.5405 679.346 36.5249 673.122 36.5249H647.164V50.8243H668.449C674.264 50.8243 675.718 55.4264 675.718 57.7275V86.8194C675.718 91.9474 670.872 93.5582 668.449 93.7225Z"
      fill="#fff"
    />
    <path
      d="M799.789 107.191H733.338V94.3711H790.963C795.532 94.3711 796.674 90.7552 796.674 88.9472V82.0441C796.674 77.7049 792.867 76.6202 790.963 76.6202H745.278C735.726 76.6202 733.338 69.0596 733.338 65.2793V47.5283C733.338 38.85 741.298 36.3517 745.278 36.1874H810.172V49.5006H754.623C750.47 49.5006 749.432 52.7878 749.432 54.4314V60.3484C749.432 64.2931 752.893 65.2793 754.623 65.2793H802.385C809.445 65.2793 812.248 71.8537 812.248 74.6478V94.8642C812.248 104.331 803.942 107.191 799.789 107.191Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false
    }
  }
};
</script>

import Vue from "vue";
// import qs from "qs";
const clone = require("rfdc")({
  proto: true
});
const moment = require("moment-timezone");
const ENV_BASE_URL = process.env.BASE_URL;
export const state = () => ({
  WIDTH_REFRESH_PR: 5,
  HEIGHT_REFRESH_PR: 10,
  //  STOP_RSZ_ROUTE: ["memberlist", "changelist", "securitylist", "change"],
  STOP_RSZ_ROUTE: [],
  API_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss.S",
  expandedWidget: "",
  appExpanded: false,
  tabletAppExpanded: false,
  appLoading: false,
  limitedAccess: "",
  chartColors: [
    "rgb(25, 25, 25)",
    "rgb(75,135,190)",
    "rgb(175,185,200)",
    "rgb(40,75,110)",
    "rgb(100,100,100)",
    "rgb(100,150,200)",
    "rgb(80,100,120)",
    "rgb(175,185,200)",
    "rgb(175,175,175)"
  ],
  resizeCount: 0,
  windowWidth: 0,
  windowHeight: 0,
  reportScrollTop: 0,
  TABLET_CRITERIA_WIDTH: 1550,
  MOBILE_CRITERIA_WIDTH: 600,
  TZ_DATE_FORMAT: {
    "Pacific/Auckland": "DD MMM YYYY"
  },
  DEFAULT_DATE_FORMAT: "DD MMM YYYY",
  DEFAULT_TIMEZONE: "Pacific/Auckland",
  computedDevicePixelRatio: 0,
  DEFAULT_TIMEZONE_ARRAY: [
    "Pacific/Auckland",
    "Australia/Syndey",
    "America/New_York",
    "America/Vancouver",
    "Asia/Shanghai",
    "Europe/Moscow"
  ],
  // isDev: devEnv === "development"
  isDev:
    ENV_BASE_URL.indexOf("local") > -1 ||
    ENV_BASE_URL.indexOf("dev") > -1 ||
    ENV_BASE_URL.indexOf("staging") > -1
      ? true
      : false,
  tempUploadFile: {},
  getTinymceLicenseKey:
    ENV_BASE_URL.indexOf("local") > -1 ||
    ENV_BASE_URL.indexOf("dev") > -1 ||
    ENV_BASE_URL.indexOf("staging") > -1
      ? "T7LKA787E390E1EC995B67473BBBF14333F92527DB3C3CAB278D037BBFCE16BF"
      : "T7LK2A85F91BBAA26B2CA1424788618503FF642D6CFE424D8B4BD25925107131"
});
export const getters = {
  isTablet: (state) => {
    return state.windowWidth < state.TABLET_CRITERIA_WIDTH;
  },
  isMobile: (state) => {
    return state.windowWidth < state.MOBILE_CRITERIA_WIDTH;
  },
  isMobileTablet: () => {
    var check = false;
    (function (a) {
      if (
        // eslint-disable-next-line no-useless-escape
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        // eslint-disable-next-line no-useless-escape
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  isMobileDevice: () => {
    var check = false;
    (function (a) {
      if (
        // eslint-disable-next-line no-useless-escape
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line no-useless-escape
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  isTouchScreen: () => {
    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
      return true;
    else return false;
  },
  getChartColor: (state) => {
    return (index) => {
      return state.chartColors[index % state.chartColors.length];
    };
  },
  getBootstrapTableHeader: () => {
    return (tmpArray) =>
      tmpArray.map((tmpItem) => ({
        key: tmpItem.value.internal_name,
        label: tmpItem.value.name,
        sortable: true
      }));
  },
  uuidv4: () => {
    return () =>
      "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
  },
  chunkString: () => {
    return (str, size) => {
      const numChunks = Math.ceil(str.length / size);
      const chunks = new Array(numChunks);
      for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substr(o, size);
      }
      return chunks;
    };
  },
  getDecimalNumberValueFromString: () => {
    return (stringValue) => {
      try {
        // eslint-disable-next-line no-useless-escape
        let returnValue = Number(stringValue.replace(/[^0-9\.]+/g, ""));
        if (stringValue.trim().substring(0, 1) === "-") returnValue *= -1;
        return returnValue;
      } catch (e) {
        console.error(e);
        return 0;
      }
    };
  },
  objectNestedPropertyCheck: () => {
    return (tmpObj, propArray, defaultValue) => {
      if (!tmpObj || propArray.length === 0) return defaultValue;
      for (let i = 0; i < propArray.length; i++) {
        if (!tmpObj || !Object.hasOwn(tmpObj, propArray[i]))
          return defaultValue;
        else tmpObj = tmpObj[propArray[i]];
      }
      return tmpObj;
    };
  },
  numberWithCommasWithoutDecimalpoint() {
    return (x) => {
      if (typeof x === "string") {
        const tempArray = x.split(".");
        const intString = parseFloat(
          tempArray[0].replace(/,/g, "")
        ).toLocaleString();
        if (tempArray[1]) {
          return intString + "." + tempArray[1];
        } else {
          return intString;
        }
      } else {
        return x.toLocaleString();
      }
    };
  },
  numberWithCommas() {
    return (x) =>
      typeof x === "string"
        ? parseFloat(x.replace(/,/g, "")).toFixed(2).toLocaleString()
        : x.toLocaleString();
  },
  integerWithCommas() {
    return (x) =>
      typeof x === "string"
        ? parseInt(x.replace(/,/g, "")).toLocaleString()
        : x.toLocaleString();
  },
  numberDecimal() {
    return (num, decimals) =>
      num.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
  },
  shouldResizeRoute(state) {
    return (currentRoute) => !state.STOP_RSZ_ROUTE.includes(currentRoute);
  },
  getGraphStatsData() {
    return (data) => {
      var points = [];
      if (data) {
        points.push(data.first.value || 0);
        points.push(data.middle.value || 0);
        points.push(data.last.value || 0);
      }
      return points;
    };
  },
  popoverRadioListInit() {
    return (tmpArray, orderAttr) => {
      const returnArray = clone(tmpArray);
      const nonOrderIndices = [];
      let firstZero = false;
      let maxOrder = 0;
      for (let i = 0; i < returnArray.length; i++) {
        if (Object.hasOwn(returnArray[i], orderAttr)) {
          if (
            returnArray[i][orderAttr] === 0 ||
            returnArray[i][orderAttr] === null
          ) {
            if (!firstZero) firstZero = true;
            // allow one zero
            else nonOrderIndices.push(i);
          } else {
            maxOrder = Math.max(maxOrder, returnArray[i][orderAttr]);
          }
        } else {
          nonOrderIndices.push(i);
        }
      }
      if (nonOrderIndices.length > 0) {
        for (let i = 0; i < nonOrderIndices.length; i++) {
          returnArray[nonOrderIndices[i]][orderAttr] = maxOrder + 1;
          maxOrder++;
        }
      }
      return returnArray;
    };
  },
  arrayBinarySearchWithKey() {
    return (tmpFullArray, attrToMatch, valueToMatch, notFoundObj) => {
      let startIndex = 0;
      let endIndex = tmpFullArray.length - 1;
      // While Start Index is less than or equal to End Index
      while (startIndex <= endIndex) {
        // Define Middle Index (This will change when comparing )
        const middleIndex = Math.floor((startIndex + endIndex) / 2);
        // Compare Middle Index with Target for match
        if (!Object.hasOwn(tmpFullArray[middleIndex], attrToMatch)) {
          startIndex++;
        } else if (valueToMatch === tmpFullArray[middleIndex][attrToMatch]) {
          return {
            index: middleIndex,
            result: tmpFullArray[middleIndex]
          };
        }
        // Search Right Side Of Array
        else if (valueToMatch > tmpFullArray[middleIndex][attrToMatch]) {
          // Assign Start Index and increase the Index by 1 to narrow search
          startIndex = middleIndex + 1;
        }
        // Search Left Side Of Array
        else if (valueToMatch < tmpFullArray[middleIndex][attrToMatch]) {
          // Assign End Index and increase the Index by 1 to narrow search
          endIndex = middleIndex - 1;
        }
        // // Not found in this iteration of the loop. Looping again.
        else {
          // console.log("Not Found this loop iteration. Looping another iteration.");
          startIndex++;
        }
      }
      return {
        index: -1,
        result: notFoundObj
      };
    };
  },
  getCurrentTimeZone: (state) => moment.tz.guess() || state.DEFAULT_TIMEZONE,
  getTimeZoneDateFormat(state) {
    return (currentTz) =>
      state.TZ_DATE_FORMAT[currentTz] || state.DEFAULT_DATE_FORMAT;
  },
  getUtcToTzTime(state, getters) {
    return (utcTime, newDateFormat, oldDateFormat, currentTimeZone = null) => {
      if (!currentTimeZone) currentTimeZone = getters.getCurrentTimeZone;
      if (!newDateFormat)
        newDateFormat = getters.getTimeZoneDateFormat(currentTimeZone);
      if (!oldDateFormat) oldDateFormat = "DD MMMM YYYY";
      return moment
        .utc(utcTime, oldDateFormat)
        .tz(currentTimeZone)
        .format(newDateFormat);
    };
  },
  getUtcTime(state, getters) {
    return (utcTime, newDateFormat, oldDateFormat) => {
      if (!oldDateFormat) oldDateFormat = "DD MMMM YYYY";
      return moment.utc(utcTime, oldDateFormat).format(newDateFormat);
    };
  },
  dataUriToBlob() {
    return (dataUri) => {
      // convert base64/UR:Encoded data component to raw binayr data held in a string
      let byteString;
      const dataArray = dataUri.split(",");
      if (!dataArray[0] || !dataArray[1]) return {};
      if (dataArray[0].indexOf("base64") >= 0) byteString = atob(dataArray[1]);
      else byteString = decodeURI(dataArray[1]);
      // separate out the mime component
      const mimeType = dataArray[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      const checkLen = byteString.length;
      const returnString = new Uint8Array(checkLen);
      for (let i = 0; i < byteString.length; i++) {
        returnString[i] = byteString.charCodeAt(i);
      }
      return {
        returnString,
        mimeType
      };
    };
  },
  getTimeFromNow() {
    return (timeString, isUtc, oldDateFormat) => {
      if (!oldDateFormat) oldDateFormat = "DD MMMM YYYY";
      if (isUtc) {
        return moment.utc(timeString, oldDateFormat).fromNow();
      } else {
        return moment(timeString, oldDateFormat).fromNow();
      }
    };
  },
  generateHexString: () => {
    return (length) => {
      // Use crypto.getRandomValues if available
      if (
        typeof crypto !== "undefined" &&
        typeof crypto.getRandomValues === "function"
      ) {
        var tmp = new Uint8Array(Math.max(~~length / 2));
        crypto.getRandomValues(tmp);
        return Array.from(tmp)
          .map((n) => ("0" + n.toString(16)).substr(-2))
          .join("")
          .substr(0, length);
      }
      // fallback to Math.getRandomValues
      var ret = "";
      while (ret.length < length) {
        ret += Math.random().toString(16).substring(2);
      }
      return ret.substring(0, length);
    };
  },
  getUnixTimeFromString() {
    return (timeString, timeFormat, isUtc) => {
      if (isUtc) {
        return moment.utc(timeString, timeFormat).valueOf();
      } else {
        return moment(timeString, timeFormat).valueOf();
      }
    };
  },
  commonTimeString(state, getters) {
    return (time, originalFormat, newFormat) => {
      let returnMsg = getters.getUtcToTzTime(time, newFormat, originalFormat);
      returnMsg += " ( ";
      returnMsg += getters.getTimeFromNow(time, true, originalFormat);
      returnMsg += " )";
      return returnMsg;
    };
  },
  parseHtml() {
    return (rawString) => {
      const parser = new DOMParser();
      const elem = parser.parseFromString(rawString, "text/html");
      return elem.body.innerText;
    };
  },
  splitString() {
    return (value, startPatten, endPatten) => {
      const startIndex = value.indexOf(startPatten) + startPatten.length;
      const endIndex = value.indexOf(endPatten);
      if (startIndex !== -1 && endIndex !== -1) {
        return value.substring(startIndex, endIndex);
      } else {
        return value;
      }
    };
  }
};
export const mutations = {
  mutateDiv(state, newExpandWidget) {
    state.appExpanded = !state.appExpanded;
    state.expandedWidget = newExpandWidget;
  },
  mutateTabletDiv(state, newExpandWidget) {
    state.tabletAppExpanded = !state.tabletAppExpanded;
    state.expandedWidget = newExpandWidget;
  },
  toggleAppLoading(state, trueOrFalse) {
    state.appLoading = trueOrFalse;
  },
  updateLimitedAccess(state, access) {
    state.limitedAccess = access;
  },
  updateWindowWidth(state, newWidth) {
    Vue.set(state, "windowWidth", newWidth);
  },
  updateWindowHeight(state, newHeight) {
    Vue.set(state, "windowHeight", newHeight);
  },
  updateReportScrollTop(state, newValue) {
    Vue.set(state, "reportScrollTop", newValue);
  },
  updateResizeCount(state, newCount) {
    Vue.set(state, "resizeCount", newCount);
  },
  updateDevicePixelRatio(state, newRatio) {
    Vue.set(state, "computedDevicePixelRatio", newRatio);
  },
  setMediaType(state, newType) {
    Vue.set(state, "mediaType", newType);
  },
  setMediaLink(state, newLink) {
    Vue.set(state, "mediaLink", newLink);
  },
  setTempFileObject(
    state,
    { storageFor, original_filename, s3_filename, file_type }
  ) {
    Vue.set(state.tempUploadFile, storageFor, {
      original_filename,
      s3_filename,
      file_type
    });
  }
};
export const actions = {
  limitAccess({ commit }, access) {
    commit("updateLimitedAccess", access);
  },
  toggleExpand({ commit }, divFloat) {
    commit("mutateDiv", divFloat);
  },
  toggleTabletExpand({ commit }, divFloat) {
    commit("mutateTabletDiv", divFloat);
  },
  remoteToggleAppLoading({ commit }, trueOrFalse) {
    commit("toggleAppLoading", trueOrFalse);
  },
  remoteUpdateWindowWidth({ commit }, newWidth) {
    commit("updateWindowWidth", newWidth);
  },
  remoteUpdateWindowHeight({ commit }, newHeight) {
    commit("updateWindowHeight", newHeight);
  },
  remoteUpdateReportScrollTop({ commit }, newValue) {
    commit("updateReportScrollTop", newValue);
  },
  remoteIncreaseResizeCount({ commit, state }) {
    const newCount = state.resizeCount + 1;
    commit("updateResizeCount", newCount);
  },
  async remoteUploadToS3PresignedUrl(
    cntxt,
    { textToUpload, presignedUrl, presignedFields, fileType, fullBlob }
  ) {
    if (!fileType || !presignedFields || !presignedFields)
      return {
        action: "error",
        message: "parameters not met"
      };
    if (!fullBlob && !textToUpload)
      return {
        action: "error",
        message: "no blob"
      };
    if (!fullBlob)
      fullBlob = new Blob([textToUpload], {
        type: fileType
      });
    const formData = new FormData();
    for (const tmpKey in presignedFields) {
      formData.append(tmpKey, presignedFields[tmpKey]);
    }
    formData.append("file", fullBlob);
    try {
      // try to upload the file to s3 using fetch to it does not include token header ... etc
      const uploadReportToS3 = await fetch(presignedUrl, {
        method: "POST",
        body: formData
      });
      if (uploadReportToS3.ok)
        return {
          action: "accept",
          message: "uploaded"
        };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e
      };
    }
    return {
      action: "error",
      message: "did not upload"
    };
  },
  remoteSetDevicePixelRatio({ commit }) {
    const pr = window.devicePixelRatio;
    const prString = (pr * 100).toFixed(2);
    commit("updateDevicePixelRatio", prString);
  },
  remoteUploadFiles(
    { commit, rootState, dispatch },
    { linkToId, linkToType, referenceGroup, fileNames }
  ) {
    if (fileNames.length === 0)
      return {
        action: "error",
        message: "no files"
      };
    // let formData = new FormData();
    // let type = "";
    // if (file && file["type"].split("/")[0]) {
    //   type = file["type"].split("/")[0];
    // }

    // if (file) formData.append("notification_file", file);
    // if (type) formData.append("notification_file_type", type);
    // if (title) formData.append("notification_file_title", title || "");
    const payload = {
      cdn_enable: true,
      link_to: linkToId,
      link_to_type: linkToType,
      reference_group: referenceGroup,
      upload_file_count: fileNames.length,
      upload_file_list: fileNames
    };
    return this.$axios
      .$post(
        `/v2/reference_file/get_upload_urls/${rootState.user.selectedShownEntity}`,
        payload
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  async remoteUploadPresignedUrl(
    cntxt,
    { textToUpload, presignedUrl, presignedFields, fileType, fullBlob }
  ) {
    if (!fileType || !presignedFields || !presignedFields)
      return {
        action: "error",
        message: "parameters not met"
      };
    if (!fullBlob && !textToUpload) return;
    if (!fullBlob)
      fullBlob = new Blob([textToUpload], {
        type: fileType
      });
    const formData = new FormData();
    for (const tmpKey in presignedFields) {
      formData.append(tmpKey, presignedFields[tmpKey]);
    }
    formData.append("file", fullBlob);

    try {
      // const uploadReportToS3 = await
      fetch(presignedUrl, {
        method: "POST",
        body: formData
      });
      return {
        action: "uploading"
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e
      };
    }
  },
  remoteStartUploadMultiPart({ rootState }, payload) {
    return this.$axios
      .$post(
        `/v2/reference_file/mul_parts_upload_urls/${rootState.user.selectedShownEntity}`,
        payload
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  async remoteUploadMultiPart(cntxt, { fileBlob, presignedUrl }) {
    try {
      const uploadReportToS3 = await fetch(presignedUrl, {
        method: "PUT",
        body: fileBlob
      });
      if (uploadReportToS3.ok)
        return {
          action: "uploaded",
          message: {
            ETag: uploadReportToS3.headers.get("ETag").replaceAll('"', "")
          }
        };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e
      };
    }
  },
  async remoteCompleteUploadMultiPart({ rootState }, payload) {
    return this.$axios
      .$put(
        `/v2/reference_file/mul_parts_upload_urls/${rootState.user.selectedShownEntity}`,
        payload
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteCheckUploadFiles({ rootState }, batchId) {
    if (batchId.length === 0)
      return {
        action: "error",
        message: "no batch id"
      };
    return this.$axios
      .$get(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/batch_id/${batchId}`
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUploadReportImage({ rootState }, { blob, fileName, linkToId }) {
    if (!blob)
      return {
        action: "error",
        message: "no files"
      };
    const formData = new FormData();
    formData.append("upload_image_file", blob, fileName);
    const params = {
      original_filename: fileName,
      link_to: linkToId
    };
    return this.$axios
      .$post(
        `/v2/other/image_cdn/${rootState.user.selectedShownEntity}/upload`,
        formData,
        {
          params: params
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteRotateReportImage({ rootState }, { imageId, rotateDegree }) {
    if (!imageId)
      return {
        action: "error",
        message: "no image id"
      };
    const params = {
      image_id: imageId,
      rotate_degree: rotateDegree
    };
    return this.$axios
      .$post(
        `/v2/other/image_cdn/${rootState.user.selectedShownEntity}/rotate`,
        params
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteLoadPageInCenterPopup(ctx, { popupW, popupH, popupUrl }) {
    // working out position of popup
    try {
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const popupLeft = (width - popupW) / 2 / systemZoom + dualScreenLeft;
      const popupTop = (height - popupH) / 2 / systemZoom + dualScreenTop;
      // ===
      return window.open(
        popupUrl,
        "_blank",
        `
          scrollbars=yes,
          width=${popupW / systemZoom},
          height=${popupH / systemZoom},
          top=${popupTop},
          left=${popupLeft},
          location=no
          `
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  async remoteUploadFileToTempStorage(
    { commit, dispatch, rootGetters },
    { storageFor, original_filename, file_type, fullBlob }
  ) {
    //use this to store tmp files on s3 against the member
    // NOTE only allow to store storing against self at the moment
    if (!original_filename || !file_type) throw new Error("no file info");
    const entity_id = rootGetters["user/currentEntity"].entity_id || null;
    if (!entity_id) throw new Error("no selected entity");
    const member_id = rootGetters["user/currentEntity"].member_id || null;
    if (!member_id) throw new Error("no selected member");
    const resp = await this.$axios.$get(
      `/v2/temp_storage/entity/${entity_id}/member/${member_id}/extension/${file_type}/upload_url`
    );
    if (
      !resp.s3_filename ||
      !resp.upload_presigned_url ||
      !resp.upload_presigned_url.url ||
      !resp.upload_presigned_url.fields
    )
      throw new Error("invalid response");
    // can probably use a map instead but for now just have 1 object as temp
    commit("setTempFileObject", {
      storageFor,
      original_filename,
      s3_filename: resp.s3_filename,
      file_type
    });
    const uploadFile = await dispatch("remoteUploadToS3PresignedUrl", {
      fileType: file_type,
      fullBlob,
      presignedFields: resp.upload_presigned_url.fields,
      presignedUrl: resp.upload_presigned_url.url
    });
    if (!uploadFile || !uploadFile.action)
      throw new Error("could not upload temp file");
    if (uploadFile.action == "error") throw new Error(uploadFile.message);
    return true;
  },
  async remoteMoveTempStorageFile(
    { state, rootState, rootGetters },
    { copy_option, storageFor, new_storage_name }
  ) {
    if (!storageFor) throw new Error("did not provide storage name");
    if (!copy_option) throw new Error("did not provide copy option");
    const storedInfo = state.tempUploadFile[storageFor];
    if (!storedInfo)
      return {
        message: "do not need upload",
        result: false
      }; // assume no model uploaded directly
    if (!storedInfo.s3_filename) throw new Error("did not store temp name");
    if (!storedInfo.file_type) throw new Error("did not store file type");
    const member_id = rootGetters["user/currentEntity"].member_id;
    if (!member_id) throw new Error("no member id");
    const entity_id = rootState.user.selectedShownEntity;
    return await this.$axios.$post(
      `/v2/temp_storage/entity/${entity_id}/member/${member_id}/copy_file`,
      {
        copy_option,
        s3_filename: storedInfo.s3_filename,
        target_s3_key: `${new_storage_name}.${storedInfo.file_type}`,
        entity_id,
        member_id
      }
    );
  },
  async getReferenceFileContent({ rootState }, reference_id) {
    try {
      const res = await this.$axios.$get(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/reference/${reference_id}`
      );
      if (res) {
        return {
          action: "accept",
          message: res
        };
      }
    } catch (error) {
      console.error(error);
      return {
        action: "error",
        message: error.data ? error.data.message : error
      };
    }
  }
};

import Vue from "vue";

export const state = () => ({
    memberCompanyList: [],
});
export const getters = {};
export const mutations = {
    //Member list update after getting members from backend

    //Change order of companies in member page
    changeOrderCompanyList(state, id) {
        let index = 0;
        for (var i = 0; i < state.memberCompanyList.length; i++) {
            if (state.memberCompanyList[i].id == id) {
                index = i;
                break;
            }
        }
        if (index) {
            let rows = [
                state.memberCompanyList[index - 1],
                state.memberCompanyList[index],
            ];
            state.memberCompanyList.splice(index - 1, 2, rows[1], rows[0]);
            //   Vue.set(
            //     state,
            //     "memberCompanyList",
            //     state.memberCompanyList.splice(index - 1, 2, rows[1], rows[0])
            //   );
        }
    },

    removeMemberCompany(state, companyId) {
        var array = state.memberCompanyList.filter((it) => {
            return it.id != companyId;
        });
        Vue.set(state, "memberCompanyList", array);
    },
    //Get member company list
    updateMemberCompanyList(state, arrayList) {
        // var tempArr = [];
        // for (var object of arrayList) {
        //     var tempObj = {
        //         id: object["member_company_id"],
        //         value: object["member_company_name"],
        //         order: object["member_company_order"],
        //         isGlobal: object["member_company_global_flag"],
        //     };
        //     tempArr.push(tempObj);
        // }
        Vue.set(state, "memberCompanyList", arrayList);
    },

    updateMemberCompanyOrder(state, details) {
        state.memberCompanyList.map((object) => {
            if (object.id == details.id) {
                object.order = details.order;
                return;
            }
        });
    },
    // updateMemberCompany(state, details) {
    //     details.memberCompany.value = details.value;
    // },
    appendMemberCompanyList(state, arrayList) {
        var tempArr = [];
        arrayList.map((object) => {
            var tempObj = {
                id: object.member_company_id,
                value: object.member_company_name,
                order: object.member_company_order,
                isGlobal: object.member_company_global_flag,
            };
            tempArr.push(tempObj);
        });
        Vue.set(
            state,
            "memberCompanyList",
            state.memberCompanyList.concat(tempArr)
        );
    },
};
export const actions = {
    getMemberCompanies({
        commit,
        rootState
    }) {
        return this.$axios
            .$get(`/v1/member_company/${rootState.user.selectedShownEntity}`)
            .then((r) => {
                commit("updateMemberCompanyList", r);
                return true;
            })
            .catch((e) => {
                console.error(e);
                return false;
            });
    },

    createMemberCompany({
        rootState
    }, memberCompany) {
        this.$axios.setHeader("Content-Type", "application/json");
        return this.$axios
            .$post(
                `/v1/member_company/${rootState.user.selectedShownEntity}`,
                memberCompany
            )
            .then((r) => {
                return r;
            })
            .catch((e) => {
                console.error(e);
                // return false;
                throw e;
            });
    },
    updateMemberCompany({
        rootState
    }, memberCompanies) {
        let payload = {
            member_companies: memberCompanies
        };
        this.$axios.setHeader("Content-Type", "application/json");
        return this.$axios
            .$put(`/v1/member_company/${rootState.user.selectedShownEntity}`, payload)
            .then((r) => {
                return true;
            })
            .catch((e) => {
                console.error(e);
                // return false;
                throw e;
            });
    },
    deleteMemberCompany({
        rootState
    }, memberCompanyId) {
        this.$axios.setHeader("Content-Type", "application/json");
        return this.$axios
            .$delete(
                `/v1/member_company/${rootState.user.selectedShownEntity}/${memberCompanyId}`
            )
            .then((r) => {
                return true;
            })
            .catch((e) => {
                // console.error(e);
                throw e;
            });
    }
};
<template>
  <b-button
    pill
    class="button-b"
    @click="$emit('cancel-click')"
    size="sm"
    variant="outline-secondary"
    >{{ label }}</b-button
  >
</template>

<script>
export default {
  props: ["label", "mobile"],
};
</script>

<style scoped>
.button-b {
  font-size: 1em;
  border-color: rgb(175, 175, 175);
  color: #6c757d !important;
  background-color: transparent !important;
}
.button-b:hover {
  background-color: rgb(230, 235, 240, 0.5);
}
</style>

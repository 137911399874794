<template>
  <div id="layout-home">
    <HomeLoader :active="!contentReady" />

    <div id="blue-bg-div"></div>
    <div class="light" :class="{ active: lightVisible }"></div>

    <HomeMenu @goHome="goHome" />

    <div id="content">
      <transition name="page" mode="out-in">
        <nuxt :key="$route.path" />
      </transition>
    </div>

    <HomeFooter @goHome="goHome" />

    <ScrollUpButton
      :active="isOnBottom && $route.path == '/'"
      @goHome="goHome"
    />
    <NotificationCenter />
    <SiteIEError />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import screenSize from "~/mixins/screenSize";
import NotificationCenter from "~/components/new/Notifications/NotificationCenter";
import useContentful from "~/utilities/contentful";
import HomeLoader from "~/components/new/Layout/HomeLoader";
import HomeMenu from "~/components/new/Layout/HomeMenu";
import HomeFooter from "~/components/new/Layout/HomeFooter";
import ScrollUpButton from "~/components/new/Layout/ScrollUpButton";
import SiteIEError from "~/components/new/Site/SiteIEError";

export default {
  components: {
    NotificationCenter,
    HomeLoader,
    HomeMenu,
    HomeFooter,
    ScrollUpButton,
    SiteIEError
  },

  mixins: [screenSize],

  data: () => ({
    lightVisible: false,
    isOnBottom: false
  }),

  async fetch() {
    const contentQuery = useContentful();
    this.initCustomLayout();
    const content = await contentQuery;
    this.setContent(content);
  },

  computed: {
    ...mapGetters({
      userLoggedIn: "user/userLoggedIn",
      contentReady: "contentful/ready"
    })
  },

  watch: {
    $route() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.checkLight();
    }
  },

  async mounted() {
    window.addEventListener("scroll", this.checkScroll);
    await new Promise((resolve) => setTimeout(resolve, 500));
    this.checkLight();
  },

  destroyed() {
    window.removeEventListener("scroll", this.checkScroll);
  },

  methods: {
    ...mapMutations({
      setContent: "contentful/setContent",
      setEntityLayoutId: "website/setEntityLayoutId"
    }),

    checkScroll() {
      this.isOnBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
    },

    checkLight() {
      this.lightVisible = this.$route.path === "/";
    },

    async initCustomLayout() {
      // Set layout from query params
      const params = new URLSearchParams(this.$route.query);
      const entityId = params.get("entity_id");

      if (entityId) {
        this.setEntityLayoutId(entityId);
      }

      return false;
    },

    goHome() {
      if (this.$route.name === "index") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (this.userLoggedIn) {
        if (this.$route.name === "entities") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          this.$router.push("/entities/");
        }
      } else {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style lang="sass">
@import "@/components/new/style.sass"
</style>

<style lang="sass" scoped>
.page-enter-active,
.page-leave-active
  transition: all 0.35s ease-in-out
.page-enter
  transform: translateX(50vw)
  opacity: 0
.page-leave-to
  transform: translateX(-50vw)
  opacity: 0


#layout-home
  max-width: 100%
  overflow-x: hidden
  box-sizing: border-box
  min-height: 100vh
  --nav-height: 80px
  position: relative
  background-color: rgba(var(--color-background), 1) !important
  background-image: url("~/assets/images/bg_home.png")
  background-size: cover
  background-position: center
  background-attachment: fixed

.light
  background: radial-gradient(circle at center, rgba(255,255,255,.07) 0, rgba(255,255,255,.0) 70%)
  height: 100vh
  width: 100vh
  position: absolute
  top: 0vh
  left: -20vh
  z-index: 0
  transition: all 0.6s ease-in-out
  transform: scale(0)
  opacity: 0
  &.active
    transform: scale(1)
    opacity: 1

#content
  padding-top: var(--nav-height)
  padding-bottom: 100px
  min-height: 100vh
  width: 100%
  box-sizing: border-box
</style>

<template>
  <transition name="site-loader" mode="out-in">
    <div v-if="active" class="site-loader">
      <div class="loader"></div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped lang="sass">
.site-loader
  width: 100vw
  height: 100vh
  background-color: rgba(var(--color-background), 1)
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  top: 0
  left: 0
  z-index: 2080
  .loader
    width: 48px
    height: 48px
    border-radius: 50%
    position: relative
    animation: rotate 1s linear infinite

  .loader::before , .loader::after
    content: ""
    box-sizing: border-box
    position: absolute
    inset: 0px
    border-radius: 50%
    border: 5px solid #FFF
    animation: prixClipFix 2s linear infinite

  .loader::after
    inset: 8px
    transform: rotate3d(90, 90, 0, 180deg )
    border-color: rgba(var(--color-primary), 1)

@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)


@keyframes prixClipFix
    0%
      clip-path: polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)
    50%
      clip-path: polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)
    75%, 100%
      clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)

.site-loader-enter-active
  transition: all .45s ease-in-out
.site-loader-leave-active
  transition: all .45s ease-in-out
  transition-delay: .3s

.site-loader-enter
  opacity: 0

.site-loader-leave-to
  opacity: 0
</style>

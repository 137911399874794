import { COLORS } from "@/constants/colors";

export const ChartHelpers = {
  DisableTooltips: { enabled: false },
  DisableDataLabelsPlugin: { datalabels: { display: false } },
  AxisStyles: {
    FontSizing: 10,
    FontColor: "rgba(0, 0, 0, 0.4)"
  },
  TooltipStyling: {
    BodyFontSize: 10,
    TitleFontSize: 10,
    CornerRadius: 3,
    Padding: 10,
    BodySpacing: 8,
    BodyAlign: "right",
    TitleFontStyle: "normal",
    TitleAlign: "right"
  },
  // Tool tip styling full options are here https://www.chartjs.org/docs/latest/configuration/tooltip.html
  CustomTooltip: (tooltip) => {
    if (!tooltip) return;
    tooltip.displayColors = false;
    tooltip.titleSpacing = 0;
    tooltip.titleMarginBottom = 4;
    tooltip.yPadding = ChartHelpers.TooltipStyling.Padding;
    // tooltip.xPadding = ChartHelpers.TooltipStyling.Padding;
    tooltip.titleFontSize = ChartHelpers.TooltipStyling.TitleFontSize;
    tooltip.bodyFontSize = ChartHelpers.TooltipStyling.BodyFontSize;
    tooltip._titleAlign = ChartHelpers.TooltipStyling.TitleAlign;
    tooltip._titleFontStyle = ChartHelpers.TooltipStyling.TitleFontStyle;
    tooltip._bodyAlign = ChartHelpers.TooltipStyling.BodyAlign;
    tooltip.bodySpacing = 4;
    tooltip.cornerRadius = ChartHelpers.TooltipStyling.CornerRadius;
  }
};

export function GetXAxisToRenderFirstAndLastElementsOnly() {
  return {
    gridLines: {
      display: false,
      drawBorder: false
    },
    scaleLabel: {
      display: true,
      labelString: ""
    },
    offset: false,
    ticks: {
      callback: function (value, index, values) {
        if (index == 0 || index == values.length - 1) {
          if (value.toLowerCase() === "invalid date") {
            return "";
          } else {
            return value;
          }
        } else {
          return "";
        }
      },
      align: "end",
      autoSkip: false,
      maxRotation: 0,
      minRotation: 0,
      fontSize: ChartHelpers.AxisStyles.FontSizing,
      source: "labels",
      fontColor: ChartHelpers.AxisStyles.FontColor
    }
  };
}
export function LimitChartToolTipCharacters(label) {
  if (label.length > 22) {
    return label.substring(0, 20) + "...";
  }
  return label;
}

export function EngagementTooltips() {
  return {
    intersect: true,
    custom: ChartHelpers.CustomTooltip,
    callbacks: {
      title: (tooltipItems, data) => {
        let label = data.labels[tooltipItems[0].index];
        // Tooltips to render nicely for the exchange labels
        if (label.includes("Exchange")) {
          label = label.replace(" Exchange", "\nExchange");
        }
        return label;
      },
      label: (tooltipItem, data) => {
        if (data.datasets.length > 0) {
          const datasets = data.datasets[0];
          if (datasets.data.length >= tooltipItem.index) {
            return datasets.data[tooltipItem.index] + "%";
          }
        }
        return "";
      }
    },
    bodyAlign: "center"
  };
}

export function EngagementBySecurityDataLabels() {
  return {
    backgroundColor: COLORS.CHARTS.FOURTH,
    borderRadius: 4,
    clamp: true,
    align: "start",
    anchor: "start",
    textAlign: ChartHelpers.TooltipStyling.BodyAlign,
    color: "#fff",
    font: {
      weight: "bold",
      size: ChartHelpers.TooltipStyling.BodyFontSize
    },
    padding: 4,
    formatter: function (value, context) {
      return LimitChartToolTipCharacters(context.dataset.label);
    }
  };
}

/*
    First implemented as #6614
    Jarden wants their analyst to write a notification summary before they can submit a change.
    This was added on the 16th June 2023 and limited to Jarden, fuctions are placed in this file to ensure this functionality is clean

 */

export function NeedsAMandatoryNotificationSummaryCheck(
  isALockedDownTemplateType,
  notificationSummaryText
) {
  if (isALockedDownTemplateType === true) {
    if (
      notificationSummaryText == null ||
      notificationSummaryText.length == 0
    ) {
      return true;
    }

    if (notificationSummaryText == " ") {
      return false;
    }
  }
  return false;
}

import { render, staticRenderFns } from "./HomeLoader.vue?vue&type=template&id=3bd74e79&scoped=true"
import script from "./HomeLoader.vue?vue&type=script&lang=js"
export * from "./HomeLoader.vue?vue&type=script&lang=js"
import style0 from "./HomeLoader.vue?vue&type=style&index=0&id=3bd74e79&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd74e79",
  null
  
)

export default component.exports
export default async function ({ store, redirect, app, route }) {
  // if user has come through from invite email
  if (app.$cookies.get("tmp_user_invite_token")) {
    // entities had been put into seperate page and it is this page that that deal with the token
    if (route.name !== "entities") redirect("/");
  }
  // If the user has not select entity

  if (!store.state.user.selectedShownEntity) {
    const selectedEntity = app.$cookies.get("auth.selected_entity");
    if (selectedEntity) {
      await store.dispatch("user/remoteSelectShownEntity", selectedEntity);
      if (store.getters["user/isViewer"]) {
        if (
          store.getters["entity_configs/eurozhartleys/shouldUseEurozLayout"] ||
          store.getters["entity_configs/genium/shouldUseGeniumLayout"]
        ) {
          // adjust layout for Euroz, custom for them for now
          const EUROZ_NON_VIEWER_ROUTE = [
            "member",
            "memberlist",
            "change",
            "changelist",
            "security",
            "securitylist",
            "rgpt"
          ];
          if (EUROZ_NON_VIEWER_ROUTE.includes(route.name)) {
            redirect("/publicationlist/");
            return;
          }
        }
        if (route.name == "member" || route.name == "memberlist") {
          return store
            .dispatch("app_store/limitAccess", "member")
            .then((value) => {
              redirect("/securitylist/");
            });
        }
        if (route.name == "change" || route.name == "changelist") {
          // store.dispatch("user/remoteSelectShownEntity", selectedEntity); NOTE:// this was already done above?
          return store
            .dispatch("app_store/limitAccess", "change")
            .then((value) => {
              redirect("/securitylist/");
            });
        }
      }
      if (store.getters["user/isEditor"]) {
        if (route.name == "member" || route.name == "memberlist") {
          return store
            .dispatch("app_store/limitAccess", "member")
            .then((value) => {
              redirect("/securitylist/");
            });
        }
      }
      if (store.getters["user/isMemberEditor"]) {
        if (route.name == "change" || route.name == "changelist") {
          return store
            .dispatch("app_store/limitAccess", "change")
            .then((value) => {
              redirect("/securitylist/");
            });
        }
      }
      if (!store.getters["user/isAuthorised"]("access_ai_environment", true)) {
        if (route.name == "rgpt") {
          return store
            .dispatch("app_store/limitAccess", "rgpt")
            .then((value) => {
              redirect("/securitylist/");
            });
        }
      }
    } else redirect("/");
  }
}

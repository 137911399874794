<template>
  <transition mode="out-in" name="up-button">
    <div class="up-button" v-if="active">
      <div class="wrapper">
        <div class="button" @click="$emit('goHome')" v-ripple="20">
          <i class="mi-chevron-up"></i>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
.up-button-enter-active,
.up-button-leave-active
  transition: all 0.35s ease-in-out
.up-button-enter
  transform: translateY(50vh)
  opacity: 0
.up-button-leave-to
  transform: translateY(50vh)
  opacity: 0

.up-button
  position: fixed
  width: 100vw
  height: 100vh
  z-index: 9
  top: 0
  left: 0
  pointer-events: none
  @media (max-width: 768px)
    display: none
  > .wrapper
    position: relative
    pointer-events: none
    position: absolute
    bottom: 20px
    right: 20px
    > .button
      width: 60px
      height: 60px
      border-radius: 50%
      background-color: rgba(var(--color-primary), 1)
      box-shadow: 0 5px 50px -15px rgba(0,0,0,0.1)
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer

      pointer-events: all
      i
        font-size: 20px
        color: #fff
        text-align: center
</style>

import Vue from "vue";

const options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.5
};

const directive = {
  inserted: (element, binding) => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        const event = new CustomEvent("intersection-change", {
          detail: {
            isIntersecting: entry.isIntersecting
          }
        });
        entry.target.dispatchEvent(event);
      });
    };

    const observer = new IntersectionObserver(callback, options);

    const eventHandler = (event, eventBinding) => {
      eventBinding.value
        ? eventBinding.value(event.detail.isIntersecting)
        : eventBinding(event.detail.isIntersecting);
    };

    element.addEventListener("intersection-change", (e) =>
      eventHandler(e, binding)
    );
    observer.observe(element);
  },

  unbind(element, binding) {
    const callback = (entries) => {
      entries.forEach((entry) => {
        const event = new CustomEvent("intersection-change", {
          detail: {
            isIntersecting: entry.isIntersecting
          }
        });
        entry.target.dispatchEvent(event);
      });
    };

    const observer = new IntersectionObserver(callback, options);

    const eventHandler = (event, eventBinding) => {
      eventBinding.value(event.detail.isIntersecting);
    };

    element.removeEventListener("intersection-change", (e) =>
      eventHandler(e, binding)
    );
    observer.disconnect(element);
  }
};

Vue.directive("intersection", directive);

<template>
  <!-- Our popover title and content render container -->
  <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
  <!-- We specify the same container as the trigger button, so that popover is close to button -->
  <b-popover
    ref="popover"
    :target="targetButtonId"
    :triggers="trigger"
    :show.sync="popoverShow"
    :placement="position"
    :container="containerId"
    custom-class="popover-a popover-a-small"
    @show="onShow"
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #title>
      <b-button
        v-if="isDismissAble"
        class="close"
        aria-label="Close"
        @click="onClose"
      >
        <span class="d-inline-block" aria-hidden="true">&times;</span>
      </b-button>
      {{ title }}
    </template>
    <div>
      <b-row class="mx-0 mb-3">
        <b-col class="px-1">{{ description }}</b-col>
      </b-row>
    </div>
  </b-popover>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    containerId: {
      type: String,
      required: true
    },
    targetButtonId: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: "auto"
    },
    trigger: {
      type: String,
      default: "click"
    },
    isDismissAble: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      //   description: "",
      popoverShow: false
    };
  },
  computed: {
    ...mapState({
      appLoading: (state) => state.app_store.appLoading,
      member: (state) => state.member.currentMember
    })
  },
  methods: {
    onKeyDown(evt) {
      if (evt.keyCode == 27) {
        evt.preventDefault();
        evt.stopPropagation();
        this.onClose();
      }
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      const element = document.createElement("div");
      element.setAttribute("class", "popover-semi-b");
      element.addEventListener("mousedown", this.onMouseDown);
      document.querySelector("body").append(element);
    },
    onClose() {
      this.popoverShow = false;
    },
    onMouseDown(e) {
      //When popover background clicked
      if (this.isDismissAble) {
        this.popoverShow = false;
      }
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      document.addEventListener("keydown", this.onKeyDown);
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      const popoverSemi = document.querySelector(".popover-semi-b");
      if (popoverSemi) {
        popoverSemi.removeEventListener("mousedown", this.onClose);
        popoverSemi.remove();
      }
      this.$root.$emit("bv::hide::popover", this.targetButtonId);
      document.removeEventListener("keydown", this.onKeyDown);
    },
    show() {
      this.popoverShow = true;
    }
  }
};
</script>

import Vue from "vue";

export const state = () => ({
  mimetype: "",
  mediaSrc: "",
  branding: {},
  reportPdfs: [],
  currentAudioFile: {},
  showAudioPlayer: false,
  audioLoading: false
});
export const mutations = {
  setMimeType(state, type) {
    Vue.set(state, "mimetype", type);
  },
  setMediaSource(state, source) {
    Vue.set(state, "mediaSrc", source);
  },
  setBranding(state, branding) {
    Vue.set(state, "branding", branding);
  },
  setReportPdfs(state, newReports) {
    Vue.set(state, "reportPdfs", newReports);
  },
  setCurrentAudioFile(state, { id, url }) {
    Vue.set(state, "currentAudioFile", {
      id,
      url
    });
  },
  toggleAudioPlayer(state, trueOfFalse) {
    Vue.set(state, "showAudioPlayer", trueOfFalse);
  },
  toggleAudioLoading(state, trueOfFalse) {
    Vue.set(state, "audioLoading", trueOfFalse);
  }
};

export const getters = {
  isDocument: (state) => {
    const DOCUMENT_TYPE = new Set(["application/pdf"]);
    return DOCUMENT_TYPE.has(state.mimetype);
  },
  isAudio: (state) => {
    const DOCUMENT_TYPE = new Set([
      "audio/mpeg",
      "audio/aac",
      "audio/wav",
      "audio/webm"
    ]);
    return DOCUMENT_TYPE.has(state.mimetype);
  },
  isVideo: (state) => {
    const DOCUMENT_TYPE = new Set([
      "video/mpeg",
      "video/mp4",
      "video/webm",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-ms-wmv",
      "video/3gpp"
    ]);
    return DOCUMENT_TYPE.has(state.mimetype);
  },
  canPlayAudio: (state) =>
    !!(
      state.currentAudioFile &&
      state.currentAudioFile.id &&
      state.currentAudioFile.url
    ),
  shouldShowPlayTTVButton: (state, getters) =>
    state.showAudioPlayer && getters.canPlayAudio
};

export const actions = {
  async remoteGetFileMapping(
    { commit, rootState, rootGetters },
    { file_id, member_id, browser_token, is_post }
  ) {
    const session_id = rootGetters["app_store/uuidv4"]();
    let r;
    try {
      if (is_post === true) {
        r = await this.$axios.$post(
          `/v1/download-url/${file_id}/${session_id}`,
          {
            browser_token: browser_token,
            member_id: member_id
          }
        );
      } else {
        r = await this.$axios.$get(`/v1/download-url/${file_id}/${session_id}`);
      }

      if (!r.url_text) return false;
      commit("setMediaSource", r.url_text);
      const mimetype = r.mimetype.trim();
      if (r.mimetype && r.mimetype === "pdf")
        commit("setMimeType", "application/pdf");
      else if (r.mimetype && r.mimetype === "csv")
        commit("setMimeType", "text/csv");
      else commit("setMediaType", "application/pdf"); // default to pdf
      if (r.report_branding && r.report_branding.length > 0) {
        commit("setBranding", r.report_branding[0]);
      }
      if (r.reports) {
        commit("setReportPdfs", r.reports);
        const currentReport = r.reports.find(
          (tmpReport) => tmpReport.current_change
        );
        if (!currentReport) return false;
        const { entity_id, security_report_id, change_published_at } =
          currentReport;
        if (member_id && r.token && rootState.webworkers.analyticsWorker) {
          // set the token for ranos analytics
          rootState.webworkers.analyticsWorker.postMessage({
            action: "set credentials",
            init: true,
            token: r.token,
            session_id,
            member_id,
            entity_id,
            security_report_id,
            base_url: this.$axios.defaults.baseURL,
            testing: change_published_at ? false : true
          });
        }
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  remoteSetValuationReports({ commit }, newReports) {
    commit("setReportPdfs", newReports);
  },
  async remoteRefreshAnalyticsToken(
    { state, rootState, rootGetters },
    { file_id }
  ) {
    if (!rootState.webworkers.analyticsWorker)
      return Promise.reject("no worker found");
    const session_id = rootGetters["app_store/uuidv4"]();
    let newTokenObj;
    try {
      newTokenObj = await this.$axios.$get(
        `/v2/refresh-analytics-token/${file_id}/${session_id}`
      );
      if (!newTokenObj.token) return Promise.reject("cannot get token");
    } catch (e) {
      console.error(e);
      return Promise.reject("cannot get token");
    }
    const currentReport = state.reportPdfs.find(
      (tmpReport) => tmpReport.current_change
    );
    if (!currentReport) return Promise.reject("no current report");
    const { entity_id, security_report_id, change_published_at } =
      currentReport;
    // set the token for ranos analytics
    if (newTokenObj != null && newTokenObj.token) {
      rootState.webworkers.analyticsWorker.postMessage({
        action: "refresh token",
        init: true,
        token: newTokenObj.token,
        session_id,
        entity_id,
        security_report_id,
        testing: change_published_at ? false : true
      });
    }
  },
  async downloadReportsForMobileFeedItem(
    { rootState },
    { security_report_id, security_id }
  ) {
    try {
      const response = await this.$axios.$get(
        `/v1/download-url/scroll-reports-protected/entity_id/${rootState.user.selectedShownEntity}/security_report_id/${security_report_id}/security_id/${security_id}`
      );
      return {
        action: "accept",
        message: response
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.data ? e.data.message : e
      };
    }
  },
  async remoteGetTTVDownload({ commit, rootState }, { id, section }) {
    // NOTE: just for MVP, will need to change later
    let section_to_api;
    switch (section) {
      case "change":
        section_to_api = "change";
        break;
      default:
        return { status: 400, message: "not implemented" };
    }
    try {
      const res = await this.$axios.$get(
        `/v2/report/${rootState.user.selectedShownEntity}/${section_to_api}/${id}/ttv`,
        { progress: false }
      );
      /*
      commit("setCurrentAudioFile", {
        id: id,
        url: "https://cdn.dev.ranos.io/ttv/ae319a65-2a7b-448e-a9cf-5da3079bba25/90d63179-1e5e-4b34-97bc-a02c47e8a58a.mp3"
      });
      return { status: 200, url: res.download_url, message: "audio ready" };
      */
      if (!res.status || res.status !== "ready") {
        return { status: 202, message: "TTV not ready" };
      }
      if (!res.download_url) {
        return { status: 500, message: "No download url generated" };
      }
      commit("setCurrentAudioFile", {
        id: id,
        url: res.download_url,
        ttv_id: res.ttv_id
      });
      return {
        status: 200,
        url: res.download_url,
        ttv_id: res.ttv_id,
        message: "audio ready"
      };
    } catch (e) {
      console.error(e);
      throw e.response;
    }
  },
  async displayAudioPlayer(
    { state, commit, dispatch, rootState },
    { entity_id, id, section }
  ) {
    if (!entity_id && !rootState.user.selectedShownEntity) {
      return {
        message: "An unknown error has occured.",
        action: "error"
      };
    }
    if (entity_id) {
      // for report page, use is not logged itn so has to set this manually
      commit("user/selectShownEntity", entity_id, { root: true });
    }
    if (!id || id == "undefined") {
      return {
        message: "The voice file for this report is not available.",
        action: "error"
      };
    }
    // if already loaded and playing the audio, will keep playing if change report but will need to reload to the new change report when the button is clicked
    if (state.showAudioPlayer) {
      if (state.currentAudioFile.id === id) {
        // if already shown just hide it,  dont need to run apis
        commit("toggleAudioPlayer", false);
        commit("toggleAudioLoading", false);
        return {
          message: "player is now hidden.",
          action: "hide"
        };
      } else {
        // if its a different change need to reload the player
        commit("setCurrentAudioFile", {});
      }
    }
    // dont do anything if already loading
    if (state.audioLoading)
      return {
        action: "generating",
        message: "still generating"
      };
    let need_generate = false;
    let ttv_row;
    commit("toggleAudioLoading", true);
    try {
      ttv_row = await dispatch("remoteGetTTVDownload", {
        id,
        section
      });
    } catch (e) {
      switch (e.status) {
        case 404:
          // if not found yet generate it put this outside as the error is different need a different try and catch
          need_generate = true;
          break;
        case 202:
          console.log("still generating...");
          return {
            action: "generating",
            message: "still generating..."
          };
        default:
          console.error(e);
          commit("toggleAudioLoading", false);
          return {
            action: "error",
            message:
              "An unknown error has occurred, please close this page and try again."
          };
      }
    }
    if (need_generate) {
      try {
        // TBI: need a better way to deal with different section
        const generateRes = await dispatch(
          "change/remoteGenerateTTVForChange",
          id,
          { root: true }
        );
        if (generateRes.status != 200 && generateRes.status != 202) {
          return {
            action: "error",
            message:
              "An unknown error has occurred, please close this page and try again."
          };
        }
      } catch (e) {
        console.error(e);
        commit("toggleAudioLoading", false);
        return {
          action: "error",
          message: e.message
        };
      }
    }
    if (!ttv_row || ttv_row.status !== 200) {
      let apiCounter = 0;
      // still waiting so call api in 3 sec to see if finished, will use ws in the future
      const voiceTimer = setInterval(async () => {
        apiCounter++;
        const get_ttv_row = await dispatch("remoteGetTTVDownload", {
          id,
          section
        });
        if ((get_ttv_row && get_ttv_row.status === 200) || apiCounter >= 40) {
          clearInterval(voiceTimer);
          commit("toggleAudioLoading", false);
          return {
            action: "play",
            message: "audio ready"
          };
        }
        if (apiCounter >= 40) {
          clearInterval(voiceTimer);
          commit("toggleAudioLoading", false);
          return {
            action: "error",
            message: "The voice file in unavailable."
          };
        }
      }, 3000);
    } else {
      // already had been generated
      commit("toggleAudioLoading", false);
    }
    commit("toggleAudioPlayer", true);
    return {
      action: "play",
      message: "audio is ready to play"
    };
  },
  async remoteGetTTVTranscript({ rootState, commit }, ttv_id) {
    if (!ttv_id)
      return {
        action: "error",
        message: "need an id"
      };

    try {
      const res = await this.$axios.$get(
        `/v2/ttv/${rootState.user.selectedShownEntity}/${ttv_id}/transcript`
      );
      if (!res)
        return {
          action: "error",
          message: "could not get transcript for this ttv"
        };
      const fetchingTextFile = await fetch(res);
      if (!fetchingTextFile)
        return {
          action: "error",
          message: "failed getting transcript"
        };
      const transcriptText = await fetchingTextFile.text();
      if (!transcriptText)
        return {
          action: "error",
          message: "cannot get the text for the transcript"
        };
      return {
        action: "accept",
        message: transcriptText
      };
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.message
      };
    }
  },
  async remoteUpdateTTVTranscript(
    { rootState, dispatch },
    { new_text, ttv_id }
  ) {
    if (!new_text) {
      return {
        action: "error",
        message: "no transcript text"
      };
    }
    if (!ttv_id) {
      return {
        action: "error",
        message: "no transcript id"
      };
    }
    try {
      const presignedRes = await this.$axios.$patch(
        `/v2/ttv/${rootState.user.selectedShownEntity}/${ttv_id}/transcript`
      );
      if (!presignedRes.url) {
        console.error("no presigned url");
        return {
          action: "error",
          message: "no presigned url"
        };
      }
      if (!presignedRes.fields) {
        console.error("no presigned fields");
        return {
          action: "error",
          message: "no presigned fields"
        };
      }
      await dispatch(
        "app_store/remoteUploadToS3PresignedUrl",
        {
          textToUpload: new_text,
          presignedUrl: presignedRes.url,
          presignedFields: presignedRes.fields,
          fileType: "text/plain"
        },
        {
          root: true
        }
      );
    } catch (e) {
      console.error(e);
      return {
        action: "error",
        message: e.meesage
      };
    }
  }
};

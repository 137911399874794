<template>
  <div id="entity-full-app-container" :key="resizeCount" @click="onMouseDown">
    <div class="entity-app" :style="bodyGradientStyle">
      <div class="entity-header">
        <div class="entity-brand">
          <div
            v-if="
              $route.name === 'entity' && !isLimitedAccess && !isMemberEditor
            "
            id="edit-logo-component"
          >
            <logo-button
              id="edit-logo-reactive"
              :label="'Logo'"
              :url="currentEntityDetail.entity_logo"
            />
            <editlogo-popover
              ref="logoPopover"
              container-id="edit-logo-component"
              target-button-id="edit-logo-reactive"
              :platform-url="currentEntityDetail.entity_logo"
              :report-url="currentEntityDetail.report_logo"
              :notification-url="currentEntityDetail.notification_logo"
              :negative-url="currentEntityDetail.notification_negative_icon"
              :positive-url="currentEntityDetail.notification_positive_icon"
              :background-url="
                currentEntityDetail.entity_metadata &&
                currentEntityDetail.entity_metadata.report_branding_icon
                  ? currentEntityDetail.entity_metadata.report_branding_icon
                  : ''
              "
              @file-added="onFileUploaded"
            />
          </div>
          <div v-else id="edit-logo-component">
            <nuxt-link to="/entity/" class="home-nuxt-link">
              <b-img
                v-if="
                  currentEntityDetail.entity_logo === null ||
                  currentEntityDetail.entity_logo === ''
                "
                src="~/assets/logos/logo_Negative.png"
                alt="img"
                class="ranos-logo"
              />
              <b-img
                v-else
                :src="currentEntityDetail.entity_logo"
                alt="img"
                class="entity-logo"
              ></b-img>
            </nuxt-link>
          </div>
          <p class="card-text py-0">
            <small>
              Viewing
              {{
                currentEntityDetail && currentEntityDetail.entity_name
                  ? currentEntityDetail.entity_name
                  : ""
              }}
              | as {{ $auth.user.user_data.user_name }} | with
              {{
                currentEntityDetail && currentEntityDetail.entity_name
                  ? currentEntity.role_name
                  : ""
              }}
              access
            </small>
          </p>
        </div>
        <div id="entity-nav-container" class="entity-nav">
          <b-nav>
            <b-nav-item
              v-for="(navText, mappingKey) in navMap"
              :id="`nav-item-${mappingKey}`"
              :key="`nav-item-${mappingKey}`"
              :active="getActiveNavTab(mappingKey)"
              :href="hrefLink(mappingKey)"
              @click.ctrl="(event) => onNavItemCtrlClicked(mappingKey, event)"
              @click="(event) => onNavItemClicked(mappingKey, event)"
              >{{ navText }}</b-nav-item
            >
          </b-nav>
          <b-button id="error-button" class="invisible"></b-button>
          <error-popover
            ref="error-popover"
            container-id="entity-nav-container"
            target-button-id="error-button"
            :is-dismiss-able="true"
            :title="errorTitle"
            :description="errorText"
          />
        </div>
        <div class="entity-search">
          <autocomplete-item
            ref="autocompleteItem"
            :dictionary="searchData"
            placeholder="Search"
            @search="onSearch"
          ></autocomplete-item>
          <div class="entity-custom-switch">
            <custom-switch
              ref="custom-switch"
              right-label="Members"
              left-label="Securities"
              :toggle-flag="toggleFlag"
              @switched="onSearchSwitched"
            />
          </div>
        </div>
      </div>
      <nuxt />
    </div>
    <div id="landscape-bg-div">
      <b-img src="~/assets/images/ic_rotate.svg"></b-img>
      <h1>Please rotate your screen</h1>
    </div>
  </div>
</template>

<script>
import { LOGINCOOKIETRIGGERVALUE } from "~/constants/commonConstants.js";
import debounce from "tiny-debounce";
import { mapGetters, mapState } from "vuex";
import CustomSwitch from "~/components/common/CustomSwitch";
import LogoButton from "~/components/common/LogoButton";
import EditLogoPopover from "~/components/popovers/EditLogoPopover";
import ErrorPopover from "~/components/popovers/ErrorPopover";
import AutocompleteItem from "~/components/common/AutocompleteItem";
import WebsocketMixin from "~/mixins/websocketMixin.js";
export default {
  components: {
    "custom-switch": CustomSwitch,
    "logo-button": LogoButton,
    "editlogo-popover": EditLogoPopover,
    "error-popover": ErrorPopover,
    "autocomplete-item": AutocompleteItem
  },
  mixins: [WebsocketMixin],
  middleware: "entityCheck",
  data() {
    return {
      searchInput: "",
      toggleFlag: false,
      logoFile: null,
      searchQuery: "",
      navMap: {
        home: "Home",
        entity: "Entity",
        memberlist: "Members",
        rgpt: "RGPT",
        changelist: "Changes",
        securitylist: "Securities",
        publicationlist: "Publications"
      },
      //   resizeCount: 0,
      errorTitle: "",
      errorText: "",
      isCtrlClicked: false
    };
  },
  computed: {
    getActiveNavTab() {
      return (routeToMatch) => {
        switch (routeToMatch) {
          case "entity":
            return this.$route.name === "entity";
          case "memberlist":
            return (
              this.$route.name === "memberlist" || this.$route.name === "member"
            );
          case "changelist":
            return (
              this.$route.name === "changelist" || this.$route.name === "change"
            );
          case "securitylist":
            return (
              this.$route.name === "securitylist" ||
              this.$route.name === "security"
            );
          case "publicationlist":
            return this.$route.name === "publicationlist";
          case "rgpt":
            return this.$route.name === "rgpt";
          case "capture":
            return this.$route.name === "capture";
          case "capturelist":
            return this.$route.name === "capturelist";
          default:
            break;
        }
      };
    },
    bodyGradientStyle() {
      const tmpObj = {
        background:
          "-webkit-linear-gradient(top,rgb(13, 30, 44),rgb(13, 30, 44) 260px,rgb(230, 230, 230) 260px,rgb(230, 230, 230))"
      };
      if (this.isTablet) {
        if (this.$route.name === "entity") {
          tmpObj.background =
            "-webkit-linear-gradient(top,rgb(13, 30, 44),rgb(13, 30, 44) 360px,rgb(230, 230, 230) 360px,rgb(230, 230, 230))";
        }
        if (this.$route.name === "change") {
          tmpObj.background =
            "-webkit-linear-gradient(top,rgb(13, 30, 44),rgb(13, 30, 44) 360px,rgb(230, 230, 230) 360px,rgb(230, 230, 230))";
        }
        if (this.$route.name === "member") {
          tmpObj.background =
            "-webkit-linear-gradient(top,rgb(13, 30, 44),rgb(13, 30, 44) 360px,rgb(230, 230, 230) 360px,rgb(230, 230, 230))";
        }
        if (this.$route.name === "security") {
          tmpObj.background =
            "-webkit-linear-gradient(top,rgb(13, 30, 44),rgb(13, 30, 44) 360px,rgb(230, 230, 230) 360px,rgb(230, 230, 230))";
        }
      }
      return tmpObj;
    },
    searchData() {
      if (!this.toggleFlag) {
        const returnArray = [];
        for (let i = 0; i < this.securityList.length; i++) {
          const tmpSecurity = this.securityList[i];
          // if (tmpSecurity.hasOwnProperty("permission") && tmpSecurity.permission === false) continue;
          if (
            Object.hasOwn(tmpSecurity.value, "permission") &&
            tmpSecurity.value.permission
          ) {
            returnArray.push({
              text: tmpSecurity.value.name,
              id: tmpSecurity.value.id
            });
            returnArray.push({
              text: tmpSecurity.value.code,
              id: tmpSecurity.value.id
            });
          }
        }
        return returnArray;
      } else {
        return this.memberList.map((it) => ({
          text: it.text,
          id: it.value.id
        }));
      }
    },
    ...mapGetters({
      isTablet: "app_store/isTablet",
      currentEntity: "user/currentEntity",
      isLimitedAccess: "user/isLimitedAccess",
      isMemberEditor: "user/isMemberEditor",
      isEditor: "user/isEditor",
      isViewer: "user/isViewer",
      shouldResizeRoute: "app_store/shouldResizeRoute",
      pageRequireAllSecurities: "security/pageRequireAllSecurities",
      isAuthorised: "user/isAuthorised"
    }),
    ...mapState({
      currentEntityDetail: (state) => state.user.currentEntityDetail,
      selectedShownEntity: (state) => state.user.selectedShownEntity,
      isRefreshingToken: (state) => state.user.isRefreshingToken,

      limitedAccess: (state) => state.app_store.limitedAccess,
      resizeCount: (state) => state.app_store.resizeCount,
      windowWidth: (state) => state.app_store.windowWidth,
      windowHeight: (state) => state.app_store.windowHeight,
      WIDTH_REFRESH_PR: (state) => state.app_store.WIDTH_REFRESH_PR,
      HEIGHT_REFRESH_PR: (state) => state.app_store.HEIGHT_REFRESH_PR,
      TABLET_CRITERIA_WIDTH: (state) => state.app_store.TABLET_CRITERIA_WIDTH,
      appExpanded: (state) => state.app_store.appExpanded,

      //filteredSecurities: (state) => state.security.filteredSecurities,
      securityList: (state) => state.security.securityList,

      // filteredMemberList: (state) => state.member.filteredMemberList,
      memberList: (state) => state.member.memberList,

      currentChangeDetail: (state) => state.change.currentChangeDetail
    })
  },
  created() {
    window.addEventListener("storage", this.onTokenRefresh);
    this.$nextTick(function () {
      this.$store.dispatch(
        "app_store/remoteUpdateWindowWidth",
        window.innerWidth
      );
      this.$store.dispatch(
        "app_store/remoteUpdateWindowHeight",
        window.innerHeight
      );
      window.addEventListener("resize", this.onResize);
      window.addEventListener("zoom", this.onZoom);
    });
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onTokenRefresh);
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("zoom", this.onZoom);
  },
  async mounted() {
    if (this.limitedAccess) {
      if (this.limitedAccess == "member") {
        this.showMemberError();
      }
      if (this.limitedAccess == "change") {
        this.showChangeError();
      }
      if (this.limitedAccess == "rgpt") {
        this.showRgptError();
      }
      this.$store.dispatch("app_store/limitAccess", "");
    }
    await this.searchResult();
  },
  methods: {
    onFileUploaded(fileInfo) {
      const entityPayload = {
        entity_logo: fileInfo.platform,
        report_logo: fileInfo.report,
        notification_logo: fileInfo.notification,
        notification_negative_icon: fileInfo.negative,
        notification_positive_icon: fileInfo.positive,
        report_branding_icon: fileInfo.background
      };
      this.$store
        .dispatch("user/uploadEntityLogo", entityPayload)
        .then((value) => {
          if (value) {
            this.$refs.logoPopover.onSuccess();
          }
        });
    },
    getLabel(item) {
      if (item) {
        return item.name || "";
      }
      return "";
    },
    onZoom: debounce(function (e) {}, 500),
    onMouseDown(e) {
      if (e.target.closest(".entity-search")) {
        return;
      }
      if (this.$refs["autocompleteItem"]) {
        this.$refs["autocompleteItem"].resetValue();
      }
    },
    onResize: debounce(function (e) {
      // check device ratio
      this.$store.dispatch("app_store/remoteSetDevicePixelRatio");
      // ====
      if (!this.shouldResizeRoute(this.$route.name)) return;

      const widthRatio = Math.abs(
        ((this.windowWidth - window.innerWidth) / this.windowWidth) * 100
      );
      const heightRatio = Math.abs(
        ((this.windowWidth - window.innerWidth) / this.windowWidth) * 100
      );
      if (
        (this.windowWidth > this.TABLET_CRITERIA_WIDTH &&
          window.innerWidth < this.TABLET_CRITERIA_WIDTH) ||
        (this.windowWidth < this.TABLET_CRITERIA_WIDTH &&
          window.innerWidth > this.TABLET_CRITERIA_WIDTH)
      ) {
        // only update the width and height if the count increase, this is so that it compares to the original size as that is what the style is set to
        this.$store.dispatch(
          "app_store/remoteUpdateWindowWidth",
          window.innerWidth
        );
        this.$store.dispatch(
          "app_store/remoteUpdateWindowHeight",
          window.innerHeight
        );
        this.$store.dispatch("app_store/remoteIncreaseResizeCount");
        if (this.appExpanded)
          this.$store.dispatch("app_store/toggleExpand", false); // when app expanded, refresh view hides everything
        const popoverBackdrop = document.querySelector(".popover-semi-b");
        if (popoverBackdrop) popoverBackdrop.remove(); // if a backdrop exit then remove it because view is refreshed and popover is removed.
      }
    }, 500),
    async searchResult() {
      const excludeLoadingSecurityRoute = new Set(["member"]);
      const exludeLoadingMemberRoute = new Set(["memberlist"]);
      if (!excludeLoadingSecurityRoute.has(this.$route.name)) {
        // NOTE: get all securities for securit popover for these pages
        await this.$store.dispatch("security/getTotalSecuritiesList");
      }

      if (!this.isLimitedAccess) {
        if (!exludeLoadingMemberRoute.has(this.$route.name))
          await this.$store.dispatch("member/remoteGetTotalMemberList");
      }
    },

    async onSearch(val) {
      if (!val.id || val.id.toLowerCase() === "none") return;
      if (this.toggleFlag) {
        // if (this.isTablet) {
        //   this.$router.push({ path: `/tablet/member/?m=${val.id}` });
        // } else {
        //   this.$router.push({ path: `/member/?m=${val.id}` });
        // }
        this.$router.push({ path: `/member/?m=${val.id}` });
      } else {
        // if (this.isTablet) {
        //   this.$router.push({ path: `/tablet/security/?s=${val.id}` });
        // } else {
        //   this.$router.push({ path: `/security/?s=${val.id}` });
        // }
        this.$router.push({ path: `/security/?s=${val.id}` });
      }
    },
    hrefLink(val) {
      if (this.isEditor) {
        if (val === "memberlist") {
          return "#";
        }
      }
      if (!this.isAuthorised("access_ai_environment", true)) {
        if (val === "rgpt") {
          return "#";
        }
      }
      if (this.isViewer) {
        if (val === "memberlist") {
          return "#";
        }
        if (val === "changelist") {
          return "#";
        }
      }
      if (this.isMemberEditor) {
        if (val === "changelist") {
          return "#";
        }
      }
      if (val === "home") {
        return "/";
      } else {
        // if (this.isTablet) {
        //   this.$router.push({ name: "tablet-" + val });
        // } else {
        //   this.$router.push({ name: val });
        // }
        return "/" + val + "/";
      }
    },
    onNavItemClicked(val, event) {
      event.preventDefault();
      if (this.isCtrlClicked) {
        this.isCtrlClicked = false;
        return;
      }
      if (this.isEditor) {
        if (val === "memberlist") {
          this.showMemberError();
          return;
        }
      }
      if (!this.isAuthorised("access_ai_environment", true)) {
        if (val === "rgpt") {
          this.showRgptError();
          return;
        }
      }
      if (this.isViewer) {
        if (val === "memberlist") {
          this.showMemberError();
          return;
        }
        if (val === "changelist") {
          this.showChangeError();
          return;
        }
      }
      if (this.isMemberEditor) {
        if (val === "changelist") {
          this.showChangeError();
          return;
        }
      }
      if (val === "home") {
        this.$router.push({ path: "/" });
      } else {
        // if (this.isTablet) {
        //   this.$router.push({ name: "tablet-" + val });
        // } else {
        //   this.$router.push({ name: val });
        // }
        this.$router.push({ name: val });
      }
    },
    onNavItemCtrlClicked(val, event) {
      this.isCtrlClicked = true;
      event.preventDefault();
      if (this.isEditor) {
        if (val === "memberlist") {
          this.showMemberError();
          return;
        }
      }
      if (!this.isAuthorised("access_ai_environment", true)) {
        if (val === "rgpt") {
          this.showRgptError();
          return;
        }
      }
      if (this.isViewer) {
        if (val === "memberlist") {
          this.showMemberError();
          return;
        }
        if (val === "changelist") {
          this.showChangeError();
          return;
        }
      }
      if (this.isMemberEditor) {
        if (val === "changelist") {
          this.showChangeError();
          return;
        }
      }
      if (val === "home") {
        const routeData = this.$router.resolve({ path: "/" });
        window.open(routeData.href, "_blank");
      } else {
        // if (this.isTablet) {
        //   this.$router.push({ name: "tablet-" + val });
        // } else {
        //   this.$router.push({ name: val });
        // }
        const routeData = this.$router.resolve({ name: val });
        window.open(routeData.href, "_blank");
      }
    },
    onSearchSwitched(val) {
      if (this.isLimitedAccess) {
        this.showMemberError();
        this.$refs["custom-switch"].resetValue();
      }
      this.$refs["autocompleteItem"].resetOnSwitch();
      this.toggleFlag = val;
    },

    showMemberError() {
      this.errorTitle = "Contact Administrator";
      this.errorText =
        "Member pages are restricted to administrators. Please contact an administrator directly or through the Entity page to request the appropriate access.";
      this.$refs["error-popover"].show();
    },
    showChangeError() {
      this.errorTitle = "Contact Administrator";
      this.errorText =
        "Change pages are restricted to administrators and editors. Please contact an administrator directly or through the Entity page to request the appropriate access.";
      this.$refs["error-popover"].show();
    },
    showRgptError() {
      this.errorTitle = "Contact Administrator";
      this.errorText =
        "RGPT (AI Beta) are restricted to administrators. Please contact an administrator directly or through the Entity page to request the appropriate access.";
      this.$refs["error-popover"].show();
    },
    onTokenRefresh(ev) {
      if (
        ev.key === LOGINCOOKIETRIGGERVALUE &&
        ev.newValue &&
        !this.isRefreshingToken
      ) {
        // when token is refreshed from another page
        this.$store.dispatch("user/remoteUpdateToken");
      }
    }
  }
};
</script>

<style>
/* Creating custom color variants */
/*
@font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('~assets/fonts/SegoeUI/Segoe UI.woff') format('woff');
}


@font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('~assets/fonts/SegoeUI/Segoe UI Italic.woff') format('woff');
}


@font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('~assets/fonts/SegoeUI/Segoe UI Bold.woff') format('woff');
}


@font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('~assets/fonts/SegoeUI/Segoe UI Bold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI Light';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Light'), url('~assets/fonts/SegoeUI/Segoe UI Light.ttf') format('ttf');
} */
html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
#entity-full-app-container {
  background-color: rgb(230, 230, 230);
  font-size: 10px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  min-height: 100%;
}

.entity-app {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 0 3.5em;
  background: -webkit-linear-gradient(
    top,
    rgb(13, 30, 44),
    rgb(13, 30, 44) 260px,
    rgb(230, 230, 230) 260px,
    rgb(230, 230, 230)
  );
  min-height: 100vh;
  width: auto;
  display: flex;
  flex-direction: column;
}

/* Page Header Styles */
.ranos-logo {
  max-width: 40em;
  max-height: 4em;
  margin-bottom: 1em;
}
.entity-logo {
  max-width: 30em;
  max-height: 3em;
  margin-bottom: 1em;
}
.entity-brand {
  flex-grow: 10;
}
.entity-brand h2 {
  color: rgb(190, 160, 90);
}
.entity-brand small {
  color: rgb(255, 255, 255, 0.5);
  font-size: 10px;
}
.entity-header {
  display: flex;
  color: white;
  padding: 1em 0;
  /* background-color: rgb(13,30,44); */
}
.entity-nav {
  /* padding:0 3em; */
  position: relative;
  flex-grow: 3;
}
.entity-nav a {
  color: rgba(255, 255, 255, 0.5);
  position: relative;
}

.entity-nav a.active::after {
  content: "";
  width: 7px;
  height: 7px;
  background: white;
  left: 46.5%;
  bottom: 0;
  position: absolute;
  border-radius: 50%;
}
.entity-nav a.active {
  color: white;
  font-weight: bold;
}

.entity-search {
  flex-grow: 2;
}

.entity-custom-switch {
  display: flex;
}
.invisible {
  width: 0;
  height: 0;
}
.popover-semi-b {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(13, 30, 44);
  opacity: 0.8;
  z-index: 500;
}
.popover-semi-c {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  opacity: 0.8;
  z-index: 500;
}
#landscape-bg-div {
  display: none;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #landscape-bg-div {
    display: flex !important;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(13, 30, 44);
    align-items: center;
    z-index: 10;
  }
  #landscape-bg-div h1 {
    color: white;
  }
  #landscape-bg-div img {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .entity-app {
      min-height: -webkit-fill-available;
    }
  }
}
</style>

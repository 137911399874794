import Vue from "vue";

export const state = () => ({
  authorisationFilters: [],
  memberMetrics: [],
  memberGroups: [],
  changeMetrics: [],
  changeGroups: [],
  securityMetrics: [],
  securityGroups: [],
  defaultAuthorisations: [
    {
      text: "Unmark published report",
      value: {
        id: "unmark_published_report",
        internal_name: "unmark_published_report",
        name: "Unmark Published Report"
      },
      origin: {
        id: "mark_published_report",
        internal_name: "mark_published_report",
        name: "Mark Published Report"
      }
    }
  ]
});
export const getters = {
  getAuthorisations: (state) => {
    const authorisations = [];
    for (const authorisationObject of state.authorisationFilters) {
      const foundAuthorization = state.defaultAuthorisations.find(
        (defaultAuthorization) =>
          authorisationObject.internal_name ===
          defaultAuthorization.origin.internal_name
      );
      if (foundAuthorization) {
        authorisations.push(foundAuthorization);
      } else {
        const tempObj = {
          value: authorisationObject,
          text: `${authorisationObject.name}`
        };
        authorisations.push(tempObj);
      }
    }
    return authorisations;
  },
  getSelectedAuthorisations: (state, getters, rootState) => {
    if (
      !rootState.member.currentMember ||
      !rootState.member.currentMember.member_authorizations ||
      !getters.getAuthorisations
    )
      return [];
    const tempArr = [];
    for (const authorization of getters.getAuthorisations) {
      const foundAuthorization =
        rootState.member.currentMember.member_authorizations.find(
          (selectedAuthorization) =>
            selectedAuthorization === authorization.value.internal_name
        );
      if (foundAuthorization) tempArr.push(authorization);
      else if (authorization.origin) {
        const foundAuthorization =
          rootState.member.currentMember.member_authorizations.find(
            (selectedAuthorization) =>
              selectedAuthorization === authorization.origin.internal_name
          );
        if (!foundAuthorization) tempArr.push(authorization);
      }
    }
    return tempArr;
  }
};
export const mutations = {
  updateAuthorisationFilters(state, arrayList) {
    var tempArr = [];
    for (var object of arrayList) {
      for (var i = 0; i < object.filter_list_metrics.length; i++) {
        var tempObj = {};
        tempObj.id = object.filter_list_metrics[i].internal_name;
        tempObj.name = object.filter_list_metrics[i].display_name;
        tempObj.internal_name = object.filter_list_metrics[i].internal_name;
        tempArr.push(tempObj);
      }
      break;
    }
    Vue.set(state, "authorisationFilters", tempArr);
  },
  updateListMetricsAndGroup(
    state,
    { authorisedList, arrayList, nameToUpdate }
  ) {
    if (!nameToUpdate) return;
    const metricArr = [];
    const groupArr = [];
    for (let i = 0; i < authorisedList.length; i++) {
      const metricsType = authorisedList[i].metricsType;
      const metricsName = authorisedList[i].metricsName;
      const authorised = authorisedList[i].authorised;
      const targetList = authorisedList[i].targetList;
      const filtertargetList = arrayList.find(
        (obj) => obj.filter_list_page === targetList
      );
      if (metricsType === "Group") {
        let groupList = [];
        let isAuthorised = true;
        if (filtertargetList) {
          isAuthorised = authorised;
          groupList = arrayList.find(
            (obj) => obj.filter_list_grouping === metricsName
          ).filter_list_metrics;
        }
        const tempArrayList = [];
        for (const object of arrayList) {
          const filter_list_metrics_temp_list = []; //used to replace object.filter_list_metrics
          for (let idx = 0; idx < object.filter_list_metrics.length; idx++) {
            const tmpObj = object.filter_list_metrics[idx];
            if (
              !(
                !isAuthorised &&
                (object.filter_list_grouping === "All" ||
                  object.filter_list_grouping === metricsName) &&
                groupList.some((e) => e.internal_name === tmpObj.internal_name)
              )
            ) {
              filter_list_metrics_temp_list.push(tmpObj);
            }
          }
          object.filter_list_metrics = filter_list_metrics_temp_list;
          if (object.filter_list_grouping !== metricsName || isAuthorised) {
            tempArrayList.push(object);
          }
        }
        arrayList = tempArrayList;
      } else {
        let isAuthorised = true;
        if (filtertargetList) {
          isAuthorised = authorised;
        }
        const tempArrayList = [];
        for (const object of arrayList) {
          const filter_list_metrics_temp_list = []; //used to replace object.filter_list_metrics
          for (let idx = 0; idx < object.filter_list_metrics.length; idx++) {
            const tmpObj = object.filter_list_metrics[idx];
            if (!(!isAuthorised && tmpObj.internal_name === metricsName)) {
              filter_list_metrics_temp_list.push(tmpObj);
            }
          }
          object.filter_list_metrics = filter_list_metrics_temp_list;
          tempArrayList.push(object);
        }
        arrayList = tempArrayList;
      }
    }
    // reformat to selectable structure
    for (const object of arrayList) {
      const groupObj = {
        id: object.filter_list_id,
        name: object.filter_list_grouping,
        subGroups: []
      };
      for (let i = 0; i < object.filter_list_metrics.length; i++) {
        const tmpObj = object.filter_list_metrics[i];
        if (tmpObj.display_flag !== undefined && tmpObj.display_flag === false)
          continue;
        let metricObj;
        if (tmpObj.sub_metrics && groupObj.name !== "All") {
          groupObj.subGroups.push({
            name: tmpObj.display_name,
            id: `${object.filter_list_id}-${i}`
          });
          for (let j = 0; j < tmpObj.sub_metrics.length; j++) {
            metricObj = {
              id: tmpObj.sub_metrics[j].internal_name,
              name: tmpObj.sub_metrics[j].display_name,
              internal_name: tmpObj.sub_metrics[j].internal_name,
              group: `${object.filter_list_id}-${i}`
            };
            metricArr.push(metricObj);
          }
        } else {
          metricObj = {
            id: tmpObj.internal_name,
            name: tmpObj.display_name,
            internal_name: tmpObj.internal_name,
            group: object.filter_list_id
          };
          metricArr.push(metricObj);
        }
      }
      groupArr.push(groupObj);
    }
    switch (nameToUpdate) {
      case "security_list":
        Vue.set(state, "securityMetrics", metricArr);
        Vue.set(state, "securityGroups", groupArr);
        break;
      case "member_list":
        Vue.set(state, "memberMetrics", metricArr);
        Vue.set(state, "memberGroups", groupArr);
        break;
      case "change_list":
        Vue.set(state, "changeMetrics", metricArr);
        Vue.set(state, "changeGroups", groupArr);
        break;
    }
  }
};
export const actions = {
  async getAuthorisationFilters({ commit }) {
    try {
      const r = await this.$axios.$get(`/v1/filter_list/member_authorisations`);
      if (r) {
        commit("updateAuthorisationFilters", r);
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async remoteGetListMetricsAndGroup({ commit, rootGetters }, listName) {
    const authorisedList = [
      {
        authorised: rootGetters["user/isAuthorised"](
          "download_member_data",
          true
        ),
        metricsType: "Group",
        metricsName: "Distribution",
        targetList: "security_list"
      },
      {
        authorised: rootGetters["user/isAuthorised"](
          "download_security_data",
          true
        ),
        metricsType: "Item",
        metricsName: "report_link",
        targetList: "change_list"
      }
    ];
    if (!listName) return false;
    try {
      const r = await this.$axios.$get(`/v1/filter_list/${listName}`);
      if (r) {
        commit("updateListMetricsAndGroup", {
          authorisedList: authorisedList,
          arrayList: r,
          nameToUpdate: listName
        });
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }
};

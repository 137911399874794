<template>
  <nuxt-link :to="link" class="link-wrapper">
    <div ref="slot" :class="{ 'full-width': full, 'fill-height': full }">
      <slot></slot>
    </div>
  </nuxt-link>
</template>

<script>
export default {
  props: {
    to: String,
    href: String,
    full: Boolean
  },
  computed: {
    link() {
      if (this.href) return this.href;
      else return this.to;
    }
  },
  mounted() {
    this.$refs["slot"].addEventListener("click", async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await new Promise((resolve) => setTimeout(resolve, 200));
      if (!this.href) this.$router.push(this.to);
      else window.open(this.href, "_blank");
    });
  }
};
</script>

<style lang="sass" scoped>
.link-wrapper
    text-decoration: none !important
    pointer-events: none !important
    &:visited, &:link, &:active
        color: inherit !important
    >div
        pointer-events: all
</style>

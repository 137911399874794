export const state = () => ({});

export const getters = {
  shouldUseGeniumLayout: (state, getters, rootState, rootGetters) =>
    rootGetters["user/isViewer"] &&
    rootGetters["user/currentEntity"]?.entity_id ==
      "7df4ccfa-55df-4ebf-847f-279ae67a2587",
  computedFrontPageOptions: () => (firstPageSelections) => {
    const GeniumTemplates = [
      {
        id: "single_security_template",
        value: "Single security template"
      },
      {
        id: "email_template",
        value: "Stretch template"
      }
    ];
    return GeniumTemplates.map((obj) => ({
      id: obj.id,
      value: obj.value,
      isSelected: firstPageSelections
        ? firstPageSelections.includes(obj.id)
        : false,
      disabled: true,
      canMove: false
    }));
  }
};

export const state = () => ({
  content: {},
  ready: false,
  message: ""
});

export const getters = {
  content(state) {
    return state.content;
  },
  ready(state) {
    return state.ready;
  },
  message(state) {
    return state.message;
  }
};

export const mutations = {
  setContent(state, content) {
    Object.assign(state.content, content);
    state.ready = true;
  },
  setMesssage(state, message) {
    state.message = message;
  }
};

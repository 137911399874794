/*
  This function returns a nicely formatted mm:hh display string given the number of seconds.
  Mostly used for the Publication Logs on the member page. The business requires the minutes to be free flowing and not be capped by logical hours,
  which is why we don't use moment.js here. Eg 5064 seconds becomes 84:25
 */
export function GetFormattedDisplayForSeconds(seconds) {
  let formattedStr = "00:00";
  if (seconds != null) {
    let secondsComponent = "";
    let minutesComponent = "";
    const secondsRemainder = seconds % 60;
    const minutesRemainder = (seconds - secondsRemainder) / 60;
    if (secondsRemainder < 60) {
      if (secondsRemainder < 10) {
        secondsComponent += "0";
      }
      secondsComponent += secondsRemainder.toString();
    }
    if (minutesRemainder < 10) {
      minutesComponent += "0";
    }
    minutesComponent += minutesRemainder.toString();
    formattedStr = minutesComponent + ":" + secondsComponent;
  }
  return formattedStr;

  // Remove 60 seconds
}

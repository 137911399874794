<template>
  <div id="footer">
    <ContentWrapper>
      <div class="content">
        <div>
          <h2 class="footer-title">Contacts</h2>
          <div
            v-for="(contact, index) in contacts"
            :key="index"
            class="contact"
          >
            <span class="title">{{ contact.title }}</span>
            <span
              class="name"
              v-html="documentToHtmlString(contact.description)"
            ></span>
            <a
              :href="`mailto:${contact.email}`"
              target="_blank"
              class="email"
              >{{ contact.email }}</a
            >
            <a :href="`tel:${contact.phone}`" target="_blank" class="phone">{{
              contact.phone
            }}</a>
          </div>
        </div>
        <div class="link-holder">
          <h2 class="footer-title">Legal</h2>
          <div class="links">
            <LinkWrapper
              v-for="(legal, index) in legalTexts"
              :key="index"
              :to="`/legal/${legal.slug}/`"
            >
              <span class="footer-link">{{ legal.title }}</span>
            </LinkWrapper>
          </div>
        </div>
        <div>
          <h2 class="footer-title">Downloads</h2>
          <div v-if="downloadLinks" class="links">
            <LinkWrapper
              v-for="(link, index) in downloadLinks"
              :key="index"
              :href="link.link"
            >
              <span class="footer-link">{{ link.title }}</span>
            </LinkWrapper>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="copyright">
          <span
            >© Copyright RANOS Limited {{ new Date().getFullYear() }}. All
            rights reserved.
          </span>
        </div>

        <Logo
          style="width: 110px; opacity: 0.6"
          white
          @click.native="$emit('goHome')"
        />
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentWrapper from "~/components/new/ContentWrapper";
import LinkWrapper from "~/components/new/Options/LinkWrapper";
import Logo from "~/components/new/Logo";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  components: {
    ContentWrapper,
    LinkWrapper,
    Logo
  },

  computed: {
    ...mapGetters({
      content: "contentful/content",
      contentReady: "contentful/ready"
    }),

    sectionFooter() {
      if (!this.contentReady) return null;
      return this.content?.footerSection[0];
    },

    contacts() {
      if (!this.contentReady) return null;
      return this.sectionFooter?.contacts?.map((contact) => ({
        title: contact.fields.title,
        description: contact.fields.description,
        email: contact.fields.email,
        phone: contact.fields.phoneNumber
      }));
    },

    legalTexts() {
      if (!this.contentReady) return null;
      return this.sectionFooter?.legalTexts?.map((legalText) => ({
        title: legalText.fields.name,
        content: legalText.fields.privacy,
        slug: legalText.fields.slug
      }));
    },

    downloadLinks() {
      if (!this.contentReady) return null;
      return this.sectionFooter?.downloads?.map((downloadLink) => ({
        title: downloadLink.fields.name,
        link: "https://" + downloadLink.fields.file.fields.file.url
      }));
    }
  },

  methods: {
    documentToHtmlString
  }
};
</script>

<style lang="sass" scoped>

#footer
  background-color: rgba(0,0,0,0.2)
  border-top: 1px solid rgba(255,255,255, 0.05)
  padding: 48px 0
  position: relative
  z-index: 1
  .links
    display: flex
    gap: 12px
    flex-flow: column
  .footer-link
    color: rgba(255,255,255,0.7)
    font-size: 14px
    font-weight: 400
    padding: 6px 0
    transition: all 0.2s ease-in-out
    &:hover
      color: rgba(255,255,255,1)
  .bottom
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
    gap: 24px
    .copyright
      color: rgba(255,255,255,0.7)
      font-size: 14px
      font-weight: 400
  .content
    margin-bottom: 48px
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    grid-gap: 24px
    position: relative
    z-index: 2
    @media (max-width: 768px)
      grid-template-columns: 1fr 1fr
    @media (max-width: 480px)
      grid-template-columns: 1fr
    .footer-title
      font-size: 22px
      font-weight: 400
      margin-bottom: 12px
      padding-bottom: 12px
      color: #fff
      &:after
        content: ""
        display: block
        width: 20px
        height: 1px
        background-color: rgba(var(--color-primary), 1)
        margin-top: 12px
    .contact
      margin-bottom: 24px
      span, a
        display: block
        color: rgba(255,255,255,0.7)
      .title
        color: #fff
        font-size: 16px
        font-weight: 600
</style>

import * as contentful from "contentful";

const auth = {
  space: "lj276boeua78",
  accessToken: "9QSXVrmaisdCbt6leSpJc3jH-VLJcgvfjj5cuberhvA"
};

const fetchContentType = async (contentType) => {
  const client = contentful.createClient(auth);
  const entries = await client.getEntries({
    content_type: contentType,
    include: 1
  });
  return entries?.items?.map((item) => {
    item.fields.id = item.sys.id;
    return item.fields;
  });
};

const types = {
  sectionAuthorization: "sectionAuthorizationSigInSignUp",
  textSection: "textSection",
  footerSection: "sectionFooter",
  sectionWhatWeDo: "sectionWhatWeDo",
  sectionHero: "sectionHero",
  sectionPromoStatistics: "sectionPromoStatistics",
  sectionEmail: "sectionEmail",
  sectionPlatform: "sectionPlatform"
};

const fetchAll = async () => {
  const data = {};
  for (const [key, value] of Object.entries(types)) {
    data[key] = await fetchContentType(value);
  }
  return data;
};

export default async () => await fetchAll();

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.resizeCount,attrs:{"id":"entity-full-app-container"},on:{"click":_vm.onMouseDown}},[_c('div',{staticClass:"entity-app",style:(_vm.bodyGradientStyle)},[_c('div',{staticClass:"entity-header"},[_c('div',{staticClass:"entity-brand"},[(
            _vm.$route.name === 'entity' && !_vm.isLimitedAccess && !_vm.isMemberEditor
          )?_c('div',{attrs:{"id":"edit-logo-component"}},[_c('logo-button',{attrs:{"id":"edit-logo-reactive","label":'Logo',"url":_vm.currentEntityDetail.entity_logo}}),_vm._v(" "),_c('editlogo-popover',{ref:"logoPopover",attrs:{"container-id":"edit-logo-component","target-button-id":"edit-logo-reactive","platform-url":_vm.currentEntityDetail.entity_logo,"report-url":_vm.currentEntityDetail.report_logo,"notification-url":_vm.currentEntityDetail.notification_logo,"negative-url":_vm.currentEntityDetail.notification_negative_icon,"positive-url":_vm.currentEntityDetail.notification_positive_icon,"background-url":_vm.currentEntityDetail.entity_metadata &&
              _vm.currentEntityDetail.entity_metadata.report_branding_icon
                ? _vm.currentEntityDetail.entity_metadata.report_branding_icon
                : ''},on:{"file-added":_vm.onFileUploaded}})],1):_c('div',{attrs:{"id":"edit-logo-component"}},[_c('nuxt-link',{staticClass:"home-nuxt-link",attrs:{"to":"/entity/"}},[(
                _vm.currentEntityDetail.entity_logo === null ||
                _vm.currentEntityDetail.entity_logo === ''
              )?_c('b-img',{staticClass:"ranos-logo",attrs:{"src":require("assets/logos/logo_Negative.png"),"alt":"img"}}):_c('b-img',{staticClass:"entity-logo",attrs:{"src":_vm.currentEntityDetail.entity_logo,"alt":"img"}})],1)],1),_vm._v(" "),_c('p',{staticClass:"card-text py-0"},[_c('small',[_vm._v("\n            Viewing\n            "+_vm._s(_vm.currentEntityDetail && _vm.currentEntityDetail.entity_name
                ? _vm.currentEntityDetail.entity_name
                : "")+"\n            | as "+_vm._s(_vm.$auth.user.user_data.user_name)+" | with\n            "+_vm._s(_vm.currentEntityDetail && _vm.currentEntityDetail.entity_name
                ? _vm.currentEntity.role_name
                : "")+"\n            access\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"entity-nav",attrs:{"id":"entity-nav-container"}},[_c('b-nav',_vm._l((_vm.navMap),function(navText,mappingKey){return _c('b-nav-item',{key:`nav-item-${mappingKey}`,attrs:{"id":`nav-item-${mappingKey}`,"active":_vm.getActiveNavTab(mappingKey),"href":_vm.hrefLink(mappingKey)},on:{"click":[function($event){if(!$event.ctrlKey)return null;return ((event) => _vm.onNavItemCtrlClicked(mappingKey, event)).apply(null, arguments)},(event) => _vm.onNavItemClicked(mappingKey, event)]}},[_vm._v(_vm._s(navText))])}),1),_vm._v(" "),_c('b-button',{staticClass:"invisible",attrs:{"id":"error-button"}}),_vm._v(" "),(
            _vm.isLimitedAccess ||
            !_vm.isAuthorised('access_ai_environment', true) ||
            _vm.isMemberEditor
          )?_c('error-popover',{ref:"error-popover",attrs:{"container-id":"entity-nav-container","target-button-id":"error-button","is-dismiss-able":true,"title":_vm.errorTitle,"description":_vm.errorText}}):_vm._e()],1)]),_vm._v(" "),_c('nuxt')],1),_vm._v(" "),_c('div',{attrs:{"id":"landscape-bg-div"}},[_c('b-img',{attrs:{"src":require("assets/images/ic_rotate.svg")}}),_vm._v(" "),_c('h1',[_vm._v("Please rotate your screen")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }